
	export default {
		props: {
			label: {
				type: String,
				default: ''
			},
			amountProp: {
				type: Number,
				default: 0.00
			},
			maxHeight: {
				type: Number,
				default: 300
			}
		},
		data () {
			return {
				amount: this.amountProp,
				denominations: [],
				selectedDenominations: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			settings () {
				return this.$store.state.settings
			}
		},
		watch: {
			amount (amount) {
				this.$emit('update:amount', this.$currency.transformNumber(amount))
			}
		},
		beforeMount () {
			this.$store.commit('setState', {
				key: 'denominations',
				value: []
			})

			this.denominations = this.settings.denominations.map((d) => {
				d.quantity = 0

				return d
			})
		},
		methods: {
			calculateAmount () {
				this.selectedDenominations = []
				this.amount = this.denominations.reduce((sum, denomination) => {
					if (denomination.quantity) {
						this.selectedDenominations.push(denomination)
					}

					sum += (denomination.amount * denomination.quantity)

					return sum
				}, 0)

				this.$store.commit('setState', {
					key: 'denominations',
					value: this.selectedDenominations
				})
			}
		}
	}
