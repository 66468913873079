
	let debounceTimer = null

	export default {
		data () {
			return {
				loading: true,
				searchTerm: '',
				customers: {
					data: [],
					total: 0,
					offset: 0
				},
				selectedCustomer: null,
				loyalty: null
			}
		},
		computed: {
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employee () {
				return this.$store.state.employee
			},
			localSettings () {
				return this.$store.state.localSettings
			},
			selectedCustomerAddress () {
				let address = ''

				if (this.selectedCustomer) {
					if (this.selectedCustomer.address.line) {
						address += `${this.selectedCustomer.address.line}, `
					}

					if (this.selectedCustomer.address.area) {
						address += `${this.selectedCustomer.address.area}, `
					}

					if (this.selectedCustomer.address.city) {
						address += `${this.selectedCustomer.address.city}, `
					}

					if (this.selectedCustomer.address.state) {
						address += `${this.selectedCustomer.address.state}, `
					}

					if (this.selectedCustomer.address.zip) {
						address += `${this.selectedCustomer.address.zip}, `
					}

					if (this.selectedCustomer.address.landmark) {
						address += `${this.selectedCustomer.address.landmark}`
					}

					if (address.slice(-2) === ', ') {
						address = address.slice(0, -2)
					}
				}

				return address
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			selectedCustomer () {
				this.getLoyaltyPoints()
			}
		},
		async beforeMount () {
			await this.getCustomers({})
			this.loading = false
			requestAnimationFrame(() => this.$refs.customersBlock?.addEventListener('scroll', this.loadMoreCustomers))
		},
		methods: {
			printCreditSummary () {
				window.printPayCredit({
					customer: this.selectedCustomer
				})
			},
			getCustomers ({ searchTerm, id, offset }) {
				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(async () => {
					let customers = await this.$bridge.getCustomers(
						this.deviceId,
						this.objToJson({
							merchant_id: this.merchant.id,
							search_term: searchTerm || null,
							id,
							offset
						})
					)

					customers = typeof customers === 'string' ? JSON.parse(customers) : customers
					this.customers = {
						data: offset && Array.isArray(this.customers?.data)
							? this.customers.data.concat(customers.data)
							: customers.data,
						total: customers.total,
						offset: customers.offset
					}
					this.selectedCustomer = (
						this.selectedCustomer
							? this.customers.data.find(c => c.id === this.selectedCustomer.id)
							: this.customers.data[0]
					) || null
				}, 100)
			},
			selectCustomer ($event, customer) {
				$event.currentTarget.closest('.list-group').childNodes.forEach((list) => {
					list.classList.remove('active')
				})
				$event.currentTarget.classList.add('active')
				this.selectedCustomer = customer
			},
			getLoyaltyPoints () {
				this.loyalty = null

				if (this.selectedCustomer && !this.isMiniPlan) {
					this.$store.dispatch('getLoyaltyPoints', {
						merchant_customer_id: this.selectedCustomer.customer_id,
						location_id: this.locationId,
						total_price: 0
					}).then((response) => {
						this.loyalty = response.data

						if (this.selectedCustomer) {
							this.selectedCustomer.loyalty = response.data
						}
					}).catch(console.error)
				}
			},
			loadMoreCustomers ($event) {
				const el = $event.currentTarget

				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					if (
						this.customers.data.length < this.customers.total &&
						Math.round(el.scrollTop) > (el.scrollHeight - el.offsetHeight) - 10
					) {
						this.getCustomers({
							searchTerm: this.$refs.searchTerm.$el.value,
							offset: this.customers.offset + (this.appVersionNumber >= 4024 ? 20 : 50)
						})
					}
				}, 100)
			},
			payCredit ($event) {
				const index = this.customers.data.findIndex(c => c.id === $event.customer.id)

				if (index !== -1) {
					this.selectedCustomer = this.customers.data[index] = {
						...this.customers.data[index],
						...$event.customer
					}
				}

				if (this.isMiniPlan) {
					this.$bridge.insert(
						'Credit',
						this.$store.state.bridgeName === 'ANDROID' ? this.objToJson($event) : $event,
						true
					)
				}

				window.printPayCredit({
					...$event,
					customer: Object.assign({}, this.selectedCustomer)
				})
			},
			createCustomer () {
				this.selectedCustomer = null
				window.requestAnimationFrame(() => this.$bvModal.show('add-customer'))
			},
			editCustomer () {
				this.$bvModal.show('add-customer')
			},
			async exportCustomers () {
				await this.$bridge.exportSheet(this.deviceId, 'Customer', this.objToJson({
					search_term: this.$refs.searchTerm.$el.value,
					offset: -1
				}))
			}
		}
	}
