
	let eventTimes = []
	let tempBarcode = ''

	export default {
		data () {
			return {
				barcode: '_ _ _ _ _ _ _ _ _ _ _ _ _ _'
			}
		},
		mounted () {
			document.addEventListener('keydown', this.readBarcode)
		},
		destroyed () {
			document.removeEventListener('keydown', this.readBarcode)
		},
		methods: {
			readBarcode (e) {
				eventTimes.push(new Date().valueOf())

				if (!document.querySelector('input:focus')) {
					const textInput = e.key || String.fromCharCode(e.keyCode)

					if (tempBarcode && textInput === 'Enter') {
						this.barcode = this.getBarcode()
						tempBarcode = ''
						eventTimes = []
					} else {
						tempBarcode += textInput
					}
				}
			},
			getBarcode () {
				for (let i = 0; i < eventTimes.length; i++) {
					if (eventTimes[i + 1] && eventTimes[i + 1] - eventTimes[i] > 20) { tempBarcode = tempBarcode.slice(1) }
				}

				return tempBarcode
			}
		}
	}
