
	export default {
		props: {
			numberProp: {
				type: String,
				default: ''
			},
			limit: {
				type: Number,
				default: 20
			},
			decimal: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				number: this.numberProp,
				numPad: [
					[1, 2, 3],
					[4, 5, 6],
					[7, 8, 9],
					this.decimal ? [0, '.', 'C'] : [0, 'C']
				]
			}
		},
		watch: {
			numberProp (number) {
				this.number = number
			},
			number (number) {
				this.$emit('update:number', number)
			}
		},
		methods: {
			appendNumber (number) {
				if (number === 'C') {
					this.number = this.number.slice(0, -1)

					return
				}

				if (this.number.length < this.limit) { this.number += number }
			}
		}
	}
