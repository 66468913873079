
	export default {
		props: {
			i18n: {
				type: Object,
				required: true
			},
			bridge: {
				type: Object,
				required: true
			},
			moment: {
				type: Function,
				required: true
			},
			currency: {
				type: Object,
				required: true
			},
			domtoimage: {
				type: Object,
				required: true
			},
			data: {
				type: Object,
				required: true
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			partner () {
				return this.$store.state.partner
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			settings () {
				return this.$store.state.settings
			},
			employee () {
				return this.$store.state.employee
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			credit () {
				return this.data.payCredit.customer.credit
			},
			debit () {
				return this.data.payCredit.customer.debit
			},
			outstanding () {
				return (this.data.payCredit.customer.credit && !this.data.payCredit.customer.debit
					? this.data.payCredit.customer.credit
					: (this.data.payCredit.customer.credit - this.data.payCredit.customer.debit)
				)
			}
		},
		methods: {
			renderReceipt () {
				const iframe = document.getElementById(this.data.id)
				const iframeDocument = iframe.contentDocument
				const el = iframeDocument.getElementById('receipt')
				const height = el.offsetHeight * this.printerSettings.scaleFactor
				const width = el.offsetWidth * this.printerSettings.scaleFactor

				this.domtoimage.toPng(el, {
					height,
					width
				}).then((dataURL) => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line
						console.log('%c ', `font-size: 1px; padding: ${Math.floor(height / 2)}px ${Math.floor(width / 2)}px; background: url(${dataURL})`)
					}

					const printData = {
						dataURL,
						printerSettings: this.printerSettings
					}

					this.bridge.pushPrinterQueue(this.bridgeName === 'ANDROID' ? this.objToJson(printData) : printData)
				}).catch(console.error).finally(() => {
					this.$emit('destroy')
				})
			}
		}
	}
