
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	let refundedTax = 0

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			order: {
				type: Object,
				required: true
			}
		},
		data () {
			return {
				loading: false,
				amount: 0,
				items: [],
				refundItems: [],
				itemFields: [
					{
						key: 'variation_name',
						label: 'item name',
						tdClass: 'align-middle'
					},
					{
						key: 'price',
						label: 'amount',
						tdClass: 'align-middle'
					},
					{
						key: 'quantity',
						label: 'quantity',
						tdClass: 'align-middle'
					},
					{
						key: 'refund_quantity',
						label: 'refund quantity',
						tdClass: 'align-middle'
					},
					{
						key: 'total',
						label: 'total',
						tdClass: 'align-middle'
					}
				],
				reason: 'Other',
				reasons: [
					{
						text: 'returned goods',
						value: 'Returned Goods'
					},
					{
						text: 'accidental charge',
						value: 'Accidental Charge'
					},
					{
						text: 'cancelled order',
						value: 'Cancelled Order'
					},
					{
						text: 'other',
						value: 'Other'
					}
				],
				otherReason: '',
				refundCharges: false,
				customAttributes: {},
				tidyPayState: {
					showModal: false,
					status: 'INITIALIZING',
					code: null
				},
				cancelTidyPayRequest: null,
				availableItems: [],
				paymentMethodId: null,
				paymentMethods: [
					{
						text: 'payment method',
						value: null
					}
				],
				restock: 1,
				restockOptions: [
					{
						text: 'void',
						value: 1
					},
					{
						text: 'waste',
						value: 0
					}
				]
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			employee () {
				return this.$store.state.employee
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			tidyPay () {
				return this.$store.state.tidyPay
			},
			orderOutstandingAmount () {
				return this.order.credits
					? this.$currency.transformNumber(this.order.payment_methods.reduce((sum, pm) => {
						if (pm.slug === 'credit') {
							sum += pm.amount
						}

						return sum
					}, 0) - this.order.credits.reduce((sum, c) => {
						sum += c.type === 'debit' ? c.amount : 0

						return sum
					}, 0))
					: 0
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		async beforeMount () {
			const paymentMethods = await this.$bridge.getPaymentMethods(this.deviceId, '')

			this.paymentMethods = this.paymentMethods.concat(
				(typeof paymentMethods === 'string' ? JSON.parse(paymentMethods) : paymentMethods)
					.reduce((paymentMethods, paymentMethod) => {
						if (!['cod', 'split'].includes(paymentMethod.slug)) {
							paymentMethods.push({
								text: paymentMethod.name,
								value: paymentMethod.id,
								slug: paymentMethod.slug
							})
						}

						return paymentMethods
					}, []))
		},
		methods: {
			initRefundModal () {
				this.amount = 0
				this.loading = false
				this.refundCharges = false

				if (this.order.payment_method.slug !== 'credit' || this.orderOutstandingAmount > 0) {
					this.paymentMethodId = this.paymentMethods.findIndex(pm => pm.value === this.order.payment_method?.id) > -1
						? this.order.payment_method.id
						: null
				}

				this.formatRefundItems(this.order)
			},
			selectAll ($event) {
				this.$refs.itemsTable[$event ? 'selectAllRows' : 'clearSelected']()
			},
			selectItems (items) {
				if (items.length === 0) {
					this.refundCharges = false
				}

				this.refundItems = items.map(item => this.items.find(i => i.item_code === item.item_code))
				this.calculateRefund()
			},
			formatRefundItems (order) {
				let allRefundedItems = [] // allRefundedItems contains aggregate of refunded items with quantity refunded

				if (order && order.refund) {
					if (!Array.isArray(order.refund)) {
						order.refunds = [{
							...order.refund
						}]
					}
				}

				if (order.refunds && order.refunds.length) {
					order.refunds.forEach((refund) => {
						refund.items.forEach((item) => {
							allRefundedItems.push(item) // All refunded items are pushed in array
						})
					})
				}

				allRefundedItems = Object.values(allRefundedItems.reduce((acc, curr) => { // Unique refuned items are aggregated and added with the quantity refuneded
					(acc[curr.item_code] = acc[curr.item_code] || { ...curr, refunded_qty: 0 }).refunded_qty += curr.refunded_qty

					return acc
				}, {}))

				this.availableItems = order.items.filter((item) => { // availableItems contains the available items that can be refunded
					const refundedItem = allRefundedItems.find(i => i.item_code === item.item_code && i.refunded_qty >= item.quantity)

					return !refundedItem
				})

				this.items = JSON.parse(this.objToJson(order.items.map((item) => {
					let refundQty = item.quantity
					let availableQuantity = item.quantity
					let refundedAmount = item.sub_total + item.tax - item.discount
					let refundedTax = item.tax - item.discounted_tax
					const tax = item.tax
					const taxDetails = item.taxes
					const refundedDiscountedTax = item.discounted_tax

					if (order.refunds && order.refunds.length) {
						const refundedItem = allRefundedItems.find(i => i.item_code === item.item_code)

						if (refundedItem) {
							availableQuantity = +(availableQuantity - refundedItem.refunded_qty).toFixed(this.getUnitDecimalPlaces(item.unit_measure_type))
							refundQty = +(refundQty - refundedItem.refunded_qty).toFixed(this.getUnitDecimalPlaces(item.unit_measure_type))
							refundedAmount -= refundedItem.refunded_amount
							refundedTax -= refundedItem.refunded_tax
						}
					}

					return {
						item_code: item.item_code,
						variation_id: item.variation_id,
						refunded_qty: refundQty,
						refunded_amount: refundedAmount,
						refunded_tax: refundedTax,
						available_qty: availableQuantity,
						tax,
						tax_details: taxDetails,
						refunded_discounted_tax: refundedDiscountedTax
					}
				})))
				this.items = this.items.filter(item => item.refunded_qty !== 0)
			},
			calculateRefund () {
				let amount = 0
				let refundQuantity = 0
				const totalOrderQuantity = this.order.items.reduce((sum, item) => {
					sum += item.quantity

					return sum
				}, 0)

				refundedTax = 0
				this.refundItems.forEach((refundItem) => {
					const item = this.order.items.find(i => i.item_code === refundItem.item_code)

					if (item && refundItem.refunded_qty <= item.quantity) {
						refundItem.refunded_discounted_tax = this.$currency.transformNumber(
							(item.discounted_tax / item.quantity) * refundItem.refunded_qty
						)
						refundItem.refunded_tax = Math.min(this.$currency.transformNumber(
							(
								item.tax / item.quantity
							) * refundItem.refunded_qty - refundItem.refunded_discounted_tax
						), item.tax)
						refundItem.refunded_amount = Math.min(this.$currency.transformNumber(
							(
								(item.sub_total / item.quantity) * refundItem.refunded_qty
							) + refundItem.refunded_tax - (
								item.discounted_amount / item.quantity
							) * refundItem.refunded_qty
						), item.sub_total - item.discount + item.tax)
						refundQuantity = this.$currency.transformNumber(refundQuantity + +refundItem.refunded_qty)
						amount = this.$currency.transformNumber(amount + (
							refundItem.refunded_amount > 0 ? refundItem.refunded_amount : 0
						))
					}
				})

				if (this.refundCharges) {
					const totalCharges = this.$currency.transformNumber(
						this.order.charges.reduce((sum, charge) => {
							sum += charge.amount + charge.tax
							refundedTax += charge.tax

							return sum
						}, 0)
					)

					amount = this.$currency.transformNumber(amount + totalCharges)
				}

				const roundOffAmount = this.$currency.transformNumber((this.order.round_off_amount / totalOrderQuantity) * refundQuantity)

				amount = this.$currency.transformNumber(amount + roundOffAmount)
				this.amount = Math.min(Math.max(amount, 0), this.order.total_price)
			},
			initTidyPay (paymentMethod) {
				this.cancelTidyPayRequest = this.$axios.CancelToken.source()

				if (this.$offline.state === 'up') {
					this.tidyPayState.showModal = true
					this.loading = true
					let data = {
						requestType: (parseFloat(this.amount) === this.order.total_amount) ? 'refund' : 'credit',
						userName: this.tidyPay.userName,
						password: this.tidyPay.password,
						accountId: this.tidyPay.accountId,
						terminalId: this.tidyPay.terminalId,
						transactionId: paymentMethod.transaction_id,
						amount: this.$currency.transformNumber(this.amount * 100),
						receiptMode: 'N'
					}

					if (this.order.customer && this.order.customer.email) {
						data = {
							...data,
							receiptMode: 'E',
							email: this.order.customer.email
						}
					}

					this.$axios.post('/api/tidypay', data, {
						cancelToken: this.cancelTidyPayRequest.token
					}).then((response) => {
						if (response.data.responseCode === 'A03' || response.data.responseCode === 'A02') {
							this.tidyPayState.status = response.data.responseCode === 'A03' ? 'APPROVED' : 'CREDIT POSTED'
							this.tidyPayState.code = response.data.responseCode
							setTimeout(() => {
								this.customAttributes.refund_payment_response = response.data
								this.resetTidyPay()
								this.processRefund()
							}, 3000)
						} else if (response.data.failureCode === 'L01') {
							this.tidyPayState.status = 'FAILED'
							this.tidyPayState.code = response.data.failureCode
						} else {
							this.tidyPayState.status = 'FAILED'
							this.tidyPayState.code = response.data.responseCode
							this.tidyPayErrorCb()
						}
					}).catch(this.tidyPayErrorCb)
				} else {
					this.processingOrder = false
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			cancelTidyPayTransaction () {
				this.cancelTidyPayRequest.cancel()
				setTimeout(this.resetTidyPay, 3000)
			},
			tidyPayErrorCb () {
				this.tidyPayState.status = 'FAILED'
				setTimeout(this.resetTidyPay, 3000)
			},
			resetTidyPay () {
				this.processingOrder = false
				this.loading = false
				this.tidyPayState = {
					showModal: false,
					status: 'INITIALIZING',
					code: null
				}
			},
			async validateOrderRefund () {
				if (await this.$refs.validator.validate()) {
					this.loading = true

					const tidypayPayment = this.order.custom_attributes.payment_methods &&
						this.order.custom_attributes.payment_methods.find(pm => pm.gateway === 'tidypay')

					if (this.order.payment_method.slug === 'card' && this.tidyPay.enabled && tidypayPayment) {
						this.initTidyPay(tidypayPayment)
					} else {
						this.processRefund()
					}
				}
			},
			async processRefund () {
				const date = new Date()
				const id = this.getUniqueId()
				const taxes = {}
				let credits = []
				const creditPaymentMethod = this.orderOutstandingAmount > 0
					? this.order.payment_methods.find(pm => pm.slug === 'credit')
					: null
				const refundPaymentMethod = this.paymentMethods.find(pm => pm.value === this.paymentMethodId)

				const refund = {
					id,
					merchant_id: this.merchant.id,
					device_id: this.deviceId,
					employee_id: this.employee.id,
					employee_shift_id: this.employeeShift.id,
					order_id: this.order.id,
					payment_method_id: this.paymentMethodId,
					items: this.refundItems.reduce((refundItems, refundItem) => {
						if (refundItem.refunded_qty > 0) {
							const refundedItem = {
								id: this.deviceId + refundItem.variation_id + date.valueOf(),
								item_code: refundItem.item_code,
								refunded_qty: parseFloat(refundItem.refunded_qty),
								refunded_amount: this.$currency.transformNumber(refundItem.refunded_amount),
								refunded_tax: this.$currency.transformNumber(refundItem.refunded_tax),
								refunded_taxes: refundItem.tax_details.map((tax) => {
									const taxRate = this.$currency.transformNumber(tax.tax_amount / refundItem.tax)

									tax.refunded_tax = this.$currency.transformNumber(refundItem.refunded_tax * taxRate)
									tax.discounted_tax = this.$currency.transformNumber(refundItem.refunded_discounted_tax * taxRate)

									if (taxes[tax.tax_id]) {
										taxes[tax.tax_id].discounted_tax = this.$currency.transformNumber(taxes[tax.tax_id].discounted_tax + tax.discounted_tax)
										taxes[tax.tax_id].tax_amount = this.$currency.transformNumber(taxes[tax.tax_id].tax_amount + tax.tax_amount)
										taxes[tax.tax_id].refunded_tax = this.$currency.transformNumber(taxes[tax.tax_id].refunded_tax + tax.refunded_tax)
									} else {
										taxes[tax.tax_id] = { ...tax }
									}

									return tax
								})
							}

							refundItems.push(refundedItem)
							refundedTax = this.$currency.transformNumber(refundedTax + refundedItem.refunded_tax)
						}

						return refundItems
					}, []),
					amount: this.$currency.transformNumber(this.amount),
					tax: this.$currency.transformNumber(refundedTax),
					taxes: Object.values(taxes),
					type: this.amount === this.$currency.transformNumber(this.order.total_price - (this.order.tip || 0)) ? 'full' : 'partial',
					reason: this.reason === 'Other' ? this.otherReason : this.reason,
					restock: this.restock,
					custom_attributes: {
						...this.customAttributes,
						is_charges_included: this.refundCharges,
						restock: this.restockOptions.find(r => r.value === this.restock)?.text || ''
					},
					created_at: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
					updated_at: date
				}

				if (creditPaymentMethod && this.order.customer && this.orderOutstandingAmount > 0 && (this.appVersionNumber >= 4110 || (this.bridgeName === 'ANDROID' && this.appVersionNumber >= 4029))) {
					credits = this.order.credits.map(c => ({
						...c,
						employee_shift_id: c.employee_shift.id,
						customer_id: c.customer.id,
						payment_method: c.payment_method.slug,
						custom_attributes: this.objToJson({
							...c.custom_attributes
						})
					}))

					credits.push({
						id: this.getUniqueId(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						employee_id: this.employee.id,
						employee_shift_id: this.employeeShift.id,
						customer_id: this.order.customer.id,
						order_id: this.id,
						credit_code: `${this.deviceId}${date.valueOf()}`,
						amount: parseFloat(this.amount > creditPaymentMethod.amount ? creditPaymentMethod.amount : this.amount),
						payment_method: refundPaymentMethod.slug,
						type: 'void',
						notes: '',
						custom_attributes: this.objToJson({
							source: 'complete'
						}),
						is_synced: true,
						created_at: date,
						updated_at: date
					})
				}

				const order = {
					id: this.order.id,
					due_amount: this.order.due_amount > 0
						? this.$currency.transformNumber(this.order.due_amount - refund.amount)
						: 0,
					refunds: [...this.order.refunds, refund].map((r) => {
						return {
							...r,
							items: r.items.map(item => ({
								...item,
								refunded_taxes: this.objToJson(item.refunded_taxes)
							})),
							taxes: this.objToJson(r.taxes),
							custom_attributes: this.objToJson(r.custom_attributes)
						}
					})
				}

				if (credits.length) {
					order.credits = credits
				}

				let syncData = {
					id: this.getUniqueId(+refund.id + 1),
					model_id: refund.id,
					model_name: 'order-refund',
					payload: this.objToJson({
						model_id: refund.id,
						order_id: refund.order_id,
						payment_method_id: refund.payment_method_id,
						employee_shift_code: this.employeeShift.shift_code,
						items: refund.items,
						refunded_amount: refund.amount,
						refunded_tax: refund.tax,
						type: refund.type,
						reason: refund.reason,
						restock: refund.restock,
						custom_attributes: refund.custom_attributes,
						created_at: refund.created_at
					})
				}
				const createRefund = JSON.parse(syncData.payload)

				createRefund.order_id = this.order.order_id

				const response = await this.$store.dispatch('createRefund', createRefund)

				if (response?.data.data?.orderRefund) {
					const customAttributes = JSON.parse(order.refunds[order.refunds.length - 1].custom_attributes)

					customAttributes.qr = response.data.data.orderRefund.customAttributes.qr
					order.refunds[order.refunds.length - 1].custom_attributes = this.objToJson(customAttributes)
					order.refunds[order.refunds.length - 1].is_synced = true
				}

				await this.$bridge.insert(
					'Order',
					this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
					true
				)

				if (!response && !this.isMiniPlan) {
					await this.$bridge.insert(
						'Sync',
						this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
						true
					)
				}

				if (refundPaymentMethod?.slug === 'cash') {
					const event = {
						id,
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						employee_id: this.employee.id,
						employee_shift_id: this.employeeShift.id,
						cash_drawer_shift_id: this.cashDrawerShift.id,
						receipt_code: this.order.receipt_code,
						amount: refund.amount,
						cash_via: 'refund',
						type: 'cash_out',
						is_synced: !!this.isMiniPlan,
						updated_at: date
					}

					await this.$bridge.insert(
						'CashDrawerShiftEvent',
						this.bridgeName === 'ANDROID' ? this.objToJson(event) : event,
						true
					)
					await this.$bridge.openCashDrawer(
						this.bridgeName === 'ANDROID'
							? this.objToJson(this.printerSettings)
							: this.printerSettings
					)

					if (!this.isMiniPlan) {
						syncData = {
							id: this.getUniqueId(+refund.id + 2),
							model_id: event.id,
							model_name: 'cash-drawer-shift-event',
							payload: this.objToJson({
								model_id: event.id,
								cash_drawer_shift_id: event.cash_drawer_shift_id,
								employee_id: event.employee_id,
								receipt_code: event.receipt_code,
								event_type: event.type,
								cash_via: event.cash_via,
								event_money: event.amount,
								shift_event_code: `${this.deviceId}${date.valueOf()}`,
								employee_shift_code: this.employeeShift.shift_code
							})
						}
						await this.$bridge.insert(
							'Sync',
							this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
							true
						)
					}
				}

				if (creditPaymentMethod && this.order.customer) {
					const customer = Object.assign({}, this.order.customer, {
						device_id: this.deviceId,
						address: this.objToJson(this.order.customer.address),
						credit: this.order.customer.credit - (
							refund.amount * (creditPaymentMethod.amount / this.order.total_price)
						),
						custom_attributes: this.objToJson(this.order.customer.custom_attributes),
						updated_at: date
					})

					await this.$bridge.insert(
						'Customer',
						this.bridgeName === 'ANDROID' ? this.objToJson(customer) : customer,
						true
					)
				}

				this.$refs.issueRefund?.hide()

				const div = document.createElement('div')
				const swalContent = `<div class='row'>
					<div class='col text-right'>
						<h4 class='text-capitalize m-0'>${this.$t('total')} :</h4>
						<p class='text-capitalize m-0'>${this.$t('amount refunded')} :</p>
					</div>
					<div class='col text-left'>
						<h4 class='m-0'>${this.$currency.toCurrency(this.order.total_price)}</h4>
						<p class='m-0'>${this.$currency.toCurrency(refund.amount || 0)}</p>
					</div>
				</div>`

				div.innerHTML = swalContent
				this.$swal({
					title: this.$options.filters.capitalize(this.$tc('success')) + '!',
					content: div.firstChild,
					icon: 'success',
					button: this.$t('ok')
				})
				this.$emit('update:refund', {
					...refund,
					payment_method: refundPaymentMethod
				})
			}
		}
	}
