
	export default {
		props: {
			i18n: {
				type: Object,
				required: true
			},
			bridge: {
				type: Object,
				required: true
			},
			moment: {
				type: Function,
				required: true
			},
			domtoimage: {
				type: Object,
				required: true
			},
			data: {
				type: Object,
				required: true
			}
		},
		data () {
			return {
				order: null,
				kotDevice: null
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			printerSettings () {
				return this.$store.state.printerSettings
			}
		},
		beforeMount () {
			this.order = this.data.order
			this.kotDevice = this.data.kot
		},
		methods: {
			renderReceipt () {
				const iframe = document.getElementById(this.data.id)
				const iframeDocument = iframe.contentDocument
				const el = iframeDocument.getElementById('receipt')
				const height = el.offsetHeight * this.printerSettings.scaleFactor
				const width = el.offsetWidth * this.printerSettings.scaleFactor

				this.domtoimage.toPng(el, {
					height,
					width
				}).then((dataURL) => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line
						console.log('%c ', `font-size: 1px; padding: ${Math.floor(height / 2)}px ${Math.floor(width / 2)}px; background: url(${dataURL})`)
					}

					const printData = {
						dataURL,
						printerSettings: {
							...this.printerSettings,
							...this.kotDevice
						}
					}

					this.bridge.pushPrinterQueue(this.bridgeName === 'ANDROID' ? this.objToJson(printData) : printData)
				}).catch(console.error).finally(() => {
					this.$emit('destroy')
				})
			}
		}
	}
