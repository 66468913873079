
	export default {
		data () {
			return {
				functionKeys: [
					'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7',
					'F8', 'F9', 'F10', 'F11', 'F12',
					'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
					'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
					'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
				]
			}
		},
		computed: {
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			availableFunctionKeys () {
				return this.functionKeys.filter((key) => {
					return !Object.values(this.keyboardShortcuts).includes(key)
				})
			}
		},
		watch: {
			keyboardShortcuts: {
				handler () {
					this.$bridge.setLocalStorage(
						'keyboardShortcuts', this.objToJson(this.keyboardShortcuts)
					)
					this.setTabsShortcuts()
				},
				deep: true
			}
		}
	}
