
	export default {
		data () {
			return {
				types: [
					{
						text: 'TCP/IP',
						value: 'tcpip'
					},
					{
						text: 'Bluetooth',
						value: 'bluetooth'
					}
				]
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			skyband: {
				get () {
					return this.$store.state.skyband
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'skyband',
						value,
						save: true
					})
				}
			},
			bluetoothDevices () {
				return JSON.parse(this.$bridge.getSerialPorts())
			}
		}
	}
