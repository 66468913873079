
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			selectedTax: {
				type: Object,
				default () {
					return {}
				}
			}
		},
		data () {
			return {
				selectedTaxes: [],
				taxes: [],
				taxTypes: [
					{
						text: 'tax type',
						value: null
					},
					{
						text: 'include tax in item price',
						value: 'inclusive'
					},
					{
						text: 'add tax to item price',
						value: 'additive'
					}
				],
				newTax: {
					name: null,
					percentage: null,
					type: null
				}
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			taxCreationModal: {
				get () {
					return this.$store.state.taxCreationModal
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'taxCreationModal',
						value
					})
				}
			}
		},
		methods: {
			initModal () {
				if (this.selectedTax) {
					this.newTax = {
						name: this.selectedTax.name,
						percentage: this.selectedTax.rate,
						type: this.selectedTax.inclusion_type
					}
				}
			},
			async processTaxCreation () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()
					const newTax = {
						id: this.selectedTax?.id || date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						tax_type_id: 1,
						name: this.newTax.name,
						rate: parseFloat(this.newTax.percentage),
						inclusion_type: this.newTax.type,
						updated_at: date

					}

					await this.$bridge.insert(
						'Tax',
						this.bridgeName === 'ANDROID' ? this.objToJson(newTax) : newTax,
						true
					)

					this.$emit('update:taxes')
					this.taxCreationModal = {
						show: false,
						data: newTax
					}

					if (typeof window.taxCreationModalCallback === 'function') {
						window.taxCreationModalCallback(newTax)
					}
				}
			},
			resetModal () {
				this.newTax = {
					name: null,
					percentage: null,
					type: null
				}
				this.taxCreationModal.show = false
				delete window.taxCreationModalCallback
			}
		}
	}
