
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import LocationSearchBox from '~/components/location-search-box'
	import GoogleMap from '~/components/google-map'

	const initialState = () => ({
		customers: [],
		customer: { last_name: '', email: '' },
		selectedCustomer: false,
		orderType: null,
		selectedDeliveryAddress: null,
		editedDeliveryAddress: null,
		deliveryAddress: {
			annotation: '',
			line: '',
			area: '',
			city: '',
			state: '',
			country: '',
			zip: '',
			landmark: ''
		},
		deliveryAddresses: [],
		markers: [],
		mapConfig: {
			zoom: 16,
			zoomControl: true,
			centerMarker: true,
			disableDefaultUI: true
		},
		showMapOverlay: false
	})

	export default {
		components: {
			LocationSearchBox,
			GoogleMap,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				hasCredit: true,
				hasCOD: true,
				...initialState()
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			settings () {
				return this.$store.state.settings
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			isOnline () {
				return this.$store.state.isOnline
			},
			currentDeliveryAddressHasCoordinates () {
				return this.deliveryAddresses[this.selectedDeliveryAddress]?.coordinates?.filter(Boolean).length
			}
		},
		watch: {
			isOnline () {
				this.calculateDeliveryDistance()
			},
			selectedDeliveryAddress () {
				this.calculateDeliveryDistance()
			}
		},
		async beforeMount () {
			const paymentMethods = await this.$bridge.getPaymentMethods(this.deviceId, '')

			this.paymentMethods = typeof paymentMethods === 'string' ? JSON.parse(paymentMethods) : paymentMethods
			this.hasCredit = this.paymentMethods.findIndex(pm => pm.slug === 'credit') !== -1
			this.hasCOD = this.paymentMethods.findIndex(pm => pm.slug === 'cod') !== -1
			this.markers = [{ position: { lat: Number(this.location.latitude), lng: Number(this.location.longitude) } }]
		},
		methods: {
			init () {
				if (this.cart.storeOrder) {
					this.customer = this.cart.storeOrder.customer
					this.selectedCustomer = true
					this.orderType = this.cart.storeOrder.orderType
					this.deliveryAddresses = this.customer.custom_attributes.delivery_address
					this.deliveryAddress = this.cart.storeOrder.deliveryAddress
					this.selectedDeliveryAddress = this.cart.storeOrder.deliveryAddress?.id

					if (this.currentDeliveryAddressHasCoordinates) {
						const coordinates = this.deliveryAddresses[this.selectedDeliveryAddress].coordinates

						this.markers = [{ position: { lat: coordinates[1], lng: coordinates[0] } }]
					}
				}
			},
			confirmBeforeReset () {
				this.$swal({
					title: this.$t('reset'),
					text: this.$t('resetAllFields'),
					icon: 'warning',
					buttons: [this.$tc('cancel', 1), this.$t('proceed')]
				}).then((res) => {
					if (res) {
						this.resetAll()
						this.cart.storeOrder = null
					}
				})
			},
			resetAll () {
				Object.assign(this.$data, initialState())
				this.markers = [{ position: { lat: Number(this.location.latitude), lng: Number(this.location.longitude) } }]
				this.$refs.customerValidator?.reset()
				this.$refs.orderTypeValidator?.reset()
				this.$refs.deliveryAddressValidator?.reset()
			},
			async getCustomers (searchTerm) {
				if (searchTerm) {
					const customers = await this.$bridge.getCustomers(
						this.deviceId,
						this.objToJson({ search_term: searchTerm })
					)

					this.customers = (
						typeof customers === 'string' ? JSON.parse(customers) : customers
					)?.data || []
				} else {
					this.customers = []
				}
			},
			selectCustomer (customer) {
				this.$refs.searchTerm.$el.value = ''
				this.customers = []
				this.customer = { ...customer }

				if (!customer.custom_attributes?.delivery_address || // customers with no delivery address
					(Array.isArray(customer.custom_attributes?.delivery_address) &&
						!customer.custom_attributes?.delivery_address.length)) { // customers with empty delivery address array
					this.customer.custom_attributes.delivery_address = [customer.address]
				} else if (!Array.isArray(customer.custom_attributes?.delivery_address)) { // customers with old delivery address (not an array)
					this.customer.custom_attributes.delivery_address = [this.customer.custom_attributes.delivery_address]
				}

				this.deliveryAddresses = this.customer.custom_attributes.delivery_address.map((da, i) => {
					return {
						line: '',
						area: '',
						city: '',
						state: '',
						country: '',
						zip: '',
						landmark: '',
						...da,
						id: i,
						annotation: da.annotation || `Address ${i + 1}`
					}
				})
				this.selectedDeliveryAddress = 0
				this.deliveryAddress = this.deliveryAddresses[this.selectedDeliveryAddress]

				if (this.currentDeliveryAddressHasCoordinates) {
					this.markers = [
						{
							position: {
								lat: parseFloat(this.deliveryAddresses[this.selectedDeliveryAddress].coordinates[1]),
								lng: parseFloat(this.deliveryAddresses[this.selectedDeliveryAddress].coordinates[0])
							}
						}
					]
				} else {
					this.selectedDeliveryAddress = null
				}

				this.selectedCustomer = true
			},
			async createEditCustomer () {
				if (await this.$refs.customerValidator.validate()) {
					this.selectedCustomer = !this.selectedCustomer
				}
			},
			editCurrentDeliveryAddress () {
				if (this.isOnline) {
					this.deliveryAddress = this.deliveryAddresses[this.selectedDeliveryAddress]
					this.editedDeliveryAddress = this.selectedDeliveryAddress
					this.selectedDeliveryAddress = null
				} else {
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('deliveryAddressInternet'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			addDeliveryAddress () {
				if (this.isOnline) {
					this.editedDeliveryAddress = this.deliveryAddresses.length
					this.deliveryAddresses[this.deliveryAddresses.length] = { id: this.deliveryAddresses.length }
					this.deliveryAddress = {
						annotation: '',
						line: '',
						area: '',
						city: '',
						state: '',
						country: '',
						zip: '',
						landmark: ''
					}
					this.selectedDeliveryAddress = null
				} else {
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('deliveryAddressInternet'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			deleteCurrentDeliveryAddress () {
				this.deliveryAddresses.splice(this.selectedDeliveryAddress, 1)

				if (this.deliveryAddresses.length >= 1) {
					this.selectedDeliveryAddress = this.selectedDeliveryAddress > 1 ? this.selectedDeliveryAddress-- : 0
				} else if (this.isOnline) {
					this.selectedDeliveryAddress = null
					this.editedDeliveryAddress = 0
				} else {
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('deliveryAddressInternet'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			removeCustomer () {
				this.customer = {}
				this.selectedCustomer = false
				this.$refs.customerValidator.reset()
			},
			async saveDeliveryAddress () {
				if (await this.$refs.deliveryAddressValidator.validate()) {
					this.deliveryAddresses[this.editedDeliveryAddress || 0] = { ...this.deliveryAddress, id: (this.editedDeliveryAddress || 0) }
					this.selectedDeliveryAddress = this.editedDeliveryAddress || 0
					this.editedDeliveryAddress = null
					this.customer = {
						...this.customer,
						custom_attributes: {
							delivery_address: this.deliveryAddresses
						}
					}
				}
			},
			proceedToCart () {
				if (((this.orderType === 'delivery' &&
					this.hasCOD &&
					this.currentDeliveryAddressHasCoordinates) ||
					(this.orderType === 'pickup' && this.hasCredit))) {
					if (!this.customer.id) {
						this.customer = {
							...this.customer,
							id: +this.customer.phone,
							address: {
								annotation: '',
								line: '',
								area: '',
								city: '',
								state: '',
								country: '',
								zip: '',
								landmark: ''
							},
							credit_limit: 0.0,
							credit: 0.0,
							debit: 0.0,
							is_active: true,
							custom_attributes: {
								...this.customer.custom_attributes,
								delivery_address: this.deliveryAddresses
							}
						}
					}

					this.customer.custom_attributes.delivery_address = this.deliveryAddresses

					this.cart = {
						...this.cart,
						customer: this.customer,
						storeOrder: {
							customer: this.customer,
							orderType: this.orderType,
							deliveryAddress: this.deliveryAddresses[this.selectedDeliveryAddress]
						}
					}

					if (this.cart.items?.length) {
						this.$store.dispatch('cartCalculation')
					}

					this.$bvModal.hide('caller-id-modal')
				}
			},
			updateLocation (response) {
				const location = response

				this.markers = [
					{
						position: {
							lat: parseFloat(location.coordinates[1]),
							lng: parseFloat(location.coordinates[0])
						}
					}
				]

				this.deliveryAddress.area = location.area
				this.deliveryAddress.city = location.city
				this.deliveryAddress.country = location.country
				this.deliveryAddress.line = location.line
				this.deliveryAddress.state = location.state
				this.deliveryAddress.zip = location.zip
				this.deliveryAddress.coordinates = location.coordinates
				this.deliveryAddress.distance = location.distance
				this.deliveryAddress.duration = location.duration
			},
			async calculateDeliveryDistance () {
				if (this.isOnline && this.currentDeliveryAddressHasCoordinates) {
					const lat = this.deliveryAddresses[this.selectedDeliveryAddress].coordinates[1]
					const lng = this.deliveryAddresses[this.selectedDeliveryAddress].coordinates[0]
					const distance = await this.$store.dispatch('getDistance', {
						origins: `${this.location.latitude},${this.location.longitude}`,
						destinations: `${lat},${lng}`
					})

					this.deliveryAddress.distance = distance.rows[0].elements[0].distance
					this.deliveryAddress.duration = distance.rows[0].elements[0].duration
				}
			}
		}
	}
