
	export default {
		props: {
			selectedCartItemIndex: {
				type: Number,
				required: true
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			cart () {
				return this.$store.state.cart
			}
		}
	}
