
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				file: null,
				update: false,
				importState: {
					status: '',
					imported_entries: 0,
					failed_entries: 0,
					total_entries: 0,
					errors: []
				}
			}
		},
		computed: {
			cart () {
				return this.$store.state.cart
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			progress () {
				return Math.floor(
					(
						this.importState.imported_entries + this.importState.failed_entries
					) / this.importState.total_entries * 100
				)
			}
		},
		mounted () {
			window.importStatus = this.importStatus
		},
		destroyed () {
			delete window.importStatus
		},
		methods: {
			async handleFileChange ($event) {
				const { valid } = await this.$refs.fileValidator.validate($event)

				if (valid) {
					this.file = $event.target.files[0]
				}
			},
			async importSheet () {
				if (await this.$refs.validator.validate()) {
					await this.$bridge.importSheet({
						merchantId: this.merchant?.id,
						deviceId: this.deviceId,
						locationId: this.locationId,
						filePath: this.file.path,
						update: this.update
					})
				}
			},
			importStatus (response) {
				Object.assign(this.importState, response)
			},
			downloadSample () {
				this.$bridge.downloadFile({
					fileName: this.merchant.businessType === 'grocery'
						? 'import-sample-retail.xlsx'
						: 'import-sample.xlsx'
				})
			}
		}
	}
