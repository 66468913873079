
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	const initialState = () => {
		return {
			tipType: 'fixed',
			tipValue: 20,
			options: [
				{
					value: 'fixed',
					text: 'fix',
					pluralizationIndex: 2
				},
				{
					value: 'percentage',
					text: 'percentage'
				}
			]
		}
	}

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			orderId: {
				type: [Number, String],
				default: null
			}
		},
		data () {
			return initialState()
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			tip: {
				get () {
					return this.$store.state.tip
				},
				set (value) {
					this.$store.commit('setState', { key: 'tip', value })
				}
			}
		},
		methods: {
			init () {
				if (this.tip) {
					this.tipType = this.tip.type
					this.tipValue = this.tip.value
				}
			},
			async addTip () {
				if (await this.$refs.validator.validate()) {
					this.tip = { type: this.tipType, value: this.tipValue }
					this.$store.dispatch('cartCalculation')

					if (this.orderId) {
						this.$root.$emit('update-table-order')
					}

					this.$refs.modal.hide()
				}
			},
			reset () {
				Object.assign(this.$data, initialState())
			}
		}
	}
