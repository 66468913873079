
	export default {
		props: {
			size: {
				type: String,
				default: 'md'
			},
			classes: {
				type: Array,
				default: () => ([])
			},
			customStyle: {
				type: Object,
				default: () => ({})
			},
			close: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: 'Search location'
			},
			geolocation: {
				type: Boolean,
				default: true
			},
			save: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				top: {
					sm: 30,
					md: 38,
					lg: 48
				},
				loading: false,
				locations: [],
				timer: null,
				token: null
			}
		},
		computed: {
			location () {
				return this.$store.state.location
			}
		},
		beforeMount () {
			this.generateToken()
		},
		methods: {
			generateToken () {
				this.token = this.$uuid.v4()
			},
			getPlaces ($event) {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					if ($event) {
						this.loading = true
						this.$store.dispatch('getPlaces', {
							input: $event,
							sessiontoken: this.token
						}).then((response) => {
							if (response.status === 'OK') {
								this.locations = response.predictions
							} else {
								this.locations = ['']
							}

							this.loading = false
						}).catch((err) => {
							this.showErrors(err)
							this.loading = false
						})
					} else {
						this.locations = []
						this.generateToken()
					}
				}, 500)
			},
			getCurrentLocation () {
				this.$bvToast.hide()

				if (navigator.geolocation) {
					this.loading = true
					navigator.geolocation.getCurrentPosition((position) => {
						this.setDeliveryLocation({
							latLng: `${position.coords.latitude},${position.coords.longitude}`
						})
					}, (err) => {
						this.loading = false

						if (err.code === 1) {
							this.showToast(this.$t('toastMessage.locationAccess'), {
								title: this.$t('access required'),
								variant: 'danger'
							})
						}
					}, {
						maximumAge: 10000,
						timeout: 5000,
						enableHighAccuracy: true
					})
				} else {
					this.showToast(this.$t('toastMessage.geoNotSupported'), {
						title: this.$options.filters.capitalize(this.$t('unsupported')),
						variant: 'danger'
					})
				}
			},
			async setDeliveryLocation (data) {
				this.loading = true
				this.$refs.input.$el.value = ''
				this.locations = []

				try {
					const response = await this.$store.dispatch(data.placeId ? 'getPlace' : 'getPlaceByGeocode', Object.assign({
						save: this.save
					}, {
						place_id: data.placeId || null,
						sessiontoken: data.placeId ? this.token : null,
						latlng: data.latLng || null
					}))

					if (response.status === 'OK') {
						const deliveryAddress = response.location
						const distance = await this.$store.dispatch('getDistance', {
							origins: `${this.location.latitude},${this.location.longitude}`,
							destinations: `${deliveryAddress.coordinates[1]}, ${deliveryAddress.coordinates[0]}`
						})

						deliveryAddress.distance = distance.rows[0].elements[0].distance
						deliveryAddress.duration = distance.rows[0].elements[0].duration

						this.$emit('location', deliveryAddress)

						if (this.save) {
							this.$store.commit('setLocation', deliveryAddress)
						}
					}
				} catch (err) {
					console.error('err', err)
				}

				if (data.placeId) {
					this.generateToken()
				}
			},
			hide () {
				this.$emit('hide')
			}
		}
	}
