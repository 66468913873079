
	export default {
		data () {
			return {
				options: [
					{
						text: 'English',
						value: 'en'
					},
					{
						text: 'Arabic',
						value: 'ar'
					},
					{
						text: 'French',
						value: 'fr'
					},
					{
						text: 'German',
						value: 'de'
					},
					{
						text: 'Spanish',
						value: 'es'
					}
				]
			}
		},
		computed: {
			locale: {
				get () {
					return this.$store.state.locale
				},
				set (value) {
					this.$store.commit('setLocale', value)
				}
			}
		}
	}
