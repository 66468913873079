
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				categories: {
					data: [],
					offset: 0,
					total: 0
				},
				selectedCategory: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'parent_category',
						label: 'parent category',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				],
				categoryName: null,
				parentCategory: null,
				parentCategories: [],
				categoryHasParentCategory: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			cart () {
				return this.$store.state.cart
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getCategories()
		},
		mounted () {
			this.$root.$on('bv::modal::hidden', this.hideSheetImportModal)
		},
		destroyed () {
			this.$root.$off('bv::modal::hidden', this.hideSheetImportModal)
		},
		methods: {
			async getCategories (isLoadMore, searchTerm) {
				let data = await this.$bridge.getCategories(this.deviceId, this.objToJson({
					search_term: searchTerm,
					offset: isLoadMore ? this.categories.offset + 20 : 0
				}))

				data = (typeof data === 'string' ? JSON.parse(data) : data)

				if (searchTerm) {
					this.parentCategories = data.data
				} else {
					this.categories.data = data.offset ? this.categories.data.concat(data.data) : data.data || data
					this.categories.offset = data.offset || 0
					this.categories.total = data.total || data.data.length

					if (data.offset === 0) {
						this.parentCategories = data.data
					}
				}
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getCategories(true)
				}
			},
			importCategories () {
				this.$root.$emit('show-sheet-import-modal', 'Category')
			},
			createCategory () {
				this.selectedCategory = null
				this.$refs.categoryModal.show()
			},
			editCategory (category) {
				this.selectedCategory = category
				this.$refs.categoryModal.show()
			},
			deleteCategory (category) {
				if (this.cart.items.length) {
					return this.$swal({
						title: this.$t('actionCannotPerformed'),
						text: this.$t('clearCartItems'),
						icon: 'error',
						button: this.$t('ok')
					})
				}

				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						const date = new Date()
						let items = await this.$bridge.getItems(
							this.deviceId, this.locationId, this.objToJson({
								category_id: category.id,
								skip_sub_categories: true,
								offset: -1
							})
						)

						items = (typeof items === 'string' ? JSON.parse(items) : items).data

						if (items.length) {
							let itemVariations = await this.$bridge.getItemVariations(
								this.deviceId, this.objToJson({
									item_id: items.map(i => i.id),
									offset: -1
								})
							)

							itemVariations = (typeof itemVariations === 'string' ? JSON.parse(itemVariations) : itemVariations).data

							if (itemVariations.length) {
								await this.$bridge.updateMany('ItemVariation', itemVariations.map(iv => ({
									id: iv.id,
									is_active: false,
									updated_at: date
								})))
							}

							await this.$bridge.updateMany('Item', items.filter(i => i.category.id === category.id).map(item => ({
								id: item.id,
								is_active: false,
								updated_at: date
							})))
						}

						let categories = await this.$bridge.getCategories(this.deviceId, this.objToJson({
							parent_category_id: category.id,
							offset: -1
						}))

						categories = (typeof categories === 'string' ? JSON.parse(categories) : categories)
						categories = categories.data || categories

						if (categories.length) {
							await this.$bridge.updateMany('Category', categories.map(c => ({
								id: c.id,
								parent_category_id: null,
								updated_at: date
							})))
						}

						category = {
							id: category.id,
							is_active: false,
							updated_at: date
						}
						await this.$bridge.insert(
							'Category',
							this.$bridge.getName() === 'ANDROID' ? this.objToJson(category) : category,
							true
						)
						this.getCategories()
					}
				})
			},
			async initModal () {
				if (this.selectedCategory) {
					this.categoryName = this.selectedCategory.name
					this.parentCategory = this.selectedCategory.parent_category

					if (this.selectedCategory) {
						let data = await this.$bridge.getCategories(this.deviceId, this.objToJson({
							parent_category_id: this.selectedCategory.id
						}))

						data = (typeof data === 'string' ? JSON.parse(data) : data)
						data = data.data || data
						this.categoryHasParentCategory = data.map(d => d.id)
						this.categoryHasParentCategory.push(this.selectedCategory.id)
					}
				}
			},
			async createOrUpdateCategory () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()

					const category = {
						id: this.selectedCategory?.id || +date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						parent_category_id: this.parentCategory?.id || null,
						name: this.categoryName,
						sub_category_count: this.selectedCategory?.sub_category_count || 0,
						item_count: this.selectedCategory?.item_count || 1,
						devices: [],
						custom_attributes: this.objToJson(this.selectedCategory?.custom_attributes || {}),
						is_active: true,
						is_available: true,
						updated_at: date
					}

					await this.$bridge.insert(
						'Category',
						this.$bridge.getName() === 'ANDROID' ? this.objToJson(category) : category,
						true
					)

					let updateParentCategory = null

					if (this.selectedCategory && this.selectedCategory.parent_category && (!this.parentCategory || (this.selectedCategory.parent_category?.id !== this.parentCategory?.id))) {
						updateParentCategory = {
							...this.selectedCategory.parent_category,
							sub_category_count: this.selectedCategory.parent_category.sub_category_count - category.sub_category_count - 1,
							item_count: this.selectedCategory.parent_category.item_count - category.item_count,
							custom_attributes: this.objToJson(this.selectedCategory.parent_category.custom_attributes),
							updated_at: date
						}
						await this.$bridge.insert(
							'Category',
							this.$bridge.getName() === 'ANDROID' ? this.objToJson(updateParentCategory) : updateParentCategory,
							true
						)
					}

					if (this.parentCategory && (!this.selectedCategory || !this.selectedCategory.parent_category || (this.selectedCategory.parent_category.id !== this.parentCategory.id))) {
						updateParentCategory = {
							...this.parentCategory,
							sub_category_count: this.parentCategory.sub_category_count + category.sub_category_count + 1,
							item_count: this.parentCategory.item_count + category.item_count,
							custom_attributes: this.objToJson(this.parentCategory.custom_attributes),
							updated_at: date
						}
						await this.$bridge.insert(
							'Category',
							this.$bridge.getName() === 'ANDROID' ? this.objToJson(updateParentCategory) : updateParentCategory,
							true
						)
					}

					this.$refs.categoryModal.hide()
					this.getCategories()
				}
			},
			resetModal () {
				this.categoryName = null
				this.parentCategory = null
			},
			async hideSheetImportModal (bvEvent, modalId) {
				if (modalId === 'sheet-import-modal') {
					await this.getCategories()
				}
			}
		}
	}
