
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				loading: false,
				reason: 'other',
				reasons: [
					'customer no longer needed',
					'delay in preparation',
					'expired food',
					'food not cooked well',
					'item not available',
					'wrong food delivered',
					'other'
				],
				otherReason: ''
			}
		},
		computed: {
			kotCancellationModal () {
				return this.$store.state.kotCancellationModal
			},
			kotCancellationReason () {
				return this.$store.state.kotCancellationReason
			},
			selectedOrder: {
				get () {
					return this.$store.state.selectedOrder
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedOrder', value })
				}
			}
		},
		methods: {
			initModal () {
				this.loading = false
			},
			resetModal () {
				this.loading = false
				this.kotCancellationModal.show = false
				delete window.kotCancellationModalCallback
			},
			async processKotCancellation () {
				this.loading = true

				if (await this.$refs.validator.validate() && this.kotCancellationModal.data.item) {
					const combos = this.kotCancellationModal.data.item.groups?.filter(g => g.type === 'combo') || []

					if (combos.length) {
						for (let i = 0; i < combos.length; i++) {
							this.kotCancellationReason.push({
								orderItemCode: this.kotCancellationModal.data.item.item_code,
								kotItemCode: this.kotCancellationModal.data.item.item_code.slice(0, -1) + i,
								cancellationReason: this.reason === 'other' ? this.otherReason : this.reason
							})
						}
					} else {
						this.kotCancellationReason.push({
							orderItemCode: this.kotCancellationModal.data.item.item_code,
							kotItemCode: this.kotCancellationModal.data.item.item_code,
							cancellationReason: this.reason === 'other' ? this.otherReason : this.reason
						})
					}

					if (typeof window.kotCancellationModalCallback === 'function') {
						window.kotCancellationModalCallback(this.reason === 'other' ? this.otherReason : this.reason)
					}
				}

				this.loading = false
			}
		}
	}
