
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	let debounceTimer = null

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				item: null,
				giftCards: [],
				giftAmount: 0,
				redemptionType: null,
				selectedGiftCard: '',
				redemptionTypes: [
					{
						text: 'selectOption',
						value: null
					},
					{
						text: 'one time redemption',
						value: 'single'
					},
					{
						text: 'multi time redemption',
						value: 'multi'
					}
				],
				loading: false,
				giftCardCode: '',
				giftCard: null
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			settings () {
				return this.$store.state.settings
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			isOnline () {
				return this.$store.state.isOnline
			}
		},
		watch: {
			locale () {
				requestAnimationFrame(this.$refs.validator.reset)
			}
		},
		async beforeMount () {
			const item = await this.$bridge.getItems(this.deviceId, this.locationId, this.objToJson({
				variation_id: parseInt(this.settings.sale.gift_card_sale)
			}))

			this.item = (typeof item === 'string' ? JSON.parse(item) : item).data[0]
		},
		methods: {
			reset () {
				this.redemptionType = null
				this.selectedGiftCard = ''
				this.giftCards = []
				this.giftCard = null
				this.giftCardCode = ''
				this.$refs.validator.reset()
				this.$refs.giftCardValidator.reset()
			},
			searchGiftCard (searchTerm) {
				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					this.getGiftCards(searchTerm)
				}, 500)
			},
			async getGiftCards (searchTerm) {
				this.giftCards = await this.$store.dispatch('getGiftCardsDetails', {
					discount_entity: 'gift_card',
					is_active: 0,
					query: searchTerm || null
				})

				const cartItemCode = this.cart.items.map(item => item.custom_attributes?.gift_card?.code)

				this.giftCards = this.giftCards?.filter(c => !cartItemCode.includes(c.code))
			},
			async addGiftItem () {
				if (await this.$refs.validator.validate()) {
					this.item.variations[0].quantity = 1
					this.item.variations[0].price = this.selectedGiftCard.getDiscountValue
					this.item.variations[0].itemization_type = 'gift_card'
					this.$store.dispatch('modifyCart', {
						item: {
							id: this.item.id,
							name: this.item.name,
							category_id: this.item.category.id,
							brand_id: this.item.brand?.id || null
						},
						variation: {
							...this.item.variations[0],
							custom_attributes: {
								...this.item.custom_attributes,
								gift_card: {
									id: this.selectedGiftCard.id,
									redemption_type: this.redemptionType,
									code: this.selectedGiftCard.code
								}
							}
						}
					})
					this.reset()
				}
			},
			async checkGiftCardValidity () {
				if (await this.$refs.giftCardValidator.validate()) {
					this.loading = true

					try {
						const response = await this.$store.dispatch('validateGiftCard', {
							code: this.giftCardCode.slice(0, 5) + '-' + this.giftCardCode.slice(5, 10) + '-' + this.giftCardCode.slice(10, 15)
						})

						this.giftCard = response.data.data
					} catch (err) {
						this.giftCard = err.response?.data?.data?.data && Object.keys(err.response?.data?.data?.data).length ? err.response?.data?.data?.data : null
						this.$refs.giftCardValidator.setErrors({
							'gift card code': err.response?.data?.data?.message || this.$t('invalid code')
						})
					} finally {
						this.loading = false
					}
				}
			}
		}
	}
