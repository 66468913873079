
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				username: '',
				password: '',
				deviceCode: '',
				forceLogin: 0,
				deviceCodeToggle: false,
				showPassword: false
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			partner () {
				return this.$store.state.partner
			},
			referrer () {
				return window.location.origin
			},
			appVersion () {
				return this.$store.state.appVersion
			},
			uuid () {
				return this.$store.state.uuid
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			employee () {
				return this.$store.state.employee
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			loginToken: {
				get () {
					return this.$store.state.loginToken
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'loginToken',
						value,
						save: value !== null
					})
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			locale () {
				requestAnimationFrame(this.$refs.validator.reset)
			}
		},
		methods: {
			async login () {
				if (await this.$refs.validator?.validate() && this.$offline.state === 'up') {
					if (this.$refs.loginBtn) {
						this.$refs.loginBtn.disabled = true
						this.$refs.loginBtn.classList.add('running')
					}

					this.$store.dispatch('login', {
						body: {
							username: this.username,
							password: this.password,
							code: this.deviceCode,
							uuid: this.uuid,
							token: this.loginToken
						},
						query: {
							force_login: this.forceLogin
						}
					}).then(async (response) => {
						localStorage.setItem('syncOnClockIn', 'false')

						if (response.status === 'success') {
							if (!this.employee || !this.employeeShift.id) {
								try {
									if (!this.isMiniPlan) {
										await this.syncAll({ force: true })
									}

									let employees = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
										type: ['admin', 'staff'],
										offset: -1
									}))

									employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
									employees = employees.data || employees
									this.$store.commit('setState', {
										key: 'employees',
										value: employees
									})
									this.$store.commit('resetProgress')
									this.$store.dispatch('checkSubscription')
								} catch (e) {
									console.error()
								}
							} else {
								this.$store.dispatch('checkSubscription')
							}
						} else {
							if (this.$refs.loginBtn) {
								this.$refs.loginBtn.classList.remove('running')
								this.$refs.loginBtn.disabled = false
							}

							if (response.data && response.data.message) {
								this.$refs.validator.setErrors({
									password: [response.data.message]
								})
							} else {
								this.$swal({
									title: this.$t('oopsError.title'),
									text: this.$t('oopsError.text'),
									icon: 'error',
									button: this.$t('ok')
								})
							}
						}
					}).catch((err) => {
						if (this.$refs.loginBtn) {
							this.$refs.loginBtn.classList.remove('running')
							this.$refs.loginBtn.disabled = false
						}

						if (err.response) {
							switch (err.response.status) {
								case 401:
									this.$refs.validator.setErrors({
										password: [err.response.data.data.message]
									})

									break

								case 409: {
									const div = document.createElement('div')
									const swalContent = `<div class='text-center'>
										<p class='mb-0'>
											${this.$tc('loginError.409.text', 1)}
										</p>
										<p class='mb-0'>
											${this.$tc('loginError.409.text', 2)}
										</p>
										<small>
											${this.$tc('loginError.409.text', 3)}
										</small>
									</div>`

									div.innerHTML = swalContent
									this.$swal({
										title: this.$t('loginError.409.title'),
										icon: 'warning',
										content: div.firstChild,
										buttons: [this.$t('no'), this.$t('yes')]
									}).then((response) => {
										if (response) {
											this.forceLogin = 1
											this.login()
										}
									})

									break
								}

								case 410:
									// this.loginToken = null
									this.$swal({
										title: this.$t('loginError.410.title'),
										text: this.$t('loginError.410.text'),
										icon: 'error',
										button: this.$t('ok')
									})

									break
								case 422:
									this.$refs.validator.setErrors(err.response.data.data.data.errors)

									break
								default:
									this.$swal({
										title: this.$t('oopsError.title'),
										text: this.$t('oopsError.text'),
										icon: 'error',
										button: this.$t('ok')
									})
							}
						}
					})
				} else if (this.$offline.state === 'down') {
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			}
		}
	}
