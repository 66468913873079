
	export default {
		data () {
			return {
				cartKey: 0,
				allItemsKey: 0,
				favoritesKey: 0,
				topItemsKey: 0,
				tabs: {
					allItems: true,
					favorites: false,
					topItems: false,
					onHold: false,
					customSale: false,
					customRefund: false,
					giftCardSale: false
				}
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			employee () {
				return this.$store.state.employee
			},
			locationId () {
				return this.$store.state.locationId
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			settings () {
				return this.$store.state.settings
			},
			cart () {
				return this.$store.state.cart
			},
			selectedItem () {
				return this.$store.state.selectedItem
			},
			item () {
				return this.$store.state.item
			},
			favorites () {
				return this.$store.state.favorites
			},
			onHold () {
				return this.$store.state.onHold[this.employee.id] || []
			},
			checkout () {
				return this.$store.state.checkout
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			kots () {
				return this.$store.state.kots
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			async onHold () {
				await this.$bridge.setLocalStorage('onHold', this.objToJson(this.$store.state.onHold))
			}
		},
		async beforeMount () {
			let discounts = await this.$bridge.getDiscounts(this.deviceId, this.locationId, this.objToJson({
				merchant_id: this.merchant.id, offset: -1
			}))

			discounts = (typeof discounts === 'string' ? JSON.parse(discounts) : discounts)

			this.$store.commit('setState', {
				key: 'discounts',
				value: discounts.data || discounts
			})

			if (typeof this.$bridge.getCharges === 'function') {
				const charges = await this.$bridge.getCharges(this.deviceId, this.locationId, this.objToJson({
					merchant_id: this.merchant.id
				}))

				this.$store.commit('setState', {
					key: 'charges',
					value: (typeof charges === 'string' ? JSON.parse(charges) : charges).data
				})
			}
		},
		methods: {
			activateTab (tab) {
				for (const index in this.tabs) {
					this.tabs[index] = index === tab
				}
			},
			removeOnHold (index) {
				this.$store.dispatch('removeOnHold', index)
			},
			addHoldToCart (index) {
				this.$store.dispatch('addHoldToCart', { index })
				this.activateTab('allItems')
			},
			reRenderItems () {
				const tab = document.querySelector('.sell-menu .nav-link.active')
					.getAttribute('data-content')

				switch (tab) {
					case 'all-items':
						this.allItemsKey++
						break
					case 'favorites':
						this.favoritesKey++
						break
					case 'top-items':
						this.topItemsKey++
						break
				}
			}
		}
	}
