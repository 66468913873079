
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				emailPhone: '',
				loading: false
			}
		},
		computed: {
			shareReceipt: {
				get () {
					return this.$store.state.shareReceipt
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'shareReceipt',
						value
					})
				}
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			}
		},
		watch: {
			shareReceipt () {
				this.emailPhone = this.shareReceipt ? this.shareReceipt.sendReceiptType === 'email' ? this.shareReceipt.customerEmail || '' : this.shareReceipt.customerPhone || '' : ''
			}
		},
		methods: {
			async sendReceipt () {
				if (await this.$refs.validator?.validate()) {
					this.loading = true
					const to = {}

					if (await this.$offline.state !== 'up') {
						this.$bvToast.toast(this.$t('offlineError.title'), {
							title: this.$t(this.shareReceipt.sendReceiptType),
							toaster: 'b-toaster-bottom-right',
							autoHideDelay: 3000
						})
					} else {
						to[(this.shareReceipt && this.shareReceipt.sendReceiptType === 'sms' ? 'phone' : 'email')] = this.emailPhone

						try {
							const response = await this.$axios.post('/api/pos/v2/send-receipt', {
								type: this.shareReceipt.sendReceiptType,
								receipt_code: this.shareReceipt.receiptCode,
								to: [{
									calling_code: this.$store.state.merchant.businessPhone.calling_code,
									...to
								}]
							})

							if (response.status === 204) {
								this.$bvToast.toast(this.$t('receipt sent'), {
									title: this.$options.filters.capitalize(this.$tc('success', 1)),
									toaster: 'b-toaster-bottom-right',
									autoHideDelay: 3000
								})
							}
						} catch (e) {
							this.$bvToast.toast((e.response.data?.message || this.$t('no credits', [this.shareReceipt.sendReceiptType])), {
								title: this.$t('oopsError.title'),
								toaster: 'b-toaster-bottom-right',
								autoHideDelay: 3000
							})
						} finally {
							this.shareReceipt = null
							this.$refs.validator?.reset()
							this.emailPhone = ''
						}
					}

					this.loading = false
				}
			}
		}
	}
