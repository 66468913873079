
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		computed: {
			cart () {
				return this.$store.state.cart
			}
		},
		methods: {
			async saveAdditionalInfo () {
				if (await this.$refs.validator.validate()) {
					this.$refs.additionalInfoModal.hide()
				}
			}
		}
	}
