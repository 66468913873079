

	export default {
		data () {
			return {
				selectedEmployee: null
			}
		},
		computed: {
			partner () {
				return this.$store.state.partner
			},
			referrer () {
				return window.location.origin
			},
			appVersion () {
				return this.$store.state.appVersion
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.merchant
			},
			selectedMerchant: {
				get () {
					return this.$store.state.selectedMerchant
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedMerchant',
						value,
						save: true
					})
				}
			},
			/* merchants () {
				let merchants = []

				if (this.parentMerchant.childMerchants && this.parentMerchant.childMerchants) {
					merchants.push(this.$store.state.merchant)
					merchants = merchants.concat(this.$store.state.merchant.childMerchants)
				}

				return merchants
			}, */
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			employees () {
				return this.$store.state.employees
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			}
		},
		async beforeMount () {
			await this.getEmployees()
		},
		methods: {
			async getEmployees () {
				let employees = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
					merchant_id: this.merchant.id,
					type: ['admin', 'staff'],
					offset: -1
				}))

				employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
				employees = employees.data || employees
				this.$store.commit('setState', {
					key: 'employees',
					value: employees
				})
			},
			async forceSyncEmployees ($event) {
				const el = $event.currentTarget
				let updatedAt = await this.$bridge.getLastUpdatedAt('Employee', this.deviceId)

				updatedAt = typeof updatedAt === 'string' ? JSON.parse(updatedAt) : updatedAt
				updatedAt = this.getUpdatedAtDateTime(updatedAt ? updatedAt.updated_at : null)

				el.disabled = true
				el.classList.add('running')

				try {
					await this.sync({
						model: 'employees',
						collection: 'Employee',
						deviceId: this.deviceId,
						locationId: this.locationId,
						params: { updated_at: updatedAt }
					})
					let employees = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
						merchant_id: this.merchant.id,
						type: ['admin', 'staff'],
						offset: -1
					}))

					employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
					employees = employees.data || employees
					this.$store.commit('setState', {
						key: 'employees',
						value: employees
					})
					this.$store.commit('setProgress', { value: 100 })
					setTimeout(() => {
						el.disabled = false
						el.classList.remove('running')
						this.$store.commit('resetProgress')
					}, 1000)
				} catch (e) {
					el.disabled = false
					el.classList.remove('running')
				}
			},
			async selectEmployee (employeeId) {
				let employee = await this.$bridge.getEmployee(employeeId)

				employee = typeof employee === 'string' ? JSON.parse(employee) : employee

				if (employee) {
					this.$bridge.setLocalStorage('employee', this.objToJson(employee))
					this.$store.commit('setPasscode', { show: true, type: 'clockin', employee })
				}
			},
			logout ($event) {
				const el = $event.currentTarget

				el.disabled = true
				el.classList.add('running')
				this.$store.dispatch('logout')
			}
		}
	}
