
	import DateRangePicker from 'vue2-daterange-picker'
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			DateRangePicker,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				priceCategories: {
					data: [],
					offset: 0,
					total: 0
				},
				selectedPriceCategory: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'price_type',
						label: 'price type',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'price_value',
						label: 'price value',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					}
				],
				selectedTaxes: [],
				priceCategoryTypes: [
					{
						text: 'base price',
						value: 'base'
					}
				],
				priceTypes: [
					{
						text: 'fixed',
						value: 'fixed'
					},
					{
						text: 'percentage',
						value: 'percentage'
					}
				],
				timePeriodTypes: [
					{
						text: 'date range',
						value: 'range'
					},
					{
						text: 'days in a week',
						value: 'days'
					}
				],
				daysInAWeek: [
					{
						text: 'sunday',
						value: 'sunday'
					},
					{
						text: 'monday',
						value: 'monday'
					},
					{
						text: 'tuesday',
						value: 'tuesday'
					},
					{
						text: 'wednesday',
						value: 'wednesday'
					},
					{
						text: 'thursday',
						value: 'thursday'
					},
					{
						text: 'friday',
						value: 'friday'
					},
					{
						text: 'saturday',
						value: 'saturday'
					}
				],
				taxes: [],
				dateRange: {
					startDate: this.$moment().startOf('day').toDate(),
					endDate: this.$moment().startOf('day').toDate()
				},
				priceCategory: {
					type: 'base',
					name: null,
					priceValue: null,
					priceType: 'fixed',
					taxes: [],
					is_default: false,
					timePeriodType: 'range',
					startTime: '01:00:00',
					endTime: '00:00:00'
				}
			}
		},
		computed: {
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			taxCreationModal: {
				get () {
					return this.$store.state.taxCreationModal
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'taxCreationModal',
						value
					})
				}
			}
		},
		watch: {
			selectedTaxes (tax, prevTax) {
				if (!tax[tax.length - 1]?.id && prevTax.length < tax.length) {
					window.taxCreationModalCallback = this.taxCreationModalCallback
					this.selectedTaxes.pop()
					this.taxCreationModal.show = true
				}
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getPriceCategories()
		},
		methods: {
			async getPriceCategories (isLoadMore) {
				let data = await this.$bridge.getPriceCategories(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id,
					type: 'base',
					offset: isLoadMore ? this.priceCategories.offset + 20 : 0
				}))

				data = (typeof priceCategories === 'string' ? JSON.parse(data) : data)
				this.priceCategories.data = data.offset ? this.priceCategories.data.concat(data.data) : data.data || data
				this.priceCategories.offset = data.offset || 0
				this.priceCategories.total = data.total || data.data.length
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getPriceCategories(true)
				}
			},
			createPriceCategory () {
				this.selectedPriceCategory = null
				window.requestAnimationFrame(() => this.$bvModal.show('price-category-modal'))
			},
			editPriceCategory (priceCategory) {
				this.selectedPriceCategory = priceCategory
				window.requestAnimationFrame(() => this.$bvModal.show('price-category-modal'))
			},
			deletePriceCategory (priceCategory) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						const date = new Date()
						let itemPriceCategories = await this.$bridge.getPriceCategories(this.deviceId, this.objToJson({
							category_id: priceCategory.id,
							is_active: false
						}))

						itemPriceCategories = (typeof itemPriceCategories === 'string' ? JSON.parse(itemPriceCategories) : itemPriceCategories)
						itemPriceCategories = itemPriceCategories.data || itemPriceCategories
						const updatedItemPriceCategories = itemPriceCategories.map(pc => ({
							id: pc.id,
							is_active: false,
							updated_at: date
						}))

						await this.$bridge.updateMany('PriceCategory', updatedItemPriceCategories)
						const itemPriceCategoryIds = itemPriceCategories.map(pc => pc.id)

						let variations = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({
							price_category_id: itemPriceCategoryIds
						}))

						variations = (typeof variations === 'string' ? JSON.parse(variations) : variations)
						variations = variations.data || variations
						const updatedVariation = variations.map(v => ({
							id: v.id,
							price_category: v.price_category.filter(pc => !itemPriceCategoryIds.includes(pc.id))
								.map(p => ({
									...p,
									custom_attributes: this.objToJson(p.custom_attributes)
								})),
							updated_at: date
						}))

						await this.$bridge.updateMany('ItemVariation', updatedVariation)

						priceCategory = {
							id: priceCategory.id,
							is_active: false,
							updated_at: new Date()
						}

						await this.$bridge.insert(
							'PriceCategory',
							this.$bridge.getName() === 'ANDROID' ? this.objToJson(priceCategory) : priceCategory,
							true
						)
						this.getPriceCategories()
					}
				})
			},
			initModal () {
				if (this.selectedPriceCategory) {
					this.priceCategory = {
						name: this.selectedPriceCategory.name,
						type: this.selectedPriceCategory.type,
						priceType: this.selectedPriceCategory.price_type,
						priceValue: this.selectedPriceCategory.price_value,
						is_default: this.selectedPriceCategory.is_default
					}
					this.selectedTaxes = this.selectedPriceCategory.tax
				}
			},
			async getTaxes () {
				const data = await this.$bridge.getTaxes(this.deviceId, this.objToJson({
					merchant_id: this.merchant.id
				}))

				this.taxes = (typeof data === 'string' ? JSON.parse(data) : data).data

				this.taxes.push({
					name: 'create new tax',
					id: 0,
					type: null
				})
			},
			taxCreationModalCallback () {
				this.selectedTaxes.push({
					...this.taxCreationModal.data
				})
				this.taxCreationModal = {
					show: false,
					data: {}
				}
			},
			async checkDefaultPriceCategory () {
				if (this.priceCategory.is_default && this.appVersionNumber >= 4110) {
					let data = await this.$bridge.getPriceCategories(this.deviceId, this.objToJson({
						merchant_id: this.merchant.id,
						type: 'base',
						is_default: true
					}))

					data = (typeof priceCategories === 'string' ? JSON.parse(data) : data)
					data = data.data || data

					if (data.length && (!this.selectedPriceCategory || (this.selectedPriceCategory.id !== data[0].id))) {
						this.$swal({
							title: this.$tc('price category', 1),
							text: this.$t('defaultPriceCategoryAlert', [data[0].name]),
							icon: 'warning',
							buttons: [this.$tc('cancel', 1), this.$t('proceed')]
						}).then((res) => {
							if (res) {
								const pc = {
									...data[0],
									is_default: false,
									custom_attributes: this.objToJson(data[0].customAttributes),
									updated_at: new Date()
								}

								this.$bridge.insert(
									'PriceCategory',
									this.bridgeName === 'ANDROID' ? this.objToJson(pc) : pc,
									true
								)
							} else {
								this.priceCategory.is_default = false
							}
						})
					}
				}
			},
			async processPriceCategoryCreation () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()
					const customAttributes = {}

					if (this.priceCategory.type === 'item') {
						customAttributes.applicable_time_period = this.priceCategory.timePeriodType

						if (this.priceCategory.timePeriodType === 'days') {
							customAttributes.days_of_week = this.priceCategory.daysInAWeek
						}
					}

					const newPriceCategory = {
						id: this.selectedPriceCategory?.id || date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						type: this.priceCategory.type,
						name: this.priceCategory.name,
						slug: this.priceCategory.name.split(' ').join('_'),
						price_type: this.priceCategory.priceType,
						price_value: parseFloat(this.priceCategory.priceValue),
						tax: this.selectedTaxes,
						start_date: customAttributes.applicable_time_period === 'range' ? this.$moment(this.dateRange.startTime).format('YYYY-MM-DD') : null,
						start_time: customAttributes.applicable_time_period === 'range' ? this.$moment(this.dateRange.startTime).format('hh:mm:ss') : this.priceCategory.startTime,
						end_date: customAttributes.applicable_time_period === 'range' ? this.$moment(this.dateRange.endTime).format('YYYY-MM-DD') : null,
						end_time: customAttributes.applicable_time_period === 'range' ? this.$moment(this.dateRange.endTime).format('hh:mm:ss') : this.priceCategory.endTime,
						custom_attributes: this.objToJson(customAttributes),
						is_default: this.priceCategory.is_default,
						is_active: true,
						updated_at: date
					}

					await this.$bridge.insert(
						'PriceCategory',
						this.bridgeName === 'ANDROID' ? this.objToJson(newPriceCategory) : newPriceCategory,
						true
					)
					this.$refs.priceCategoryModal.hide()
					this.getPriceCategories()
				}
			},
			resetModel () {
				this.priceCategory = {
					name: null,
					type: 'base',
					priceType: 'fixed',
					priceValue: null,
					is_default: false
				}
				this.selectedTaxes = []
			}
		}
	}
