import { render, staticRenderFns } from "./app.vue?vue&type=template&id=6821f813&scoped=true"
import script from "./app.vue?vue&type=script&lang=js"
export * from "./app.vue?vue&type=script&lang=js"
import style0 from "./app.vue?vue&type=style&index=0&id=6821f813&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6821f813",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Locale: require('/home/ubuntu/complete-nuxt-js/components/locale.vue').default,Sell: require('/home/ubuntu/complete-nuxt-js/components/sell.vue').default,Tables: require('/home/ubuntu/complete-nuxt-js/components/tables.vue').default,OnlineOrders: require('/home/ubuntu/complete-nuxt-js/components/online-orders.vue').default,Sales: require('/home/ubuntu/complete-nuxt-js/components/sales.vue').default,Reports: require('/home/ubuntu/complete-nuxt-js/components/reports.vue').default,Cash: require('/home/ubuntu/complete-nuxt-js/components/cash.vue').default,Customers: require('/home/ubuntu/complete-nuxt-js/components/customers.vue').default,Settings: require('/home/ubuntu/complete-nuxt-js/components/settings.vue').default,CashDrawerModal: require('/home/ubuntu/complete-nuxt-js/components/cash-drawer-modal.vue').default,ReceiptPreview: require('/home/ubuntu/complete-nuxt-js/components/receipt-preview.vue').default,CallerIdModal: require('/home/ubuntu/complete-nuxt-js/components/caller-id-modal.vue').default,ShareReceiptModal: require('/home/ubuntu/complete-nuxt-js/components/share-receipt-modal.vue').default})
