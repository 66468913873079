
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				brands: {
					data: [],
					offset: 0,
					total: 0
				},
				brandName: null,
				selectedBrand: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				]
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			settings () {
				return this.$store.state.settings
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isOnline () {
				return this.$store.state.isOnline
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
			}

			await this.getBrands()
		},
		mounted () {
			this.$root.$on('bv::modal::hidden', this.hideSheetImportModal)
		},
		destroyed () {
			this.$root.$off('bv::modal::hidden', this.hideSheetImportModal)
		},
		methods: {
			async getBrands (isLoadMore = false) {
				let data = await this.$bridge.getBrands(this.deviceId, this.locationId, this.objToJson({
					offset: isLoadMore ? this.brands.offset + 20 : 0
				}))

				data = (typeof data === 'string' ? JSON.parse(data) : data)

				this.brands.data = data.offset ? this.brands.data.concat(data.data) : data.data
				this.brands.offset = data.offset
				this.brands.total = data.total
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getBrands(true)
				}
			},
			importBrands () {
				this.$root.$emit('show-sheet-import-modal', 'Brand')
			},
			createBrand () {
				this.selectedBrand = null
				this.$refs.brandModal.show()
			},
			editBrand (brand) {
				this.selectedBrand = brand
				this.initModal()
				this.$refs.brandModal.show()
			},
			deleteBrand (brand) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						const date = new Date()
						let items = await this.$bridge.getItems(
							this.deviceId, this.locationId, this.objToJson({
								brand_id: brand.id
							})
						)

						items = (typeof items === 'string' ? JSON.parse(items) : items).data
						const updateItems = items.map(item => ({
							id: item.id,
							brand_id: null,
							updated_at: date
						}))

						await this.$bridge.updateMany('Item', updateItems)
						brand = {
							id: brand.id,
							is_active: false,
							updated_at: date
						}

						await this.$bridge.insert(
							'Brand',
							this.$bridge.getName() === 'ANDROID' ? this.objToJson(brand) : brand,
							true
						)
						this.getBrands()
					}
				})
			},
			initModal () {
				if (this.selectedBrand) {
					this.brandName = this.selectedBrand.name
				}
			},
			async createOrUpdateBrand () {
				if (await this.$refs.validator.validate()) {
					const date = new Date()
					const brand = {
						id: this.selectedBrand?.id || +date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						name: this.brandName,
						custom_attributes: this.objToJson(this.selectedbrand?.custom_attributes || {}),
						is_active: true,
						created_at: this.selectedBrand?.created_at || date,
						updated_at: date
					}

					await this.$bridge.insert(
						'Brand',
						this.$bridge.getName() === 'ANDROID' ? this.objToJson(brand) : brand,
						true
					)

					this.$refs.brandModal.hide()
					this.getBrands()
				}
			},
			resetModal () {
				this.brandName = null
			},
			async hideSheetImportModal (bvEvent, modalId) {
				if (modalId === 'sheet-import-modal') {
					await this.getBrands()
				}
			}
		}
	}
