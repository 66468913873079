
	import DateRangePicker from 'vue2-daterange-picker'
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	let printFlag = true
	let receiptCode = null
	let generatedRefCode = null

	export default {
		components: {
			DateRangePicker,
			ValidationObserver,
			ValidationProvider
		},
		props: {
			source: {
				type: String,
				required: true
			},
			orderProp: {
				type: Object,
				default () {
					return {}
				}
			}
		},
		data () {
			return {
				order: Object.assign({}, this.orderProp),
				totalPrice: 0,
				totalAmount: null,
				isPermitNumberApplied: false,
				loyalty: null,
				selectedLoyaltyDiscount: null,
				selectedSku: null,
				paymentMethods: [],
				selectedPaymentMethod: null,
				orderPayments: [],
				otherPaymentType: null,
				paymentNote: '',
				processingOrder: false,
				customAttributes: {
					payment_methods: []
				},
				splitPayment: {
					showModal: false,
					paid: false,
					paymentMethod: null,
					options: [
						{
							value: 'equally',
							text: 'equally'
						},
						{
							value: 'unequally',
							text: 'unequally'
						},
						{
							value: 'percent',
							text: 'percent'
						}
					],
					splitType: 'equally',
					splitValue: null
				},
				splitCardPayment: null,
				passportConnect: {
					showModal: false,
					status: 'INITIALIZING',
					code: null,
					transactionId: null,
					cancel: false
				},
				tidyPayState: {
					showModal: false,
					status: 'INITIALIZING',
					code: null
				},
				skybandState: {
					showModal: false,
					status: 'INITIALIZING'
				},
				pinelabsConnect: {
					showModal: false,
					status: 'INITIALIZING',
					cancel: false
				},
				cloverPay: {
					showModal: false,
					status: 'INITIALIZING',
					cancel: false
				},
				cancelTidyPayRequest: null,
				cardTips: [
					...(this.$store.state.settings.general.tip_percentages || []).reduce((tips, tip) => {
						if (Number.isInteger(parseInt(tip))) {
							tips.push({
								value: +parseFloat(tip).toFixed(2),
								text: parseFloat(tip).toFixed(2) + '%'
							})
						}

						return tips
					}, []),
					{
						value: 0,
						text: this.$t('no tip')
					}
				],
				selectedCardTip: 0,
				cardTip: 0,
				selectedForeignCurrency: null,
				keystrokes: '',
				isCustomRefund: false,
				restock: 1,
				restockOptions: [
					{
						text: 'void',
						value: 1
					},
					{
						text: 'waste',
						value: 0
					}
				]
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			device () {
				return this.$store.state.device
			},
			primaryDevice () {
				return this.$store.state.primaryDevice
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			isGiftCard () {
				return this.$store.getters.isGiftCard
			},
			localSettings () {
				return this.$store.state.localSettings
			},
			isOnline () {
				return this.$store.state.isOnline
			},
			checkout: {
				get () {
					return this.$store.state.checkout
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'checkout',
						value
					})
				}
			},
			charges () {
				return this.$store.state.charges
			},
			cart () {
				return this.$store.state.cart
			},
			customer: {
				get () {
					return this.$store.state.cart.customer
				},
				set (value) {
					this.$store.commit('setCart', { customer: value })
				}
			},
			customers () {
				return this.$store.state.customers
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			kots () {
				return this.$store.state.kots
			},
			selectedTables: {
				get () {
					return this.$store.state.selectedTables
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedTables', value })
				}
			},
			selectedOrder: {
				get () {
					return this.$store.state.selectedOrder
				},
				set (order) {
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: order
					})
				}
			},
			selectedCustomerAddress () {
				let address = ''

				if (this.customer) {
					if (this.customer.address.line) {
						address += `${this.customer.address.line}, `
					}

					if (this.customer.address.area) {
						address += `${this.customer.address.area}, `
					}

					if (this.customer.address.city) {
						address += `${this.customer.address.city}, `
					}

					if (this.customer.address.state) {
						address += `${this.customer.address.state}, `
					}

					if (this.customer.address.zip) {
						address += `${this.customer.address.zip}, `
					}

					if (this.customer.address.landmark) {
						address += `${this.customer.address.landmark}`
					}

					if (address.slice(-2) === ', ') {
						address = address.slice(0, -2)
					}
				}

				return address
			},
			selectedDeliveryAddress () {
				let address = ''

				if (this.cart.advanceOrder.enabled) {
					if (this.cart.advanceOrder.deliveryAddress.line) {
						address += `${this.cart.advanceOrder.deliveryAddress.line}, `
					}

					if (this.cart.advanceOrder.deliveryAddress.area) {
						address += `${this.cart.advanceOrder.deliveryAddress.area}, `
					}

					if (this.cart.advanceOrder.deliveryAddress.city) {
						address += `${this.cart.advanceOrder.deliveryAddress.city}, `
					}

					if (this.cart.advanceOrder.deliveryAddress.state) {
						address += `${this.cart.advanceOrder.deliveryAddress.state}, `
					}

					if (this.cart.advanceOrder.deliveryAddress.zip) {
						address += `${this.cart.advanceOrder.deliveryAddress.zip}, `
					}

					if (this.cart.advanceOrder.deliveryAddress.landmark) {
						address += `${this.cart.advanceOrder.deliveryAddress.landmark}`
					}

					if (address.slice(-2) === ', ') {
						address = address.slice(0, -2)
					}
				}

				return address
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			settings () {
				return this.$store.state.settings
			},
			selectedDiscount: {
				get () {
					return this.$store.state.selectedDiscount
				},
				set (discount) {
					this.$store.commit('setState', {
						key: 'selectedDiscount',
						value: discount
					})
				}
			},
			showOrderConfirmation () {
				return this.$store.state.showOrderConfirmation
			},
			showConfirmWOPrint () {
				return this.$store.state.showConfirmWOPrint
			},
			splitPayments () {
				return this.paymentMethods.reduce((sp, pm) => {
					if (pm.slug !== 'split') {
						sp.push({
							...pm,
							amount: 0
						})
					}

					return sp
				}, [])
			},
			splitTotal () {
				return this.$currency.transformNumber(this.orderPayments.reduce((sum, op) => {
					sum += +op.amount

					return sum
				}, 0))
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			tip: {
				get () {
					return this.$store.state.tip
				},
				set (value) {
					this.$store.commit('setState', { key: 'tip', value })
				}
			},
			internationalBancard () {
				return this.$store.state.internationalBancard
			},
			tidyPay () {
				return this.$store.state.tidyPay
			},
			skyband () {
				return this.$store.state.skyband
			},
			pineLabs () {
				return this.$store.state.pineLabs
			},
			clover () {
				return this.$store.state.clover
			},
			isPermitNumberRequired () {
				return [...new Set(this.cart.items.map(i => i.custom_attributes.alcohol_strength))].some(as => as > 5)
			},
			currencySymbol () {
				return this.merchant.customAttributes?.currency_symbol || this.$currency.getCurrencySymbol()
			},
			refCode () {
				return this.order.ref_code || generatedRefCode?.code
			},
			kotCancellationReason () {
				return this.$store.state.kotCancellationReason
			},
			advanceOrdersCount: {
				get () {
					return this.$store.state.advanceOrdersCount
				},
				set (value) {
					this.$store.commit('setState', { key: 'advanceOrdersCount', value })
				}
			},
			balanceAmount () {
				const foreignCurrencyBalanceReturned = this.selectedForeignCurrency
					? this.selectedForeignCurrency.totalAmount - this.$currency.transformNumber(
						this.cart.price.total / this.selectedForeignCurrency.exchangeRate,
						this.selectedForeignCurrency.code
					)
					: 0

				const totalAmount = foreignCurrencyBalanceReturned > 0
					? this.$currency.transformNumber(this.selectedForeignCurrency.totalAmount * this.selectedForeignCurrency.exchangeRate, this.selectedForeignCurrency.code)
					: +this.totalAmount

				const balanceReturned = this.selectedPaymentMethod?.slug === 'cash' && (
					!this.selectedForeignCurrency || foreignCurrencyBalanceReturned > 0
				)
					? this.$currency.transformNumber(totalAmount - this.totalPrice, this.selectedForeignCurrency?.code)
					: 0.00

				return {
					nativeCurrency: balanceReturned < 0 ? 0 : balanceReturned,
					foreignCurrency: foreignCurrencyBalanceReturned < 0 ? 0 : foreignCurrencyBalanceReturned,
					totalAmount
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			},
			customerOtpModal () {
				return this.$store.state.customerOtpModal
			}
		},
		watch: {
			cart: {
				handler (cart, prevCart) {
					if (cart.items.length <= 0) {
						return this.$store.commit('resetCheckout')
					}

					const cartPriceTotal = this.$currency.transformNumber(cart.price.total)

					if (this.totalPrice !== cartPriceTotal) {
						this.totalAmount = cartPriceTotal
					}

					this.totalPrice = cartPriceTotal

					if (cart.items.length !== prevCart.items.length) {
						this.checkPermitCharge()
					}

					if (this.loyalty?.applied && !this.isMiniPlan && cart.items.reduce((sum, item) => sum + item.quantity, 0).toFixed(3) !== prevCart.items.reduce((sum, item) => sum + item.quantity, 0).toFixed(3)) {
						this.getLoyaltyPoints()
					}
				},
				deep: true
			},
			selectedCardTip () {
				if (this.selectedCardTip != null) {
					this.cardTip = this.$currency.transformNumber((this.totalPrice - this.cart.price.tip) * (this.selectedCardTip / 100))
				}
			},
			orderPayments (orderPayments) {
				if (this.splitPayment.splitType === 'equally') {
					const amounts = this.evenly(this.totalPrice, orderPayments.length)

					orderPayments = orderPayments.map((op, i) => {
						op.amount = amounts[i]

						return op
					})
				}
			},
			selectedDiscount (selectedDiscount) {
				if (!selectedDiscount) {
					this.removeLoyaltyDiscount()
				}
			},
			advanceOrder: {
				handler (advanceOrder) {
					if (advanceOrder.enabled && ['credit', 'split'].includes(this.selectedPaymentMethod.slug)) {
						this.selectedPaymentMethod = this.paymentMethods.find(pm => +pm.custom_attributes?.is_default_payment === 1)
					}
				},
				deep: true
			}
		},
		async mounted () {
			const paymentMethods = await this.$bridge.getPaymentMethods(this.deviceId, '')

			this.paymentMethods = typeof paymentMethods === 'string' ? JSON.parse(paymentMethods) : paymentMethods

			const defaultPaymentMethodIndex = this.paymentMethods.findIndex(pm => (pm.id === this.cart.priceCategory?.payment_method?.id) || +pm.custom_attributes?.is_default_payment === 1)

			this.selectedPaymentMethod = this.cart.storeOrder
				? this.cart.storeOrder.orderType === 'delivery'
					? this.paymentMethods.find(p => p.slug === 'cod')
					: this.paymentMethods.find(p => p.slug === 'credit')
				: this.paymentMethods[defaultPaymentMethodIndex !== -1 ? defaultPaymentMethodIndex : 0] || null
			this.totalPrice = this.$currency.transformNumber(this.cart.price.total)
			this.totalAmount = this.totalPrice
			this.customAttributes.tax_calculation_phase = this.settings.general.tax_calculation_phase

			if (document.querySelector('ul.sell-menu a.active')?.getAttribute('data-content') === 'custom-refund') {
				this.isCustomRefund = true
				this.customAttributes.is_custom_refund = true
			}

			if (this.cart.table) {
				this.selectedTables.push(this.cart.table)
			}

			if (this.selectedOrder) {
				this.order = { ...this.selectedOrder }
				Object.assign(this.customAttributes, this.order.custom_attributes)

				if (['open', 'closed', 'completed'].includes(this.order.status)) {
					this.selectedPaymentMethod = this.order.payment_method

					if (this.order.payment_method.slug === 'split') {
						this.orderPayments = this.order.payment_methods

						if (this.order.custom_attributes.split_payment_type) {
							this.splitPayment.splitType = this.order.custom_attributes.split_payment_type
						}

						if (this.order.custom_attributes.split_payment_type === 'percent') {
							this.orderPayments = this.orderPayments.map(pm => ({
								...pm,
								value: (pm.amount / this.order.total_amount) * 100
							}))
						}
					} else if (this.order.payment_method.slug === 'other') {
						this.otherPaymentType = this.order.custom_attributes.other_payment_method
					}
				}
			}

			if (this.order.customer && this.order.customer.id) {
				this.customer = this.order.customer

				if (this.order.discounts.length && this.order.custom_attributes.loyalty_points) {
					this.selectedLoyaltyDiscount = {
						id: this.order.discounts[0].id,
						name: this.order.discounts[0].name,
						description: this.order.discounts[0].description,
						getDiscountType: this.order.discounts[0].get_discount_type,
						getDiscountValue: this.order.discounts[0].get_discount_value,
						maxDiscountValue: this.order.discounts[0].max_discount_value
					}
					this.loyalty = {
						active: true,
						amountPerPoint: this.order.custom_attributes.loyalty_points.discountedAmountPerPoint,
						applied: this.selectedDiscount && this.selectedDiscount.description === 'Loyalty',
						discounts: this.selectedDiscount ? [this.selectedDiscount] : [],
						earnedPointsWithDiscount: Math.floor(this.cart.price.total / this.order.custom_attributes.loyalty_points.spendingPerPoint),
						earnedPointsWithoutDiscount: Math.floor((this.cart.price.subtotal + this.cart.price.tax) / this.order.custom_attributes.loyalty_points.spendingPerPoint),
						redeemablePoints: this.order.custom_attributes.loyalty_points.redeemable_points,
						spendingPerPoint: this.order.custom_attributes.loyalty_points.spendingPerPoint,
						totalPoints: this.order.custom_attributes.loyalty_points.total_points
					}

					if (this.order.custom_attributes?.loyalty_points?.product_type === 'advanced_loyalty') {
						this.loyalty.applied = this.selectedDiscount && this.selectedDiscount.description === 'advanced_loyalty'
						this.loyalty.rewardId = this.customAttributes.loyalty_points.rewardId
						this.loyalty.earnedPoints = Math.floor(this.cart.price.total / this.order.custom_attributes.loyalty_points.discountedAmountPerPoint) * this.order.custom_attributes.loyalty_points.spendingPerPoint
						this.loyalty.product_type = 'advanced_loyalty'
					}

					if (!this.selectedDiscount || !['Loyalty', 'advanced_loyalty'].includes(this.selectedDiscount.description)) {
						this.selectedLoyaltyDiscount = null
					}
				}
			}

			if (this.customer) {
				await this.updateCustomer(this.customer)
			}

			if (this.checkout.isTrusted && !this.order.customer && this.localSettings.defaultCustomerId) {
				let customer = await this.$bridge.getCustomers(this.deviceId, this.objToJson({
					id: this.localSettings.defaultCustomerId
				}))

				customer = (typeof customer === 'string' ? JSON.parse(customer) : customer).data[0]

				if (customer) {
					this.customer = customer
				}
			}

			this.paymentNote = this.order?.payment_note || ''
			document.addEventListener('keydown', this.keyboardEvents)
			this.$root.$on('cancel-item-verified', this.processOrder)
			window.cardPaymentSuccess = this.handleExternalPaymentSuccess
			window.cardPaymentFailure = this.handleExternalPaymentMessage

			if (this.checkout.isTrusted) {
				setTimeout(() => {
					if (this.checkout.action === 'transferTable') {
						return this.$bvModal.show('tables-modal')
					}

					if (+this.settings.general.is_customer_mandatory && this.checkout.action && this.checkout.action.isTrusted) {
						this.$bvModal.show('add-customer')
					} else if (+this.settings.general.is_table_mandatory && !this.selectedTables.length && !(this.order.tables && this.order.tables.length)) {
						this.$bvModal.show('tables-modal')
					}
				}, 100)
			}

			if (this.source === 'table') {
				this.$root.$emit('update-table-order')
			}
		},
		destroyed () {
			if (this.source === 'table') {
				this.tip = null
				this.selectedOrder = null
			}

			this.selectedTables = []
			this.removePermitCharge()
			document.removeEventListener('keydown', this.keyboardEvents)
			this.$root.$off('cancel-item-verified', this.processOrder)
		},
		methods: {
			async checkOrderLimitationReached () {
				if (this.merchant.subscription.limitations?.orders?.limit) {
					const filters = { offset: -1 }
					let period = null

					switch (this.merchant.subscription.limitations.orders.period) {
						case 'daily':
							period = 'day'
							break
						case 'monthly':
							period = 'month'
							break
						case 'yearly':
							period = 'year'
							break
						default:
							return
					}

					filters.from_date = this.$moment().startOf(period).utc()
					filters.to_date = this.$moment().endOf(period).utc()

					const orders = await this.$bridge.getOrders(
						this.deviceId,
						this.objToJson(filters)
					)
					const orderCount = (typeof orders === 'string' ? JSON.parse(orders) : orders).data.length

					return orderCount >= this.merchant.subscription.limitations.orders.limit
				}
			},
			async updateCustomer (customer) {
				let customerChanged = false

				if (!this.customer || !customer || (this.customer.id !== customer.id)) {
					this.removeLoyaltyDiscount()
					this.loyalty = null
					customerChanged = true
				}

				this.customer = customer
				this.isPermitNumberApplied = false

				if (
					customer &&
					this.isPermitNumberRequired &&
					Array.isArray(this.location.customAttributes.permit_numbers) &&
					this.location.customAttributes.permit_numbers.length
				) {
					const generatePermitNumber = async () => {
						let permitNumber = +(await this.$bridge.getLocalStorage('permitNumber'))

						if (permitNumber === 0) {
							permitNumber = this.location.customAttributes.permit_numbers[0][0]
						} else {
							permitNumber++

							if (!this.isPermitNumberInRange(permitNumber)) {
								const permitNumbers = this.location.customAttributes.permit_numbers.sort((a, b) => a[0] - b[0])
								const rangeIndex = permitNumbers.map(range => range[1])
									.findIndex(range => permitNumber < range)

								if (rangeIndex !== -1) {
									permitNumber = permitNumbers[rangeIndex][0]
								}
							}
						}

						return permitNumber
					}

					const permitNumber = await generatePermitNumber()

					if (customer.custom_attributes.permit_type === 'daily' && (
						!customer.custom_attributes.permit_validity || customer.custom_attributes.permit_validity < this.$moment().format('YYYY-MM-DD')
					)) {
						this.customer.custom_attributes.permit_number = permitNumber
						this.isPermitNumberApplied = true
					}
				}

				this.checkPermitCharge()

				if (customer) {
					if (customer.custom_attributes.delivery_address || this.selectedCustomerAddress) {
						let customerDeliveryAddress

						if (Array.isArray(customer.custom_attributes.delivery_address)) {
							customerDeliveryAddress = customer.custom_attributes.delivery_address[0]
						} else {
							customerDeliveryAddress = customer.custom_attributes.delivery_address
						}

						this.$set(
							this.cart.advanceOrder,
							'deliveryAddress',
							customerDeliveryAddress || { ...customer.address }
						)

						if (!this.cart.advanceOrder.deliveryAddress.name) {
							this.cart.advanceOrder.deliveryAddress.name = customer.first_name
						}

						this.cart.advanceOrder.isDeliveryAddressValid = true
					} else {
						this.cart.advanceOrder.deliveryAddress = {}
						this.cart.advanceOrder.isDeliveryAddressValid = false
					}

					this.customAttributes.new_order = customer.custom_attributes?.new_customer
					delete customer.custom_attributes?.new_customer

					if (
						this.$nuxt.isOnline &&
						this.checkout.isTrusted &&
						!this.loyalty &&
						!this.selectedLoyaltyDiscount &&
						this.selectedDiscount?.entity !== 'gift_card'
					) {
						this.$bvModal.show('loyalty')
					}
				}

				await this.$store.dispatch('orderAutoDiscount')

				if (customerChanged) {
					await this.$store.dispatch('cartCalculation', true)
				}

				if (this.source === 'table') {
					this.$root.$emit('update-table-order')
				}
			},
			async validateDeliveryAddress ($event) {
				if ($event.trigger === 'ok') {
					$event.preventDefault()
				}

				this.cart.advanceOrder.isDeliveryAddressValid = await this.$refs.deliveryAddressValidator.validate()

				if ($event.trigger === 'ok' && this.cart.advanceOrder.isDeliveryAddressValid) {
					this.cart.advanceOrder.deliveryAddress.coordinates = [this.location.longitude, this.location.latitude]
					$event.vueTarget.hide()
				} else if ($event.trigger !== 'ok') {
					this.$refs.deliveryAddressValidator.reset()
				}
			},
			getLoyaltyPoints () {
				let total = this.cart.price.total
				const chargeAmount = this.cart.price.charges.reduce((acc, c) => (acc + c.amount + c.tax), 0)

				if (this.selectedDiscount?.description === 'Loyalty') {
					total += this.cart.items.reduce((discountSum, i) => {
						const index = i.discounts.findIndex(d => d.id === this.selectedDiscount.id)

						if (index !== -1) {
							discountSum += i.discounts[index].discounted_amount + i.discounts[index].discounted_tax
						}

						return discountSum
					}, 0)
				}

				return this.$store.dispatch('getLoyaltyPoints', {
					merchant_customer_id: this.customer.customer_id ? this.customer.customer_id : -1,
					location_id: this.locationId,
					total_price: total,
					total_discount: this.cart.price.discount,
					non_discount_price: chargeAmount + this.cart.price.tip,
					loyalty_type: this.merchant.subscription.products.advanced_loyalty
						? 'advanced_loyalty'
						: '',
					response_type: 'camelcase'
				}).then((response) => {
					this.loyalty = response.data

					if (this.order?.custom_attributes?.new_order) {
						this.loyalty.totalPoints = 0
						this.loyalty.discounts = []
						this.loyalty.earnedPointsWithoutDiscount += (this.order.custom_attributes.loyalty_points.earned_points - Math.floor(this.order.total_price / this.order.custom_attributes.loyalty_points.spendingPerPoint))
					}

					if (this.loyalty.discounts?.length === 0) {
						setTimeout(this.$refs.loyaltyModal.hide, 2000)
					}

					if (this.selectedLoyaltyDiscount) {
						this.loyalty.applied = true

						if (this.loyalty.product_type === 'advanced_loyalty') {
							this.selectedLoyaltyDiscount = this.loyalty.discounts.find(discount => discount.rewardId === this.selectedLoyaltyDiscount.rewardId)
						}
					}
				}).catch(console.error)
			},
			async applyLoyaltyDiscount () {
				this.selectedDiscount = {
					id: this.selectedLoyaltyDiscount.id,
					name: this.selectedLoyaltyDiscount.name,
					description: this.selectedLoyaltyDiscount.description,
					get_discount_type: this.selectedLoyaltyDiscount.getDiscountType,
					get_discount_value: this.selectedLoyaltyDiscount.getDiscountValue,
					apply_discount_sub_total: this.selectedLoyaltyDiscount.applyDiscountSubTotal,
					is_automatic: this.selectedLoyaltyDiscount.isAutomatic,
					max_discount_value: this.selectedLoyaltyDiscount.getDiscountValue
				}

				if (this.loyalty.product_type === 'advanced_loyalty') {
					this.selectedDiscount.description = 'advanced_loyalty'
					this.selectedDiscount.max_discount_value = this.selectedLoyaltyDiscount.getDiscountType === 'percentage' ? this.selectedLoyaltyDiscount.maxDiscountValue : this.selectedLoyaltyDiscount.getDiscountValue
					this.selectedDiscount.buy_condition_type = this.selectedLoyaltyDiscount.buyConditionType
					this.selectedDiscount.buy_condition_value = this.selectedLoyaltyDiscount.type === 'item' ? 1 : this.selectedLoyaltyDiscount.buyConditionValue
				}

				this.loyalty.applied = true

				if (this.loyalty.product_type === 'advanced_loyalty' && this.loyalty.isOtpEnabled && !this.customer.otp_verified) {
					this.processingOrder = false

					return this.$store.commit('setState', {
						key: 'customerOtpModal',
						value: 'advancedLoyalty'
					})
				} else {
					if (this.selectedLoyaltyDiscount.type === 'item' && this.selectedSku) {
						let dbItemVariation = await this.$bridge.getItemVariations(this.deviceId, this.objToJson({ sku: this.selectedSku }))

						dbItemVariation = (typeof dbItemVariation === 'string' ? JSON.parse(dbItemVariation) : dbItemVariation)
						dbItemVariation = (dbItemVariation.data || dbItemVariation)[0]

						Object.assign(this.selectedDiscount, {
							type: this.selectedLoyaltyDiscount.type,
							sku: this.selectedLoyaltyDiscount.value[0].sku,
							variation_id: dbItemVariation?.id
						})

						if (dbItemVariation) {
							dbItemVariation = {
								...dbItemVariation,
								quantity: 1,
								item_discount: [{
									...this.selectedDiscount,
									buy_batch_id: null,
									get_batch_id: null,
									buy_condition_type: 'quantity',
									buy_condition_value: 1,
									get_discount_type: 'percentage',
									get_discount_value: 100,
									get_discount_quantity: 1,
									get_discount_max_quantity: 1,
									entity: 'item',
									type: 'ITEM'
								}]
							}

							const existingItem = this.cart.items.find(item => item.id === dbItemVariation.id)

							if (existingItem) {
								existingItem.quantity += 1
								existingItem.item_discount = dbItemVariation.item_discount
							} else {
								this.cart.items.push(dbItemVariation)
							}

							this.$store.commit('setCart', { items: this.cart.items })
						}
					}

					this.$store.dispatch('cartCalculation')
				}

				this.$refs.loyaltyModal.hide()
				this.$root.$emit('update-table-order')

				if (this.settings.general.tax_calculation_phase === 'after_discount' &&
					(this.selectedLoyaltyDiscount.maxDiscountValue === this.loyalty.redeemablePoints * this.loyalty.amountPerPoint ||
						(this.loyalty.redeemablePoints + 1) * this.loyalty.amountPerPoint > this.selectedLoyaltyDiscount.maxDiscountValue)) {
					this.loyalty.earnedPointsWithDiscount = Math.floor(this.cart.price.total / this.loyalty.spendingPerPoint)
				}

				if (!this.settings.general.calculate_loyalty_for_discounted_sale) {
					this.loyalty.earnedPointsWithDiscount = 0
				}
			},
			removeLoyaltyDiscount () {
				if (this.selectedLoyaltyDiscount) {
					this.selectedLoyaltyDiscount = null

					if (this.loyalty) {
						this.loyalty.applied = false
					}

					this.selectedDiscount = null
					this.$store.dispatch('cartCalculation')
				}

				this.$root.$emit('update-table-order')
			},
			selectedDiscountLoyalty (discount) {
				this.selectedLoyaltyDiscount = discount
				this.selectedSku = discount.value.length === 1 ? discount.value[0].sku : null
			},
			selectSku (sku) {
				this.selectedSku = sku
				this.selectedLoyaltyDiscount.sku = sku
			},
			checkPermitCharge () {
				this.removePermitCharge()

				if (+this.settings.sale.daily_permit_charge_id && this.isPermitNumberRequired && this.isPermitNumberApplied) {
					const charge = this.charges.find(c => c.id === +this.settings.sale.daily_permit_charge_id)

					if (charge) {
						this.cart.charges.push(charge)
						this.$store.dispatch('cartCalculation')
					}
				}
			},
			isPermitNumberInRange (permitNumber) {
				return this.location.customAttributes.permit_numbers.some((range) => {
					return permitNumber >= range[0] && permitNumber <= range[1]
				})
			},
			showPermitNumberError (errorKey) {
				this.$swal({
					title: this.$t(`${errorKey}.title`),
					text: this.$t(`${errorKey}.text`),
					icon: 'error',
					button: this.$t('ok')
				}).then((response) => {
					if (response) {
						this.$bvModal.show('add-customer')
					}
				})
			},
			removePermitCharge () {
				if (+this.settings.sale.daily_permit_charge_id) {
					const chargeIndex = this.cart.charges.findIndex(c => c.id === +this.settings.sale.daily_permit_charge_id)

					if (chargeIndex !== -1) {
						this.cart.charges.splice(chargeIndex, 1)
						this.$store.dispatch('cartCalculation')
					}
				}
			},
			validateOrder () {
				this.$store.dispatch('validateOrder', this.order).then(async () => {
					if (this.selectedTables.length || this.cart.storeOrder) {
						return this.processOrder()
					}

					if ((+this.settings.general.is_customer_mandatory || this.cart.advanceOrder.enabled) && !this.customer) {
						this.processingOrder = false

						return this.$bvModal.show('add-customer')
					} else if (this.cart.advanceOrder.enabled && this.cart.advanceOrder.orderType === 'delivery' && !this.cart.advanceOrder.isDeliveryAddressValid) {
						this.processingOrder = false

						return this.$bvModal.show('delivery-address-modal')
					} else if (+this.settings.general.is_table_mandatory && !this.selectedTables.length && !(this.order.tables && this.order.tables.length)) {
						this.processingOrder = false

						return this.$bvModal.show('tables-modal')
					} else if (this.customer && this.isPermitNumberRequired && Array.isArray(this.location.customAttributes.permit_numbers) && this.location.customAttributes.permit_numbers.length) {
						if (!this.customer.gender || !this.customer.dob) {
							this.processingOrder = false
							this.$bvModal.show('add-customer')

							return setTimeout(() => {
								const btn = document.querySelector('#add-customer .btn-success')

								if (btn) {
									btn.click()
								}
							}, 250)
						} else if (!this.customer.custom_attributes.permit_type || !this.customer.custom_attributes.permit_number) {
							this.processingOrder = false

							return this.showPermitNumberError('permitMissingError')
						} else if (this.customer.custom_attributes.permit_type === 'daily' && !this.isPermitNumberInRange(this.customer.custom_attributes.permit_number)) {
							this.processingOrder = false
							const div = document.createElement('div')

							div.innerHTML = `<div class='text-left'>
								<p class='mb-0'>${this.$t('permitRangeError.text1', [this.customer.custom_attributes.permit_number])}</p>
								<p class='mb-0'>
									${this.$t('permitRangeError.text2', [this.location.customAttributes.permit_numbers.map(range => `${range[0]} - ${range[1]}`).join(', ')])}
								</p>
								<p class='mb-0'>${this.$t('permitRangeError.text3')}</p>
							</div>`

							return this.$swal({
								title: this.$t('permitRangeError.title'),
								icon: 'error',
								button: this.$t('ok'),
								content: div.firstChild
							})
						} else if (this.customer.custom_attributes.permit_type === 'yearly' && this.customer.custom_attributes.permit_validity < this.$moment().format('YYYY-MM-DD')) {
							this.processingOrder = false

							return this.showPermitNumberError('permitExpiredError')
						}
					}

					if (await this.$refs.validator.validate()) {
						switch (this.selectedPaymentMethod.slug) {
							case 'card':
								if (['restaurant', 'qsr'].includes(this.merchant.businessType) && this.settings.general.tip_mandatory) {
									this.$bvModal.show('card-tip')
								} else {
									this.processExternalPayment('card')
								}

								break
							case 'upi':
								this.processExternalPayment('upi')

								break
							case 'credit':
								this.creditValidation()

								break
							case 'split':
								if (this.orderPayments.reduce((cardPayments, op) => {
									if (op.slug === 'card' || op.slug === 'upi') {
										cardPayments.push(op.isPaid)
									}

									return cardPayments
								}, []).includes(false) || this.orderPayments.length === 0) {
									this.processingOrder = false

									return this.$swal({
										title: this.$tc('oopsError.title'),
										text: this.orderPayments.length === 0 ? this.$t('splitPaymentWarning.text2') : this.$t('splitPaymentWarning.text4'),
										icon: 'warning',
										buttons: this.$t('ok')
									})
								}

								if (this.orderPayments.findIndex(op => op.slug === 'credit') !== -1) {
									this.creditValidation()
								} else {
									this.processOrder()
								}

								break
							default:
								this.processOrder()

								break
						}
					} else {
						this.processingOrder = false
					}
				}).catch(this.stockValidationHandler).finally(() => {
					if (this.cart.storeOrder) {
						this.checkout = { show: false, isTrusted: false }
					}
				})
			},
			creditValidation () {
				if (this.customer) {
					if (!this.customer.otp_verified && this.settings.general.enable_credit_otp_verification) {
						this.processingOrder = false

						return this.$store.commit('setState', {
							key: 'customerOtpModal',
							value: 'credit'
						})
					}

					const swalopt = {
						title: this.$t('creditLimitWarning.title'),
						icon: 'warning'
					}

					if (!this.settings.general.enable_credit_limit_restriction) {
						swalopt.buttons = [this.$t('no'), this.$t('yes')]
						swalopt.text = this.$t('creditLimitWarning.text')
					}

					let totalCreditAmount = this.totalPrice

					if (this.selectedPaymentMethod.slug === 'split') {
						totalCreditAmount = this.orderPayments.reduce((total, payment) => {
							if (payment.slug === 'credit') {
								total += payment.amount
							}

							return total
						}, 0)
					}

					if (((this.customer.credit && !this.customer.debit
						? this.customer.credit
						: (this.customer.credit - this.customer.debit)
					) + totalCreditAmount) > this.customer.credit_limit) {
						this.$swal(swalopt).then((response) => {
							if (response && !this.settings.general.enable_credit_limit_restriction) {
								this.processOrder()
							} else {
								this.processingOrder = false
							}
						})
					} else {
						this.processOrder()
					}
				} else {
					this.$bvModal.show('add-customer')
					this.processingOrder = false
				}
			},
			processTableOrder (isEditOrder) {
				if (this.selectedOrder) {
					this.order = { ...this.selectedOrder }

					if (!isEditOrder) {
						this.addOrderItemsToCart(this.order, this.cart.items.reverse(), false)
					}

					if (this.order.customer) {
						this.customer = this.order.customer
					}
				}

				this.$bvModal.hide('tables-modal')
			},
			async preProcessOrder ($event, print) {
				if (!$event || $event.detail <= 1) {
					if (await this.checkOrderLimitationReached()) {
						return this.$swal({
							title: this.$t('subscriptionLimitations.orders.title'),
							text: this.$t('subscriptionLimitations.orders.text', [
								this.merchant.subscription.limitations.orders.period,
								this.merchant.subscription.limitations.orders.limit
							]),
							icon: 'error',
							button: this.$t('ok')
						})
					}

					if (!this.isOnline && this.order?.custom_attributes?.order_type === 'dine_in' && this.order?.custom_attributes?.platform === 'online') {
						this.$store.commit('resetCheckout')

						return this.$swal({
							title: this.$t('offlineError.title'),
							text: this.$t('offlineError.text'),
							icon: 'error',
							button: this.$t('ok')
						})
					}

					this.processingOrder = true
					printFlag = print
					generatedRefCode = this.order.ref_code || generatedRefCode || (
						this.appVersionNumber >= 3852
							? (this.selectedTables.length === 0 ? await this.$store.dispatch('generateRefCode') : null)
							: await this.$store.dispatch('generateRefCode')
					)

					if (['open', 'closed', 'completed'].includes(this.order.status)) {
						this.customAttributes.edited = true

						const creditOrderPayment = this.order.payment_methods.find(op => op.slug === 'credit')

						if (this.order.payment_methods.find(op => op.slug === 'cash')) {
							let cashEvent = this.appVersionNumber < 3839
								? await this.$bridge.getRecord('CashDrawerShiftEvent', this.deviceId, this.objToJson([
									{ key: 'receipt_code', value: this.order.receipt_code },
									{ key: 'type', value: 'cash_in' }
								]))
								: await this.$bridge.getRecord('CashDrawerShiftEvent', this.objToJson([
									{ key: 'device_id', value: this.deviceId },
									{ key: 'receipt_code', value: this.order.receipt_code },
									{ key: 'type', value: 'cash_in' }
								]))

							cashEvent = typeof cashEvent === 'string' ? JSON.parse(cashEvent) : cashEvent

							if (cashEvent) {
								await this.$bridge.deleteRecord('CashDrawerShiftEvent', cashEvent.id)
							}
						}

						if (creditOrderPayment && this.order.customer) {
							const credit = this.order.customer.credit - creditOrderPayment.amount

							if (this.customer && this.customer.id === this.order.customer.id) {
								this.customer.credit = credit
							} else {
								const customer = { id: this.order.customer.id, credit }
								let dbCustomer = await this.$bridge.getCustomers(this.deviceId, this.objToJson({
									id: customer.id
								}))

								dbCustomer = (typeof dbCustomer === 'string' ? JSON.parse(dbCustomer) : dbCustomer).data[0]

								if (!dbCustomer) {
									this.customer.credit = credit
									customer.id = this.customer.id
								}

								this.$bridge.insert(
									'Customer',
									this.bridgeName === 'ANDROID' ? this.objToJson(customer) : customer,
									true
								)
							}
						}
					}

					if (this.selectedTables.length) {
						if (this.order.id && this.order.items.some((item) => {
							const cartItem = this.filterItem(this.cart.items, item)

							return cartItem === null || cartItem.quantity < item.quantity
						})) {
							if (this.employeePolicy.isAdmin || (this.employeePolicy.tables && this.employeePolicy.tables.includes('reduce quantity'))) {
								this.validateOrder()
							} else {
								this.otpModalType = 'cancel-item'
								this.processingOrder = false
							}
						} else {
							this.validateOrder()
						}
					} else {
						this.validateOrder()
					}
				}
			},
			async processOrder () {
				const date = new Date()
				const id = this.getUniqueId()
				const orderId = this.order.id || id
				const cart = JSON.parse(this.objToJson(this.cart))
				const items = []
				let kotItems = []
				let removedItemCodes = []
				let totalGiftCardDiscount = 0

				cart.items.forEach((itemVariation, index) => {
					let removedQuantity = 0
					const giftCardDiscount = itemVariation.discounts.find(d => d.entity === 'gift_card')
					const totalDiscount = itemVariation.discountedAmount + itemVariation.discountedTax
					const orderItem = this.order.id ? this.filterItem(this.order.items, itemVariation) : null
					const item = {
						item_id: itemVariation.item_id,
						category_id: itemVariation.category_id,
						brand_id: itemVariation.brand_id || null,
						item_variation_id: itemVariation.id,
						item_inventory_id: itemVariation.inventory_id,
						batch_id: typeof itemVariation.batch_id === 'number' ? itemVariation.batch_id : null,
						merchant_price_category_id: itemVariation.price_category_id,
						kot_device_id: itemVariation.kot_device_id,
						item_code: orderItem
							? orderItem.item_code
							: itemVariation.item_code || `${this.deviceId}${itemVariation.id}${date.valueOf()}${index}`,
						item_name: itemVariation.item_name,
						item_variation_name: itemVariation.name,
						alternate_name: itemVariation.alternate_name,
						barcode: itemVariation.barcode,
						hsn: itemVariation.hsn,
						itemization_type: itemVariation.itemization_type,
						unit_measure_type: itemVariation.unit_measure_type,
						mrp: itemVariation.mrp,
						single_quantity_amount: this.$currency.transformNumber(itemVariation.price),
						quantity: Number(itemVariation.quantity),
						groups: itemVariation.groups,
						notes: itemVariation.notes,
						sub_total: this.$currency.transformNumber(itemVariation.subtotal),
						total: this.$currency.transformNumber((itemVariation.subtotal + itemVariation.taxAmount) - totalDiscount),
						gross_sales: itemVariation.subtotal,
						net_sales: this.$currency.transformNumber(itemVariation.subtotal - itemVariation.discountedAmount),
						tax: this.$currency.transformNumber(itemVariation.taxAmount),
						tax_type: itemVariation.taxType,
						tax_details: itemVariation.taxes,
						item_discount: itemVariation.item_discount,
						discount: totalDiscount,
						discounted_amount: itemVariation.discountedAmount,
						discounted_tax: itemVariation.discountedTax,
						discount_details: itemVariation.discounts,
						custom_attributes: itemVariation.custom_attributes
					}

					this.$set(this.cart.items[index], 'item_code', item.item_code)
					items.unshift(item)

					if (giftCardDiscount) {
						totalGiftCardDiscount += giftCardDiscount.discounted_amount + giftCardDiscount.discounted_tax
					}

					if (['restaurant', 'qsr'].includes(this.merchant.businessType) && !this.cart.advanceOrder.enabled) {
						const kotId = this.getUniqueId(`${orderId}${(
							this.order.id ? this.order.kot_items.length : 0
						) + kotItems.length + 1}`)

						if (!orderItem || (orderItem && item.quantity > orderItem.quantity)) {
							if (itemVariation.type === 'combo') {
								item.groups.filter(g => g.type === 'combo').forEach((g, gIndex) => {
									kotItems.unshift({
										id: kotId + gIndex,
										kot_id: id,
										kot_device_id: g.kot_device_id,
										employee_shift_id: this.employeeShift.id,
										order_id: orderId,
										item_variation_id: g.group_item_variation_id,
										item_code: `${this.deviceId}${itemVariation.id}${date.valueOf()}${
											gIndex
										}`,
										item_variation_name: `${itemVariation.name} - ${g.item_variation_name}`,
										item_variation_alternate_name: g.alternate_name || itemVariation.alternate_name,
										unit_measure_type: g.unit_measure_type,
										quantity: g.quantity,
										modifiers: g.modifiers,
										price: this.$currency.transformNumber((items[0].single_quantity_amount * g.quantity) / item.groups.length),
										notes: item.notes,
										status: 'pending',
										status_history: [{
											status: 'pending',
											created_at: date
										}],
										created_at: date,
										updated_at: date
									})
								})
							} else {
								kotItems.unshift({
									id: kotId,
									kot_id: id,
									kot_device_id: item.kot_device_id,
									employee_shift_id: this.employeeShift.id,
									order_id: orderId,
									item_variation_id: item.item_variation_id,
									item_code: item.item_code,
									item_variation_name: item.item_variation_name,
									item_variation_alternate_name: item.alternate_name,
									unit_measure_type: item.unit_measure_type,
									quantity: orderItem
										? Math.abs(orderItem.quantity - itemVariation.quantity)
										: itemVariation.quantity,
									modifiers: item.groups.filter(g => g.type === 'modifier'),
									price: this.$currency.transformNumber(items[0].single_quantity_amount * (orderItem
										? Math.abs(orderItem.quantity - itemVariation.quantity)
										: itemVariation.quantity)),
									notes: item.notes,
									status: 'pending',
									status_history: [{
										status: 'pending',
										created_at: date
									}],
									created_at: date,
									updated_at: date
								})
							}
						} else if (orderItem && item.quantity < orderItem.quantity) {
							let reducedQuantity = 0
							const comboItemCodes = item.groups.filter(g => g.type === 'combo').map((comboItem, i) => {
								return orderItem.item_code.slice(0, -1) + i
							})

							const itemKots = this.order.kot_items.filter((i) => {
								if (comboItemCodes.length) {
									return comboItemCodes.includes(i.item_code)
								} else {
									return i.item_code === item.item_code
								}
							})

							removedQuantity = orderItem.quantity - item.quantity
							itemKots.sort((a, b) => (b.quantity - a.quantity))

							for (const i in itemKots) {
								const minQuantity = Math.min(itemKots[i].quantity, removedQuantity)
								const quantity = itemKots[i].quantity - minQuantity
								const dispatchedFlag = (itemKots[i].status_history.filter((sh) => { return sh.status === 'dispatched' }).length !== 0)

								const kotCancelIndex = this.kotCancellationReason.findIndex(kot => kot.kotItemCode === itemKots[i].item_code)

								if (quantity <= 0) {
									itemKots[i].price = (orderItem.price * quantity) / (orderItem.groups.length > 0 ? orderItem.groups.length : 1)

									itemKots[i].status = 'removed'
									itemKots[i].status_history = itemKots[i].status_history.concat([{
										status: 'removed',
										created_at: date
									}])
									itemKots[i].cancellation_reason = this.kotCancellationReason[kotCancelIndex]?.cancellationReason
									itemKots[i].updated_at = date
									kotItems.push({
										...itemKots[i],
										id: kotId,
										kot_id: id,
										status: 'cancelled',
										status_history: dispatchedFlag
											? [{
												...itemKots[i].status_history[0]
											}, {
												status: 'dispatched',
												created_at: date
											}, {
												status: 'cancelled',
												created_at: date
											}]
											: [{
												...itemKots[i].status_history[0]
											}, {
												status: 'cancelled',
												created_at: date
											}]
									})
								} else {
									itemKots[i].price = (orderItem.price * quantity) / (orderItem.groups.length > 0 ? orderItem.groups.length : 1)
									itemKots[i].quantity = quantity
									itemKots[i].updated_at = date
									itemKots[i].cancellation_reason = this.kotCancellationReason[kotCancelIndex]?.cancellationReason

									if (comboItemCodes.length) {
										kotItems.unshift({
											...itemKots[i],
											id: this.getUniqueId(`${orderId}${this.order.kot_items.length + kotItems.length + 1}`),
											kot_id: id,
											quantity: minQuantity,
											price: this.$currency.transformNumber((orderItem.price * minQuantity) / (orderItem.groups.length > 0 ? orderItem.groups.length : 1)),
											status: 'cancelled',
											status_history: dispatchedFlag
												? [{
													...itemKots[i].status_history[0]
												}, {
													status: 'dispatched',
													created_at: date
												}, {
													status: 'cancelled',
													created_at: date
												}]
												: [{
													...itemKots[i].status_history[0]
												}, {
													status: 'cancelled',
													created_at: date
												}]
										})
									} else {
										reducedQuantity += minQuantity
									}
								}

								if (comboItemCodes.length === 0) {
									removedQuantity -= minQuantity

									if (removedQuantity <= 0) {
										break
									}
								}
							}

							if (reducedQuantity) {
								const kotCancelIndex = this.kotCancellationReason.findIndex(kot => kot.kotItemCode === item.item_code)
								const dispatchedFlag = (itemKots.find((ik) => {
									return ik.item_code === item.item_code
								}).status_history.filter((sh) => { return sh.status === 'dispatched' }).length !== 0)
								const originalKot = itemKots.find((ik) => {
									return ik.item_code === item.item_code
								})

								kotItems.unshift({
									id: kotId,
									kot_id: id,
									kot_device_id: item.kot_device_id,
									employee_shift_id: this.employeeShift.id,
									order_id: orderId,
									item_variation_id: item.item_variation_id,
									item_code: item.item_code,
									cancellation_reason: this.kotCancellationReason[kotCancelIndex]?.cancellationReason,
									item_variation_name: item.item_variation_name,
									item_variation_alternate_name: item.alternate_name,
									unit_measure_type: item.unit_measure_type,
									quantity: reducedQuantity,
									price: this.$currency.transformNumber(item.single_quantity_amount * reducedQuantity),
									modifiers: item.groups.filter(g => g.type === 'modifier'),
									notes: item.notes,
									status: 'cancelled',
									status_history: dispatchedFlag
										? [{
											...originalKot.status_history[0]
										}, {
											status: 'dispatched',
											created_at: date
										}, {
											status: 'cancelled',
											created_at: date
										}]
										: [{
											...originalKot.status_history[0]
										}, {
											status: 'cancelled',
											created_at: date
										}],
									created_at: date,
									updated_at: date
								})
							}
						}
					}
				})

				if (this.order.id) {
					removedItemCodes = this.order.items.reduce((ri, item) => {
						if (items.findIndex(i => i.item_code === item.item_code) === -1) {
							const combos = item.groups.filter(g => g.type === 'combo')

							if (combos.length) {
								for (let i = 0; i < combos.length; i++) {
									ri.push(item.item_code.slice(0, -1) + i)
								}
							} else {
								ri.push(item.item_code)
							}
						}

						return ri
					}, [])
				}

				for (const i in removedItemCodes) {
					const itemKots = this.order.kot_items.filter((ki) => {
						return ki.item_code === removedItemCodes[i] && !['cancelled', 'removed'].includes(ki.status)
					})

					for (const i in itemKots) {
						const kotCancelIndex = this.kotCancellationReason.findIndex(kot => kot.kotItemCode === itemKots[i].item_code)
						const currentStatus = itemKots[i].status
						const status = currentStatus === 'dispatched' ? 'cancelled' : 'removed'

						itemKots[i].status = status
						itemKots[i].cancellation_reason = this.kotCancellationReason[kotCancelIndex]?.cancellationReason
						itemKots[i].status_history = itemKots[i].status_history.concat([{
							status,
							created_at: date
						}])
						itemKots[i].updated_at = date

						if (currentStatus !== 'dispatched') {
							kotItems.push({
								...itemKots[i],
								id: this.getUniqueId(`${orderId}${this.order.kot_items.length + kotItems.length + 1}`),
								kot_id: id,
								status: 'cancelled',
								status_history: [{
									...itemKots[i].status_history[0]
								}, {
									status: 'cancelled',
									created_at: date
								}]
							})
						}
					}
				}

				const total = this.totalPrice

				this.totalAmount = this.balanceAmount.totalAmount

				const dueAmount = this.selectedPaymentMethod.slug === 'credit'
					? total
					: this.cart.advanceOrder.enabled || this.cart.storeOrder
						? total - this.totalAmount
						: this.orderPayments.find(op => op.slug === 'credit')?.amount || 0
				const orderCredits = []

				if (dueAmount > 0 && this.customer && (this.appVersionNumber >= 4110 || (this.bridgeName === 'ANDROID' && this.appVersionNumber >= 4029))) {
					orderCredits.push({
						id: this.getUniqueId(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						employee_id: this.employee.id,
						employee_shift_id: this.employeeShift.id,
						customer_id: this.customer.id,
						order_id: orderId,
						credit_code: `${this.deviceId}${date.valueOf()}`,
						amount: parseFloat(dueAmount),
						payment_method: 'credit',
						type: 'credit',
						notes: '',
						custom_attributes: this.objToJson({}),
						is_synced: true,
						created_at: date,
						updated_at: date
					})
				}

				const labels = Object.keys(this.cart.additionalInfo)

				receiptCode = receiptCode ||
					this.order.receipt_code ||
					await this.$store.dispatch('generateReceiptCode')

				if (!receiptCode) {
					return this.$swal({
						title: this.$t('primaryDeviceError.title'),
						text: this.$t('primaryDeviceError.text'),
						icon: 'error',
						buttons: [this.$tc('cancel', 1), this.$t('connect')]
					}).then((response) => {
						if (response) {
							this.processingOrder = true
							this.processOrder()
						} else {
							this.processingOrder = false

							return 0
						}
					})
				}

				if (this.tip) {
					this.customAttributes.tip = this.tip
				}

				if (this.selectedForeignCurrency && !cart.advanceOrder.enabled && this.selectedPaymentMethod.slug === 'cash') {
					this.customAttributes.exchange_base_total = this.cart.price.total / this.selectedForeignCurrency.exchangeRate
					this.customAttributes.exchange_rate = this.selectedForeignCurrency.exchangeRate
					this.customAttributes.current_exchange_rate = this.selectedForeignCurrency.currentExchangeRate
					this.customAttributes.exchange_rate_type = this.selectedForeignCurrency.exchangeRateType
					this.customAttributes.exchange_rate_value = this.selectedForeignCurrency.exchangeRateValue
					this.customAttributes.exchange_currency_code = this.selectedForeignCurrency.code
					this.customAttributes.foreign_currency_total_amount = this.selectedForeignCurrency.totalAmount
					this.customAttributes.foreign_currency_balance_returned = this.balanceAmount.foreignCurrency
				}

				if (this.selectedPaymentMethod.slug === 'other' && this.otherPaymentType) {
					this.customAttributes.other_payment_method = this.otherPaymentType
				} else if (this.selectedPaymentMethod.slug === 'split') {
					this.customAttributes.split_payment_type = this.splitPayment.splitType
				}

				if (labels.length) {
					this.customAttributes.labels = labels.map((i) => {
						return {
							label: i,
							value: this.cart.additionalInfo[i]
						}
					})
				}

				if (this.cart.ignoredCharges?.length) {
					this.customAttributes.ignoredCharges = this.cart.ignoredCharges
				}

				if (this.customer) {
					if (this.customer.custom_attributes.permit_number) {
						if (this.isPermitNumberApplied) {
							if (this.customer.custom_attributes.permit_type === 'daily') {
								this.customer.custom_attributes.permit_validity = this.$moment().format('YYYY-MM-DD')
							}

							await this.$bridge.setLocalStorage('permitNumber', this.customer.custom_attributes.permit_number)
						}

						this.customAttributes.permit = {
							number: this.customer.custom_attributes.permit_number,
							type: this.customer.custom_attributes.permit_type,
							update_counter: this.isPermitNumberApplied
						}
					}

					if (this.loyalty && this.loyalty.active) {
						this.customAttributes.loyalty_points = {
							total_points: this.loyalty.totalPoints,
							spendingPerPoint: this.loyalty.spendingPerPoint,
							discountedAmountPerPoint: this.loyalty.amountPerPoint,
							redeemable_points: this.loyalty.applied ? this.loyalty.redeemablePoints : 0,
							earned_points: this.loyalty.applied ? this.loyalty?.earnedPointsWithDiscount : this.loyalty?.earnedPointsWithoutDiscount
						}

						if (this.selectedLoyaltyDiscount && this.loyalty.product_type === 'advanced_loyalty') {
							this.customAttributes.loyalty_points.redeemable_points = this.loyalty.applied ? this.selectedLoyaltyDiscount.redeemablePoints || this.loyalty.redeemablePoints : 0
							this.customAttributes.loyalty_points.rewardId = this.selectedLoyaltyDiscount.rewardId || this.loyalty.rewardId
							this.customAttributes.loyalty_points.earned_points = this.loyalty.applied
								? (this.selectedLoyaltyDiscount.earnedPointsWithDiscount >= 0
									? this.selectedLoyaltyDiscount.earnedPointsWithDiscount
									: (this.loyalty.earnedPoints || 0))
								: this.loyalty.earnedPointsWithoutDiscount
							this.customAttributes.loyalty_points.product_type = 'advanced_loyalty'
						}
					}

					if (this.cart.advanceOrder.enabled) {
						this.customAttributes.platform = 'advance'
						this.customAttributes.due_amount = dueAmount
						this.customAttributes.order_type = this.cart.advanceOrder.orderType
						this.customAttributes.scheduled_at = this.$moment.utc(this.cart.advanceOrder.scheduledOn.startDate).format('YYYY-MM-DD HH:mm:ss')

						if (this.customAttributes.order_type === 'delivery') {
							this.customer.custom_attributes.delivery_address = [this.cart.advanceOrder.deliveryAddress]
							this.customAttributes.delivery_address = this.cart.advanceOrder.deliveryAddress
						}
					}

					if (this.cart.storeOrder) {
						this.customAttributes.platform = 'store'
						this.customAttributes.due_amount = total
						this.customAttributes.order_type = this.cart.storeOrder.orderType

						if (this.customAttributes.order_type === 'delivery') {
							this.customAttributes.delivery_address = this.cart.storeOrder.deliveryAddress
						}
					}
				}

				const tableIds = this.selectedTables.map(t => t.id)

				if (this.order.id) {
					this.order.tables.forEach((table) => {
						if (this.selectedTables.findIndex(st => st.id === table.id) === -1) {
							tableIds.push(table.id)
						}
					})
				}

				let tableOrders = []

				if (tableIds.length) {
					if (this.appVersionNumber < 4030 && this.bridgeName === 'ANDROID') {
						tableOrders = await this.$bridge.getOrders(this.deviceId, this.objToJson({
							table_id: tableIds,
							status: ['pending', 'billed']
						}))

						tableOrders = (typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders).data
					}

					this.customAttributes.table_start_time = this.cart.tableStartTime || this.order.custom_attributes.table_start_time

					if (this.cart.numberOfGuest) {
						this.customAttributes.no_of_guest = this.cart.numberOfGuest
					}

					if (this.refCode) {
						this.customAttributes.table_end_time = date
					}
				}

				if (this.selectedDiscount?.entity === 'gift_card') {
					this.customAttributes.gift_card = {
						code: this.selectedDiscount.code,
						expires_on: this.selectedDiscount.expires_on,
						redeemed_amount: this.$currency.transformNumber(totalGiftCardDiscount),
						balance_amount: this.$currency.transformNumber(this.selectedDiscount.max_discount_value - totalGiftCardDiscount)
					}
				} else {
					delete this.customAttributes.gift_card
				}

				const creditPaymentMethod = this.paymentMethods.find(pm => pm.slug === 'credit')
				const codPaymentMethod = this.paymentMethods.find(pm => pm.slug === 'cod')
				const order = {
					id: orderId,
					order_id: this.order.order_id ? this.order.order_id : null,
					merchant_id: this.merchant.id,
					merchant: this.merchant,
					device_id: this.deviceId,
					location_id: this.locationId,
					employee_id: this.employee.id,
					employee_shift_id: this.employeeShift.id,
					created_by: this.order.created_by
						? this.order.created_by.id
						: this.cart.waiter?.id,
					employee_shift_code: this.employeeShift.shift_code,
					customer_id: this.customer ? parseInt(this.customer.phone) : null,
					tables: (this.selectedTables.length ? this.selectedTables : (this.order.tables || [])).map((table) => {
						const updatedTable = {
							...table,
							layout: this.objToJson(table.layout),
							custom_attributes: this.selectedTables.length ? this.objToJson(table.custom_attributes) : '{}',
							updated_at: date
						}

						if (this.appVersionNumber < 4030 && this.bridgeName === 'ANDROID') {
							const orders = tableOrders.filter((o) => {
								return o.tables.findIndex(t => t.id === table.id) !== -1
							})

							updatedTable.is_occupied = (this.source === 'sell' && (
								!this.order.id || !['open', 'closed', 'completed'].includes(this.order.status)
							)) || orders.length > 1
						}

						return updatedTable
					}),
					merchant_price_category_id: this.order.price_category
						? this.order.price_category.id
						: (this.cart.priceCategory ? this.cart.priceCategory.id : null),
					payment_method_id: this.cart.advanceOrder.enabled && dueAmount > 0
						? this.paymentMethods.find(pm => pm.slug === 'split')?.id
						: this.selectedPaymentMethod?.id,
					price_category: this.order.price_category ? this.order.price_category : this.cart.priceCategory,
					status: this.selectedTables.length > 0 || this.cart.advanceOrder.enabled
						? (this.order.status || 'pending')
						: this.cart.storeOrder?.orderType === 'delivery'
							? 'pending'
							: (this.appVersionNumber >= 4039 && this.bridgeName === 'ELECTRON' &&
								(this.selectedPaymentMethod.slug === 'credit' || this.orderPayments.find(op => op.slug === 'credit')))
								? 'open'
								: 'completed',
					receipt_code: receiptCode,
					ref_code: this.refCode,
					sub_total: cart.price.subtotal,
					inclusive_tax: cart.price.inclusive_tax,
					additive_tax: cart.price.additive_tax,
					total_tax: cart.price.tax,
					taxes: cart.price.taxes,
					total_charge: cart.price.charge,
					charges: cart.price.charges,
					total_price: cart.price.total,
					total_discount: cart.price.discount,
					total_discounted_amount: cart.price.discountedAmount,
					total_discounted_tax: cart.price.discountedTax,
					discounts: this.selectedDiscount ? [this.selectedDiscount] : [],
					card_details: this.customAttributes.payment_methods.length
						? { number: this.customAttributes.payment_methods.map(m => m.number).toString() }
						: {},
					payment_note: this.paymentNote,
					amount_balance_returned: this.balanceAmount.nativeCurrency,
					total_amount: this.selectedTables.length === 0
						? (
							this.selectedPaymentMethod.slug === 'cash' || this.cart.advanceOrder.enabled
								? parseFloat(this.totalAmount)
								: total
						)
						: 0,
					due_amount: dueAmount,
					tip: cart.price.tip,
					round_off_amount: cart.price.roundOff,
					custom_attributes: this.customAttributes,
					items,
					credits: orderCredits,
					order_payments: this.cart.storeOrder
						? [{
							...(this.cart.storeOrder.orderType === 'delivery' ? codPaymentMethod : creditPaymentMethod),
							payment_method_id: (this.cart.storeOrder.orderType === 'delivery' ? codPaymentMethod : creditPaymentMethod).id,
							employee_shift_id: this.employeeShift.id,
							order_id: orderId,
							amount: total,
							updated_at: date,
							card_details: {}
						}]
						: this.selectedPaymentMethod.slug !== 'split'
							? this.cart.advanceOrder.enabled && +this.totalAmount <= 0
								? []
								: [{
									payment_method_id: this.selectedPaymentMethod.id,
									employee_shift_id: this.employeeShift.id,
									order_id: orderId,
									slug: this.selectedPaymentMethod.slug,
									name: this.selectedPaymentMethod.name,
									amount: this.cart.advanceOrder.enabled ? +this.totalAmount : total,
									updated_at: date,
									card_details: this.customAttributes.payment_methods.length
										? this.customAttributes.payment_methods[0]
										: {}
								}]
							: this.orderPayments.map(op => (Object.assign({
								payment_method_id: op.id || op.payment_method_id,
								employee_shift_id: this.employeeShift.id,
								order_id: orderId,
								slug: op.slug,
								name: op.name,
								amount: op.amount,
								updated_at: date
							}, op.slug === 'card'
								? { card_details: op.card_details }
								: op.slug === 'upi'
									? { upi_details: op.upi_details }
									: {}))),
					customers: this.customer
						? [{
							first_name: this.customer.first_name,
							last_name: this.customer.last_name,
							gender: this.customer.gender,
							dob: this.customer.dob ? this.$moment(this.customer.dob).format('YYYY-MM-DD') : null,
							customer_code: this.customer.code,
							email: this.customer.email,
							phone: this.customer.phone,
							alternative_phone: this.customer.custom_attributes.alternative_phone,
							merchant_customer_group_id: (this.customer.customer_group && this.customer.customer_group.id) || null,
							credit_code: receiptCode,
							credit_limit: this.customer.credit_limit,
							credit: this.customer.credit,
							debit: this.customer.debit,
							currency_code: this.merchant.currencyCode,
							calling_code: this.merchant.businessPhone.calling_code,
							address: this.customer.address,
							delivery_address: this.cart.advanceOrder.enabled && this.cart.advanceOrder.orderType === 'delivery'
								? this.cart.advanceOrder.deliveryAddress
								: null,
							custom_attributes: this.customer.custom_attributes
						}]
						: [],
					kot_items: (this.order.id ? this.order.kot_items.concat(kotItems) : kotItems).map((i) => {
						if (this.source === 'table' && this.selectedTables.length === 0 && ![
							'dispatched', 'cancelled', 'removed'
						].includes(i.status)) {
							i.status = 'dispatched'
							i.status_history = i.status_history.concat([{
								status: 'dispatched',
								created_at: date
							}])
						}

						return i
					}),
					order_type: this.cart.advanceOrder.enabled
						? this.cart.advanceOrder.orderType
						: this.cart.storeOrder?.orderType || 'other',
					scheduled_at: this.cart.advanceOrder.enabled
						? this.$moment.utc(this.cart.advanceOrder.scheduledOn.startDate).toDate()
						: null,
					created_at: this.$moment.utc(date).toDate(),
					updated_at: date,
					is_synced: !!this.isMiniPlan
				}

				if (
					dueAmount > 0 &&
					this.cart.advanceOrder.enabled &&
					this.selectedPaymentMethod?.slug !== 'credit'
				) {
					const creditPaymentMethod = this.paymentMethods.find(pm => pm.slug === 'credit')

					if (creditPaymentMethod) {
						order.order_payments.push({
							payment_method_id: creditPaymentMethod.id,
							employee_id: this.employee.id,
							employee_shift_id: this.employeeShift.id,
							order_id: orderId,
							slug: creditPaymentMethod.slug,
							name: creditPaymentMethod.name,
							amount: dueAmount,
							updated_at: date
						})
					}
				}

				if (order.status === 'billed') {
					order.custom_attributes.edited_after_billed = true
				}

				const deviceSettings = {}
				const creditOrderPayment = order.order_payments.find(op => op.slug === 'credit')

				if (this.isCustomRefund) {
					order.refunds = [{
						id: orderId,
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						employee_id: this.employee.id,
						employee_shift_id: this.employeeShift.id,
						order_id: orderId,
						payment_method_id: this.selectedPaymentMethod.id,
						items: order.items.map((refundItem) => {
							return {
								id: this.deviceId + refundItem.item_variation_id + date.valueOf(),
								item_code: refundItem.item_code,
								refunded_qty: refundItem.quantity,
								refunded_amount: this.$currency.transformNumber(refundItem.total),
								refunded_tax: this.$currency.transformNumber(refundItem.tax),
								refunded_taxes: refundItem.tax_details.map(t => ({
									...t,
									refunded_tax: this.$currency.transformNumber(t.tax_amount)
								}))
							}
						}, []),
						amount: this.$currency.transformNumber(order.total_price),
						tax: this.$currency.transformNumber(order.total_tax),
						taxes: order.taxes,
						type: 'full',
						reason: 'Returned Goods',
						restock: this.restock,
						custom_attributes: {
							is_custom_refund: true,
							restock: this.restockOptions.find(r => r.value === this.restock)?.text || ''
						},
						created_at: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
						updated_at: order.updated_at
					}]
				}

				if (order.ref_code) {
					const orderCount = await this.$bridge.getLocalStorage('orderCount')
					const refCode = await this.refCode?.replace(generatedRefCode?.prefix, '')
					const lastForceSyncDate = await this.$bridge.getLocalStorage('lastForceSyncDate')
					const nextBussinessOpeningTime = await this.$bridge.getLocalStorage('nextBussinessOpeningTime')

					if (refCode) {
						deviceSettings.refCode = {
							date,
							count: refCode
						}
					}

					if (orderCount) {
						deviceSettings.orderCount = +orderCount
					}

					if (lastForceSyncDate) {
						deviceSettings.lastForceSyncDate = lastForceSyncDate
					}

					if (nextBussinessOpeningTime) {
						deviceSettings.nextBussinessOpeningTime = nextBussinessOpeningTime
					}
				}

				try {
					const response = await this.$store.dispatch('createOrder', {
						device_id: order.device_id,
						location_id: order.location_id,
						employee_id: order.employee_id,
						orders: [order],
						device_settings: deviceSettings
					})

					if (this.cart.advanceOrder.enabled) {
						order.id = this.getUniqueId(response.data.data.pos_order_id)
						order.order_id = order.id
						order.order_payments = order.order_payments.map(op => ({
							...op,
							order_id: order.id
						}))
						order.credits = order.credits.map(credit => ({
							...credit,
							order_id: order.id
						}))
						this.$store.dispatch('getAdvanceOrdersCount')
					}

					if (response?.data.data?.orders && this.cart.storeOrder?.orderType !== 'delivery') {
						order.order_id = this.getUniqueId(response.data.data.orders.id)
						order.custom_attributes.qr = response.data.data.orders.customAttributes.qr
						order.updated_at = new Date()
						order.is_synced = true

						if (this.customer && !this.customer.customer_id && response.data.data.orders.customers.length) {
							this.customer.customer_id = response.data.data.orders.customers[0].customer.id
						}
					}

					kotItems = kotItems.reduce((acc, ki) => {
						const findItemIndex = this.filterItem(acc, ki, 'index')

						if (findItemIndex >= 0 && acc[findItemIndex].quantity === ki.quantity &&
							(acc[findItemIndex].status === 'cancelled' || ki.quantity === 'cancelled')) {
							acc.splice(findItemIndex, 1)
						} else {
							acc.push(ki)
						}

						return acc
					}, [])

					const nonDispatchedKotItems = kotItems.reduce((acc, ki) => {
						if (ki.status_history.filter(sh => sh.status === 'dispatched').length === 0) {
							acc.push(ki)
						}

						return acc
					}, [])

					if (nonDispatchedKotItems.length && [
						'restaurant', 'qsr'
					].includes(this.merchant.businessType) &&
						this.source === 'sell' &&
						(!this.cart.storeOrder || this.cart.storeOrder.orderType === 'pickup')) {
						window.printKot({
							...order,
							items: this.groupBy(kotItems, 'kot_device_id'),
							is_running_order: !!this.order.id
						})
					}

					if (printFlag) {
						window.printOrder({
							...order,
							customer: this.customer,
							payment_method: this.selectedPaymentMethod,
							created_by: this.order.created_by || (order.created_by === 'object' ? order.created_by : this.cart.waiter)
						})
					}

					if (this.cart.storeOrder?.orderType !== 'delivery') {
						await this.$bridge.customInsertOrUpdate(
							'Order',
							this.deviceId,
							this.locationId,
							this.bridgeName === 'ANDROID' ? this.objToJson(order) : order
						)
					}

					if (this.appVersionNumber >= 4030 || this.bridgeName === 'ELECTRON') {
						await this.$store.dispatch('updateTableStatus', order.tables?.map(t => t.id))
					}

					if (this.customer) {
						const customAttributes = { ...this.customer.custom_attributes }

						delete customAttributes.alternative_phone

						const customer = Object.assign({}, this.customer, {
							merchant_id: this.merchant.id,
							device_id: this.deviceId,
							address: this.objToJson(this.customer.address),
							credit: this.selectedPaymentMethod.slug === 'credit' || creditOrderPayment
								? ((this.customer.credit || 0) + (creditOrderPayment ? creditOrderPayment.amount : total))
								: (this.customer.credit || 0),
							custom_attributes: this.objToJson(customAttributes),
							updated_at: date
						})

						this.$bridge.insert(
							'Customer',
							this.bridgeName === 'ANDROID' ? this.objToJson(customer) : customer,
							true
						)
					}

					if ((
						this.appVersionNumber >= 3852 && !this.customAttributes.edited && !this.customAttributes.channel_id
					) || !this.order.id) {
						await this.$store.dispatch('updateLastOrder', {
							orderCount: receiptCode.substr(-4),
							refCode: this.refCode?.replace(generatedRefCode?.prefix, ''),
							date
						})
					}

					let syncData

					if (!this.isMiniPlan && (['open', 'closed', 'completed'].includes(order.status) || this.cart.storeOrder?.orderType === 'delivery')) {
						order.tables = order.tables.map(table => table.id)
						delete order.updated_at

						if (!response && !order.custom_attributes.channel_id) {
							syncData = {
								id: this.getUniqueId(+order.id + 1),
								model_id: order.id,
								model_name: 'order',
								payload: this.objToJson({
									model_id: order.id,
									merchant_id: order.merchant_id,
									device_id: order.device_id,
									location_id: order.location_id,
									employee_id: order.employee_id,
									orders: [order],
									device_settings: deviceSettings
								})
							}

							this.$bridge.insert(
								'Sync',
								this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
								true
							)
						}

						if (this.isCustomRefund) {
							syncData = {
								id: this.getUniqueId(+order.refunds[0].id + 2),
								model_id: order.refunds[0].id,
								model_name: 'order-refund',
								payload: this.objToJson({
									model_id: order.refunds[0].id,
									order_id: order.refunds[0].order_id,
									payment_method_id: order.refunds[0].payment_method_id,
									employee_shift_code: this.employeeShift.shift_code,
									items: order.refunds[0].items,
									refunded_amount: order.refunds[0].amount,
									refunded_tax: order.refunds[0].tax,
									type: order.refunds[0].type,
									restock: this.restock,
									reason: order.refunds[0].reason,
									custom_attributes: order.refunds[0].custom_attributes,
									created_at: order.refunds[0].created_at
								})
							}

							this.$bridge.insert(
								'Sync',
								this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
								false
							)
						}
					}

					if ((['open', 'closed', 'completed'].includes(order.status) || order.custom_attributes.platform === 'advance')) {
						const cashOrderPayments = order.order_payments.filter(op => op.slug === 'cash')

						if (this.selectedPaymentMethod.slug === 'cash' || cashOrderPayments.length) {
							const event = {
								id,
								merchant_id: this.merchant.id,
								device_id: this.deviceId,
								employee_id: this.employee.id,
								employee_shift_id: this.employeeShift.id,
								cash_drawer_shift_id: this.cashDrawerShift.id,
								receipt_code: order.receipt_code,
								amount: cashOrderPayments.length || order.custom_attributes.platform === 'advance'
									? cashOrderPayments.reduce((sum, payment) => {
										sum += payment.amount

										return sum
									}, 0)
									: total,
								cash_via: this.isCustomRefund ? 'refund' : 'sale',
								type: this.isCustomRefund ? 'cash_out' : 'cash_in',
								is_synced: !!this.isMiniPlan,
								updated_at: date
							}

							this.$bridge.insert(
								'CashDrawerShiftEvent',
								this.bridgeName === 'ANDROID' ? this.objToJson(event) : event,
								true
							)
							this.$bridge.openCashDrawer(this.bridgeName === 'ANDROID' ? this.objToJson(this.printerSettings) : this.printerSettings)

							if (!this.isMiniPlan) {
								syncData = {
									id: this.getUniqueId(+order.id + 3),
									model_id: event.id,
									model_name: 'cash-drawer-shift-event',
									payload: this.objToJson({
										model_id: event.id,
										cash_drawer_shift_id: event.cash_drawer_shift_id,
										employee_id: event.employee_id,
										receipt_code: event.receipt_code,
										event_type: event.type,
										cash_via: event.cash_via,
										event_money: event.amount,
										shift_event_code: `${this.deviceId}${date.valueOf()}`,
										employee_shift_code: this.employeeShift.shift_code
									})
								}
								this.$bridge.insert(
									'Sync',
									this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
									true
								)
							}
						}
					}

					if (!this.isMiniPlan && order.custom_attributes.channel_id && Array.isArray(order.custom_attributes.tables)) {
						this.$store.dispatch('updateOnlineOrder', {
							orderId: order.id,
							data: { ...order }
						})
					}

					if (this.isCustomRefund) {
						document.querySelector('#all-items-tab')?.click()
					}

					receiptCode = null
					generatedRefCode = null
					this.selectedOrder = null
					this.$store.commit('resetCart')
					this.$store.commit('resetCheckout')
					this.$root.$emit('checkout-completed')
					this.$store.commit('setState', { key: 'kotCancellationReason', value: [] })

					if (this.showOrderConfirmation) {
						const swalOptions = {
							title: this.$options.filters.capitalize(this.$tc('success')) + '!',
							icon: 'success',
							button: this.$t('ok')
						}

						if (this.selectedTables.length === 0) {
							const div = document.createElement('div')
							const swalContent = `<div class='row'>
								<div class='col text-right'>
									<h4 class='text-capitalize m-0'>${this.$t('total')} :</h4>
									<p class='text-capitalize text-success m-0'>${this.$t('amount tendered')} :</p>
									${this.balanceAmount.nativeCurrency > 0 ? `<p class='text-capitalize text-danger m-0'>${this.$t('amount returned')} :</p>` : ''}
                  ${dueAmount > 0 ? `<p class='text-capitalize text-danger m-0'>${this.$t('amount due')} :</p>` : ''}
									<p class='text-capitalize m-0'>${this.$tc('payment method', 1)} :</p>
								</div>
								<div class='col text-left'>
									<h4 class='m-0'>${this.$currency.toCurrency(this.customAttributes.exchange_base_total || total, this.selectedForeignCurrency?.code || this.merchant.currencyCode)}</h4>
									<p class='text-success m-0'>${this.$currency.toCurrency(this.selectedForeignCurrency?.totalAmount || this.totalAmount || total, this.selectedForeignCurrency?.code || this.merchant.currencyCode)}</p>
                  ${this.balanceAmount.nativeCurrency > 0 ? `<p class='text-danger m-0'>${this.selectedForeignCurrency ? `${this.$currency.toCurrency(order.custom_attributes.foreign_currency_balance_returned, this.selectedForeignCurrency.code)} / ` : ''}${this.$currency.toCurrency(this.balanceAmount.nativeCurrency)}</p>` : ''}
                  ${dueAmount > 0 ? `<p class='text-danger m-0'>${this.$currency.toCurrency(dueAmount)}</p>` : ''}
									<p class='text-capitalize m-0'>${this.selectedPaymentMethod.name}</p>
								</div>
							</div>`

							div.innerHTML = swalContent
							swalOptions.content = div.firstChild
						}

						await this.$swal(swalOptions)
					}

					document.getElementsByClassName('main-menu')[0]?.getElementsByClassName('active tab')[0]?.focus()
				} catch (err) {
					this.stockValidationHandler(err)
				}
			},
			stockValidationHandler (err) {
				console.error(err)

				if (err.message === 'Network Error') {
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				} else if (err.response?.status === 422) {
					const responseError = err.response.data.data.errors
					let insufficientStockCount = 0
					const cartItems = JSON.parse(this.objToJson(this.cart.items))
						.reduce((items, item) => {
							item.insufficientStock = null
							items.unshift(item)

							return items
						}, [])

					Object.keys(responseError).forEach((err) => {
						const index = +(err.replace('orders.0.items.', '').replace('.item_variation_id', ''))

						if (index > -1 && cartItems[index]) {
							cartItems[index].insufficientStock = responseError[err]
							insufficientStockCount++
						}
					})
					this.$store.commit('setCart', { items: cartItems.reverse() })
					this.$swal({
						title: this.$options.filters.capitalize(this.$t('inventoryError.title')),
						text: this.$options.filters.capitalize(this.$t('inventoryError.text1', { 0: insufficientStockCount })),
						icon: 'error',
						buttons: {
							clear: {
								text: this.$t('inventoryError.clear'),
								value: 'clear'
							},
							ok: {
								text: this.$t('ok')
							}
						}
					}).then((res) => {
						if (res === 'clear') {
							this.cart.items.forEach((item) => {
								if (item.insufficientStock) {
									this.$store.dispatch('modifyCart', {
										item: {
											id: item.item_id,
											name: item.item_name,
											category_id: item.category_id,
											brand_id: item.brand_id
										},
										variation: {
											...item,
											quantity: 0
										}
									})
								}
							})
							this.checkout = { show: false, isTrusted: false }
						} else {
							this.checkout = { show: false, isTrusted: false }
						}
					})
				} else {
					this.$swal({
						title: this.$options.filters.capitalize(this.$tc('oopsError.title')),
						text: this.$options.filters.capitalize(err.message),
						icon: 'error',
						button: this.$t('ok')
					})
				}

				this.processingOrder = false
			},
			processCancelCheckout () {
				const paymentStatus = []

				this.orderPayments.forEach((op) => {
					// eslint-disable-next-line no-prototype-builtins
					if ((op.slug === 'card' || op.slug === 'upi') && op.hasOwnProperty('isPaid')) {
						paymentStatus.push(op.isPaid)
					}
				})

				if (paymentStatus.includes(true)) {
					this.$swal({
						title: this.$t('splitPaymentWarning.title'),
						text: this.$t('splitPaymentWarning.text1'),
						icon: 'warning',
						buttons: [this.$t('no'), this.$t('yes')]
					}).then((response) => {
						if (response) {
							this.cancelCheckout()
						}
					})
				} else {
					this.cancelCheckout()
				}
			},
			cancelCheckout () {
				if (Object.keys(this.order).length === 0) {
					this.removeLoyaltyDiscount()
				}

				if (this.source === 'table') {
					this.$store.commit('resetCart')
				}

				this.$store.commit('resetCheckout')
			},
			applySplitPayment () {
				const orderPayments = JSON.parse(this.objToJson(this.orderPayments))
				const payment = this.splitPayment.paymentMethod
				const credit = orderPayments.findIndex(op => op.id === payment?.id)

				if (payment.slug === 'credit' && credit !== -1) { return }

				payment.type = this.splitPayment.splitType
				payment.value = this.splitPayment.splitValue || 0
				payment.payment_id = new Date().valueOf()

				if ((payment.slug === 'card' &&
					(this.internationalBancard.enabled || (this.$bridge.payCard && this.skyband.enabled) || this.tidyPay.enabled || this.pineLabs.enabled || this.clover.enabled)) ||
					(payment.slug === 'upi' && this.pineLabs.enabled)) {
					payment.isPaid = false
				}

				payment.amount = payment.type === 'percent' ? this.totalPrice * (payment.value / 100) : payment.value
				orderPayments.push(payment)
				this.orderPayments = orderPayments
				this.splitPayment.showModal = false
				this.splitPayment.paymentMethod = null
				this.splitPayment.splitValue = null
			},
			removeSplitPayment (index) {
				this.orderPayments.splice(index, 1)
			},
			calculateSplitAmount (index, value) {
				this.$set(this.orderPayments, index, {
					...this.orderPayments[index],
					amount: this.totalPrice * (value / 100)
				})
			},
			processSplitPayment () {
				if (this.currentSplitPayment) {
					const paymentStatus = []
					const index = this.orderPayments.findIndex(pm => pm.payment_id === this.currentSplitPayment.payment_id)

					this.orderPayments[index].isPaid = true
					this.splitPayment.paid = true
					this.orderPayments.forEach((op) => {
						if (op.slug === 'card') {
							paymentStatus.push(op.isPaid)
						}
					})

					if (paymentStatus.every(v => v === true)) {
						this.preProcessOrder(null, true)
					}

					this.currentSplitPayment = null
				}
			},
			async processExternalPayment (type, splitPayment) {
				if (splitPayment) {
					generatedRefCode = this.order.ref_code || generatedRefCode || await this.$store.dispatch('generateRefCode')
					this.currentSplitPayment = splitPayment
				}

				if (this.cart.price.total > 0) {
					if (this.internationalBancard.enabled) {
						this.initPassportConnect()
					} else if (this.tidyPay.enabled) {
						this.initTidyPay()
					} else if (this.$bridge.payCard && this.skyband.enabled) {
						this.initSkyband()
					} else if (this.pineLabs.enabled) {
						this.initPinelabsConnect({ type })
					} else if (this.clover.enabled) {
						this.initClover()
					} else if (!splitPayment) {
						this.processOrder()
					}
				} else {
					this.processOrder()
				}
			},
			handleExternalPaymentSuccess (data) {
				const dataJSON = typeof data === 'string' ? JSON.parse(data || '{}') : data

				this.processingOrder = false
				this.skybandState.showModal = false
				this.pinelabsConnect.showModal = false

				if (data.paymentType === 'card') {
					this.customAttributes.payment_methods.push({
						gateway: dataJSON.type,
						transaction_id: dataJSON.TxnId,
						number: dataJSON.cardNo.slice(-4)
					})
				} else if (data.paymentType === 'upi') {
					this.customAttributes.payment_methods.push({
						gateway: dataJSON.type,
						transaction_id: dataJSON.TxnId,
						vpa: dataJSON.vpa
					})
				}

				this.processOrder()
			},
			handleExternalPaymentMessage (message) {
				this.processingOrder = false
				this.skybandState.showModal = false
				this.pinelabsConnect.showModal = false
				this.$swal({
					title: this.$options.filters.capitalize(this.$tc('payment', 1)),
					text: message,
					icon: 'info',
					button: this.$t('ok')
				})
			},
			async calculateCardTip () {
				if (await this.$refs.cardTipValidator.validate()) {
					this.tip = { type: 'fixed', value: this.cardTip }
					this.$store.dispatch('cartCalculation')
					this.totalAmount = this.$currency.transformNumber(this.cart.price.total)
					this.totalPrice = this.cart.price.total
					this.orderPayments = [...this.orderPayments]
					this.$bvModal.hide('card-tip')
					this.processExternalPayment('card')
				}
			},
			initPassportConnect () {
				if (this.$offline.state === 'up') {
					this.passportConnect.showModal = true
					this.$axios.post(`/api/passport-connect/${this.internationalBancard.terminalId}`, {
						amount: this.currentSplitPayment ? this.currentSplitPayment.amount.toFixed(2) : this.cart.price.total.toFixed(2),
						transaction_id: `${this.merchant.id}-${this.employeeShift.shift_code}-${new Date().valueOf()}`
					}, {
						headers: {
							Authorization: `Bearer ${this.internationalBancard.privateToken}`
						}
					}).then((response) => {
						this.passportConnect.transactionId = response.data.id
						this.passportConnectTransactionStatus()
					}).catch(this.passportConnectErrorCb)
				} else {
					this.processingOrder = false
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			passportConnectTransactionStatus () {
				this.$axios.get(`/api/passport-connect/${this.passportConnect.transactionId}`, {
					headers: {
						Authorization: `Bearer ${this.internationalBancard.privateToken}`
					}
				}).then((response) => {
					this.passportConnect.status = response.data.status

					if (['COMPLETE', 'FAILED'].includes(response.data.status)) {
						this.passportConnect.code = response.data.response.code || 'TIMEOUT'
						setTimeout(() => {
							this.resetPassportConnect()

							if (response.data.response.code === 'AUTH') {
								this.customAttributes.payment_methods.push({
									gateway: 'passport_connect',
									transaction_id: response.data.response.ttid
								})

								if (this.currentSplitPayment) {
									this.processSplitPayment()
								} else {
									this.processOrder()
								}
							}
						}, 3000)
					} else {
						this.passportConnectTransactionStatus()
					}
				}).catch(this.passportConnectErrorCb)
			},
			cancelPassportConnectTransaction () {
				this.passportConnect.cancel = true
				this.$axios.post(`/api/passport-connect/${this.internationalBancard.terminalId}/cancel`, {
					transaction_id: this.passportConnect.transactionId
				}, {
					headers: {
						Authorization: `Bearer ${this.internationalBancard.privateToken}`
					}
				}).then((response) => {
					this.passportConnect.transactionId = response.data.id
				}).catch(console.error)
			},
			passportConnectErrorCb () {
				this.passportConnect.status = 'FAILED'
				setTimeout(this.resetPassportConnect, 3000)
			},
			resetPassportConnect () {
				this.processingOrder = false
				this.passportConnect = {
					showModal: false,
					status: 'INITIALIZING',
					code: null,
					transactionId: null,
					cancel: false
				}
			},
			initTidyPay () {
				if (this.$offline.state === 'up') {
					this.$store.dispatch('initTidyPay', {
						amount: parseInt(this.currentSplitPayment ? this.currentSplitPayment.amount.toFixed(2) * 100 : this.cart.price.total.toFixed(2) * 100),
						customer_email: (this.customer && this.customer.email) ? this.customer.email : null,
						refCode: this.refCode
					}).then((res) => {
						const cardDetails = {
							gateway: 'tidypay',
							transaction_id: res.transactionId,
							number: res.accountNumberMasked.slice(-4)
						}

						this.customAttributes.payment_methods.push(cardDetails)

						if (this.currentSplitPayment) {
							this.currentSplitPayment.card_details = cardDetails
						}

						setTimeout(() => {
							if (this.currentSplitPayment) {
								this.processSplitPayment()
							} else {
								this.processOrder()
							}
						}, 3000)
					}).catch(console.error).finally(() => {
						this.processingOrder = false
					})
				} else {
					this.processingOrder = false
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			initSkyband () {
				this.skybandState.showModal = true
				this.$bridge.payCard(
					this.bridgeName === 'ANDROID'
						? this.objToJson({
							order_id: this.order.id || this.getUniqueId(),
							amount: this.currentSplitPayment ? this.currentSplitPayment.amount : this.totalPrice,
							gateway: 'skyband'
						})
						: {
							ip: this.skyband.ipAddress,
							port: +this.skyband.portNumber,
							gateway: 'skyband',
							ecrNumber: this.skyband.ecrNumber,
							cashRegisterNumber: this.skyband.cashRegisterNumber,
							amount: this.currentSplitPayment ? this.currentSplitPayment.amount : this.totalPrice
						}
				)
			},
			resetSkyband () {
				this.processingOrder = false
				this.skybandState = {
					showModal: false,
					status: 'INITIALIZING'
				}
			},
			initPinelabsConnect ({ type = 'card' }) {
				this.pinelabsConnect.showModal = true
				this.$axios.post('/api/pinelabs/upload-bill', {
					TransactionNumber: `${this.merchant.id}${this.employeeShift.shift_code}${new Date().valueOf()}`,
					SequenceNumber: 1,
					AllowedPaymentMode: type === 'card' ? '1' : type === 'upi' ? '10' : '',
					MerchantStorePosCode: this.pineLabs.merchantStorePoscode,
					Amount: parseInt((this.currentSplitPayment ? this.currentSplitPayment.amount : this.cart.price.total) * 100),
					UserID: '',
					MerchantID: this.pineLabs.merchantId,
					SecurityToken: this.pineLabs.securityToken,
					IMEI: this.pineLabs.IMEI,
					AutoCancelDurationInMinutes: 5
				}).then((response) => {
					this.pineLabs.TransactionReferenceID = response.data.PlutusTransactionReferenceID
					this.pinelabsTransactionStatus({ type })
				}).catch(console.error)
			},
			pinelabsTransactionStatus ({ type = 'card' }) {
				this.$axios.post('/api/pinelabs/transaction-status', {
					MerchantID: this.pineLabs.merchantId,
					SecurityToken: this.pineLabs.securityToken,
					IMEI: this.pineLabs.IMEI,
					MerchantStorePosCode: this.pineLabs.merchantStorePoscode,
					PlutusTransactionReferenceID: this.pineLabs.TransactionReferenceID
				}).then((response) => {
					if (response.data.ResponseCode === 1001) {
						this.pinelabsConnect.status = 'PENDING'
					}

					if (response.data.ResponseCode === 0) {
						if (type === 'card') {
							const cardDetails = {
								type: 'pinelabs',
								TxnId: response.data.TransactionData.find(td => td.Tag === 'TransactionLogId')?.Value,
								cardNo: response.data.TransactionData.find(td => td.Tag === 'Card Number')?.Value
							}

							if (this.currentSplitPayment) {
								this.currentSplitPayment.card_details = cardDetails
								this.processSplitPayment()
							} else {
								this.handleExternalPaymentSuccess(cardDetails)
							}

							this.customAttributes.payment_methods.push(cardDetails)
						} else if (type === 'upi') {
							const upiDetails = {
								type: 'pinelabs',
								TxnId: response.data.TransactionData.find(td => td.Tag === 'TransactionLogId')?.Value,
								vpa: response.data.TransactionData.find(td => td.Tag === 'Customer VPA')?.Value
							}

							if (this.currentSplitPayment) {
								this.currentSplitPayment.upi_details = upiDetails
								this.processSplitPayment()
							} else {
								this.handleExternalPaymentSuccess(upiDetails)
							}
						}

						this.pinelabsConnect.showModal = false
					} else if (response.data.ResponseCode === 1) {
						this.resetPinelabsConnect()
					} else {
						setTimeout(() => {
							this.pinelabsTransactionStatus({ type })
						}, 3000)
					}
				}).catch(console.error)
			},
			cancelPinelabsConnectTransaction () {
				this.pinelabsConnect.cancel = true
				this.$axios.post('/api/pinelabs/cancel', {
					MerchantID: this.pineLabs.merchantId,
					SecurityToken: this.pineLabs.securityToken,
					IMEI: this.pineLabs.IMEI,
					MerchantStorePosCode: this.pineLabs.merchantStorePoscode,
					PlutusTransactionReferenceID: this.pineLabs.TransactionReferenceID,
					Amount: parseInt((this.currentSplitPayment ? this.currentSplitPayment.amount : this.cart.price.total) * 100)
				}).catch(console.error)
			},
			resetPinelabsConnect () {
				this.processingOrder = false
				this.pinelabsConnect = {
					showModal: false,
					status: 'INITIALIZING',
					cancel: false
				}
			},
			async initClover () {
				if (this.$offline.state === 'up') {
					try {
						const response = await this.$store.dispatch(
							'initClover',
							this.currentSplitPayment ? this.currentSplitPayment.amount : this.cart.price.total
						)

						if (response.data?.payment?.result === 'SUCCESS') {
							const cardDetails = {
								gateway: 'clover',
								transaction_id: response.data.payment.id,
								number: response.data.payment.cardTransaction.last4
							}

							this.customAttributes.payment_methods.push(cardDetails)

							if (this.currentSplitPayment) {
								this.currentSplitPayment.card_details = cardDetails
								this.processSplitPayment()
							} else {
								this.processOrder()
							}
						}
					} catch (err) {
						console.error(err)
					} finally {
						this.processingOrder = false
					}
				} else {
					this.processingOrder = false
					this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}
			},
			keyboardEvents (e) {
				if ([
					'AltLeft', 'AltRight', 'KeyA', 'KeyB', 'KeyC', 'KeyD', 'KeyE', 'KeyF', 'KeyG', 'KeyH', 'KeyI',
					'KeyJ', 'KeyK', 'KeyL', 'KeyM', 'KeyN', 'KeyO', 'KeyP', 'KeyQ', 'KeyR',
					'KeyS', 'KeyT', 'KeyU', 'KeyV', 'KeyW', 'KeyX', 'KeyY', 'KeyZ',
					'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'
				].includes(e.code)) {
					this.keystrokes += e.code
					let paymentMethod
					let selector

					if (/^Alt/.test(this.keystrokes) || /^F(1[0-2]?|[2-9])$/.test(e.code)) {
						switch (Object.keys(this.keyboardShortcuts).find(key => this.keyboardShortcuts[key] === e.code.replace(/Key/g, ''))) {
							case 'cash_payment':
								paymentMethod = this.paymentMethods.find(pm => pm.slug === 'cash')
								selector = `//span[text()='${paymentMethod?.name}']/parent::label`
								break
							case 'card_payment':
								paymentMethod = this.paymentMethods.find(pm => pm.slug === 'card')
								selector = `//span[text()='${paymentMethod?.name}']/parent::label`
								break
							case 'credit_payment':
								paymentMethod = this.paymentMethods.find(pm => pm.slug === 'credit')
								selector = `//span[text()='${paymentMethod?.name}']/parent::label`
								break
							case 'other_payment':
								paymentMethod = this.paymentMethods.find(pm => pm.slug === 'other')
								selector = `//span[text()='${paymentMethod?.name}']/parent::label`
								break
							case 'split_payment':
								paymentMethod = this.paymentMethods.find(pm => pm.slug === 'split')
								selector = `//span[text()='${paymentMethod?.name}']/parent::label`
								break
							case 'add_customer':
								selector = '//*[@id="add-customer"]'
								break
							case 'order_confirm_wo_print':
								selector = '//*[@id="confirm-wo-print"]'
								break
							case 'order_confirm':
								selector = '//*[@id="confirm"]'
								break
							default:
								break
						}

						if (selector) {
							document.evaluate(
								selector,
								document,
								null,
								XPathResult.FIRST_ORDERED_NODE_TYPE,
								null
							).singleNodeValue?.click()

							this.keystrokes = ''
						}
					} else {
						this.keystrokes = ''
					}
				} else {
					this.keystrokes = ''
				}
			}
		}
	}
