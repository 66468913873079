
	import axios from 'axios'

	export default {
		props: {
			selectedForeignCurrency: {
				type: Object,
				default: null
			}
		},
		data () {
			return {
				selectedCurrency: null,
				loading: true,
				currencies: [],
				exchangeRateType: 'fixed',
				exchangeRateValue: 0,
				exchangeRate: [
					{
						value: 'fixed',
						text: 'fix',
						pluralizationIndex: 2
					},
					{
						value: 'percentage',
						text: 'percentage'
					}
				]
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			supportedCurrencies () {
				return this.$store.state.settings.sale.supported_currencies
			},
			foreignCurrencyExchangeRate: {
				get () {
					return this.$store.state.foreignCurrencyExchangeRate
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'foreignCurrencyExchangeRate',
						value,
						save: true
					})
				}
			}
		},
		methods: {
			async init () {
				this.exchangeRateType = this.foreignCurrencyExchangeRate.exchangeRateType
				this.exchangeRateValue = this.foreignCurrencyExchangeRate.exchangeRateValue
				this.selectedCurrency = this.selectedForeignCurrency
				this.currencies = []

				if (
					this.foreignCurrencyExchangeRate.cache &&
					this.merchant.currencyCode === this.foreignCurrencyExchangeRate.cache.query.base &&
					this.supportedCurrencies.join(',') === this.foreignCurrencyExchangeRate.cache.query.symbols &&
					this.$moment().isSame(this.$moment(this.foreignCurrencyExchangeRate.cache.date), 'day')
				) {
					this.supportedCurrencies.forEach((currency) => {
						this.currencies.push({
							code: currency,
							currentExchangeRate: 1 / this.foreignCurrencyExchangeRate.cache.data.rates[currency]
						})
					})
					this.applyExchangeRate()
					this.loading = false
				} else {
					try {
						const query = {
							base: this.merchant.currencyCode,
							symbols: this.supportedCurrencies.join(',')
						}
						const response = await axios.get('/api/apilayer/exchangerates_data/latest', {
							params: query
						})

						if (response.status === 200) {
							this.supportedCurrencies.forEach((currency) => {
								this.currencies.push({
									code: currency,
									currentExchangeRate: 1 / response.data.rates[currency]
								})
							})
							this.applyExchangeRate()
							this.foreignCurrencyExchangeRate = {
								...this.foreignCurrencyExchangeRate,
								cache: {
									query,
									data: response.data,
									date: new Date()
								}
							}
						}
					} catch (err) {
						console.error(err)

						if (
							err.response?.status === 429 && this.foreignCurrencyExchangeRate.cache &&
							this.merchant.currencyCode === this.foreignCurrencyExchangeRate.cache.query.base
						) {
							this.supportedCurrencies.forEach((currency) => {
								this.currencies.push({
									code: currency,
									currentExchangeRate: 1 / this.foreignCurrencyExchangeRate.cache.data.rates[currency]
								})
							})
							this.applyExchangeRate()
						}
					} finally {
						this.loading = false
					}
				}
			},
			selectForeignCurrency (currency) {
				this.selectedCurrency = currency
				this.$emit('update:selectedForeignCurrency', currency)
				this.$refs.foreignCurrency.hide()
			},
			applyExchangeRate () {
				this.foreignCurrencyExchangeRate = {
					...this.foreignCurrencyExchangeRate,
					exchangeRateType: this.exchangeRateType,
					exchangeRateValue: this.exchangeRateValue
				}
				this.currencies = this.currencies.map((currency) => {
					let exchangeRate = null

					if (this.exchangeRateType === 'fixed') {
						exchangeRate = currency.currentExchangeRate - this.exchangeRateValue
					} else {
						exchangeRate = currency.currentExchangeRate - ((this.exchangeRateValue / 100) * currency.currentExchangeRate)
					}

					const updatedCurrency = {
						...currency,
						exchangeRate,
						exchangeRateType: this.exchangeRateType,
						exchangeRateValue: +this.exchangeRateValue,
						totalAmount: this.$currency.transformNumber(this.cart.price.total / exchangeRate, currency.code)
					}

					if (this.selectedForeignCurrency && this.selectedForeignCurrency.code === currency.code) {
						this.$emit('update:selectedForeignCurrency', updatedCurrency)
					}

					return updatedCurrency
				})
			},
			submit () {
				this.$refs.foreignCurrency.hide()
				this.$refs.foreignCurrency.show()
			},
			reset () {
				this.loading = true
				this.selectedCurrency = null
			}
		}
	}
