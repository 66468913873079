
	export default {
		computed: {
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			tidyPay: {
				get () {
					return this.$store.state.tidyPay
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'tidyPay',
						value,
						save: true
					})
				}
			}
		}
	}
