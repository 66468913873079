
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				applicableCharges: [],
				chargesType: 'default',
				ChargesOnOptions: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'total',
						text: 'total'
					},
					{
						value: 'subtotal',
						text: 'subtotal'
					}
				],
				options: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'fixed',
						text: 'fix',
						pluralizationIndex: 2
					},
					{
						value: 'percentage',
						text: 'percentage'
					}
				],
				categories: [
					{
						value: null,
						text: 'selectOption'
					},
					{
						value: 'packing_charge',
						text: 'order packing charges'
					},
					{
						value: 'delivery_charge',
						text: 'delivery charges'
					},
					{
						value: 'service_charge',
						text: 'service charges'
					},
					{
						value: 'payment_charge',
						text: 'payment charges'
					}
				],
				taxes: [],
				chargeName: null,
				applicableOn: null,
				chargeType: null,
				chargeValue: null,
				selectedTaxes: null,
				selectedCategory: null,
				dynamicCharge: null,
				selectedDynamicCharge: null,
				showForm: true
			}
		},
		computed: {
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			cart () {
				return this.$store.state.cart
			},
			charges: {
				get () {
					return this.$store.state.charges
				},
				set (value) {
					this.$store.commit('setState', { key: 'charges', value })
				}
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			appliedDynamicCharge () {
				return this.$store.state.cart.charges.filter(c => c.id === this.dynamicCharge?.id)
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			selectedDynamicCharge () {
				this.prefillForm()
			}
		},
		mounted () {
			this.$root.$on('dynamic-charge-verified', this.applyCharge)
		},
		destroyed () {
			this.$root.$off('dynamic-charge-verified', this.applyCharge)
		},
		methods: {
			prefillForm () {
				this.chargeName = this.selectedDynamicCharge?.name || null
				this.chargeType = this.selectedDynamicCharge?.type || null
				this.chargeValue = this.selectedDynamicCharge?.value || null
				this.applicableOn = this.selectedDynamicCharge?.applicable_on || null
				this.selectedCategory = this.selectedDynamicCharge?.category || null
				this.selectedTaxes = this.selectedDynamicCharge?.tax || null
			},
			getCharges () {
				this.dynamicCharge = this.charges.find(c => c.category === 'dynamic_charge')
				this.applicableCharges = this.charges.reduce((charges, charge) => {
					if (
						charge.category !== 'dynamic_charge' && !charge.is_automatic &&
						(!this.cart.priceCategory || !charge.excluded_price_categories?.includes(this.cart.priceCategory.id)) &&
						this.validateConditions(this.cart.price, charge.conditions)
					) {
						charge.selected = this.cart.charges.findIndex(c => c.id === charge.id) > -1
						charges.push({ ...charge })
					}

					return charges
				}, [])

				this.showForm = this.appliedDynamicCharge.length === 0
			},
			async getTaxes () {
				const taxes = await this.$bridge.getTaxes(this.deviceId, '')

				this.taxes = (typeof taxes === 'string' ? JSON.parse(taxes) : taxes).data
			},
			async initApplyCharge () {
				if (this.chargesType === 'default') {
					const cartCharges = this.cart.charges.filter(c => c.id === this.dynamicCharge?.id)

					this.$store.commit('setCart', { charges: cartCharges.concat([...this.applicableCharges.filter(c => c.selected)]) })
					this.$store.dispatch('cartCalculation')
					this.$refs.chargesModal.hide()
				} else if (await this.$refs.validator.validate()) {
					if (this.employeePolicy.isAdmin || (this.employeePolicy.cart && this.employeePolicy.cart.includes('apply custom charge'))) {
						this.applyCharge()
					} else {
						this.otpModalType = 'dynamic-charge'
					}
				}
			},
			applyCharge () {
				const charge = {
					...this.dynamicCharge,
					identifier: this.selectedDynamicCharge?.identifier || this.getUniqueId(),
					name: this.chargeName,
					value: this.chargeValue,
					type: this.chargeType,
					applicable_on: this.applicableOn,
					category: this.selectedCategory,
					tax: this.selectedTaxes,
					updated_at: new Date()
				}
				const cartCharges = this.cart.charges.filter(c => !c.identifier || (c.identifier && c.identifier !== charge.identifier))

				this.$store.commit('setCart', { charges: cartCharges.concat(charge) })
				this.$store.dispatch('cartCalculation')
				this.$refs.chargesModal.hide()
			},
			deleteDynamicCharge (charge) {
				const cartCharges = this.cart.charges.filter(c => c.identifier && c.identifier !== charge.identifier)

				this.selectedDynamicCharge = null

				this.$store.commit('setCart', { charges: cartCharges })
				this.$store.dispatch('cartCalculation')
			},
			resetForm () {
				this.chargeName = null
				this.chargeType = null
				this.chargeValue = null
				this.applicableOn = null
				this.selectedTaxes = null
				this.selectedCategory = null
				this.selectedDynamicCharge = null
				this.chargesType = 'default'
			}
		}
	}
