
	export default {
		data () {
			return {
				codes: [
					['1', '2', '3'],
					['4', '5', '6'],
					['7', '8', '9'],
					['clear', '0', '']
				]
			}
		},
		computed: {
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			bridgeName () {
				return this.$store.state.bridgeName
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			passcode () {
				return this.$store.state.passcode
			},
			employee () {
				return this.$store.state.employee
			},
			employees () {
				return this.$store.state.employees
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			settings () {
				return this.$store.state.settings
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			'passcode.show' () {
				if (this.passcode.show) {
					document.addEventListener('keydown', this.handleKeydown)
				} else {
					document.removeEventListener('keydown', this.handleKeydown)
				}
			}
		},
		methods: {
			async handleKeydown (event) {
				const key = event.key

				if (key === 'Backspace') {
					this.$store.commit('setPasscode', { value: this.passcode.value.slice(0, -1) })
				} else if (/^\d$/.test(key) && this.passcode.value.length < 6) {
					await this.appendCode(key)
				}
			},
			async appendCode (code) {
				this.$store.commit('setPasscode', { value: this.passcode.value + code })

				if ((this.settings.general.multi_employee_kiosk && this.passcode.value.length === 4) || // multiple employee
					(!this.settings.general.multi_employee_kiosk && this.passcode.value.length === 6) // single employee
				) {
					let employees = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
						type: ['admin', 'staff'],
						offset: -1
					}))

					employees = (typeof employees === 'string' ? JSON.parse(employees) : employees)
					employees = employees.data || employees

					this.$store.commit('setState', {
						key: 'employees',
						value: employees
					})

					const employee = employees.find((e) => {
						return (
							((!e.merchant_id || e.merchant_id === this.merchant.id) && // filter by merchant ID (for cloud accounts)
								+this.passcode.value === (this.settings.general.multi_employee_kiosk
									? +e.passkey // check for passkey match
									: +e.passcode) // check for passcode match
							) &&
							(!this.passcode.employee || // employee swap
								e.id === this.passcode.employee.id)) // employee clockin
					})

					if (employee) {
						switch (this.passcode.type) {
							case 'clockin':
								this.clockin()

								break
							case 'clockout':
								this.clockout()

								break
							case 'swap':
								this.$store.commit('setState', {
									key: 'employee',
									value: {
										...this.employee,
										...employee
									},
									save: true
								})
								this.$store.dispatch('addHoldToCart', { index: 0, switchEmployee: true })
								this.$store.commit('resetPasscode')

								break
							default:
								this.$store.commit('resetPasscode')

								break
						}
					} else {
						this.$store.commit('setPasscode', { value: '' })
						this.$refs.passcodeUl?.classList.add('animate-shake')
						setTimeout(() => {
							this.$refs.passcodeUl?.classList.remove('animate-shake')
						}, 1000)
					}
				}
			},
			async clockin () {
				const date = new Date()
				const id = this.getUniqueId()
				const employee = Object.assign({}, this.passcode.employee)
				const shift = {
					id,
					merchant_id: this.merchant.id,
					device_id: this.deviceId,
					employee_id: employee.id,
					location_id: this.locationId,
					shift_code: `${this.deviceId}${this.locationId}${date.valueOf()}`,
					clock_in: this.appVersionNumber >= 4110
						? date
						: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
					is_synced: !!this.isMiniPlan,
					updated_at: date
				}

				if (this.appVersionNumber < 4110) {
					employee.shiftId = shift.id
					employee.shiftCode = shift.shift_code
				}

				this.$store.commit('setState', {
					key: 'employee',
					value: employee,
					save: true
				})
				this.$store.commit('setState', {
					key: 'employeeShift',
					value: shift,
					save: true
				})
				this.$bridge.insert(
					'EmployeeShift',
					this.bridgeName === 'ANDROID' ? this.objToJson(shift) : shift,
					true
				)

				if (!this.isMiniPlan) {
					const syncData = {
						id: shift.id,
						model_id: shift.id,
						model_name: 'employee-shift',
						payload: this.objToJson({
							model_id: shift.id,
							merchant_id: shift.merchant_id,
							employee_id: shift.employee_id,
							location_id: shift.location_id,
							device_id: shift.device_id,
							shift_code: shift.shift_code,
							clockin_time: this.appVersionNumber >= 4110
								? this.$moment.utc(shift.clock_in).format('YYYY-MM-DD HH:mm:ss')
								: shift.clock_in
						})
					}

					this.$bridge.insert(
						'Sync',
						this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
						true
					)
				}

				this.$store.commit('resetPasscode')

				const cb = () => {
					this.$store.commit('setProgress', { value: 100 })
					setTimeout(() => {
						this.$store.commit('resetProgress')
					}, 1000)
				}

				if (localStorage.getItem('syncOnClockIn') === 'false') {
					localStorage.removeItem('syncOnClockIn')
				} else if (this.$offline.state === 'up' && !this.isMiniPlan) {
					this.syncAll().then(cb).catch(cb)
				}

				if (!this.cashDrawerShift) {
					this.$store.commit('setState', {
						key: 'cashDrawer',
						value: {
							show: true,
							close: false,
							type: 'starting'
						}
					})
				}

				let pendingOrders = await this.$bridge.getOrders(this.deviceId, this.objToJson({
					status: ['pending', 'billed']
				}))

				pendingOrders = (typeof pendingOrders === 'string' ? JSON.parse(pendingOrders) : pendingOrders).data

				if (pendingOrders.length) {
					for (let i = 0; i < pendingOrders.length; i++) {
						const order = {
							id: pendingOrders[i].id,
							employee_shift_id: shift.id,
							updated_at: new Date(),
							is_synced: true
						}

						this.$bridge.insert(
							'Order',
							this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
							true
						)
					}
				}
			},
			async clockout () {
				const date = new Date()
				const cashDrawerShift = Object.assign({}, this.cashDrawerShift)
				const employee = Object.assign({}, this.employee)

				this.$bridge.insert(
					'CashDrawerShift',
					this.bridgeName === 'ANDROID' ? this.objToJson(cashDrawerShift) : cashDrawerShift,
					true
				)
				let syncData = null

				if (!this.isMiniPlan) {
					syncData = {
						id: this.getUniqueId(+cashDrawerShift.id + 1),
						model_id: cashDrawerShift.id,
						model_name: 'cash-drawer-shift',
						payload: this.objToJson({
							model_id: cashDrawerShift.id,
							merchant_id: this.merchant.id,
							device_id: this.deviceId,
							shift_code: cashDrawerShift.shift_code,
							cash_drawer_status: 'closed',
							employee_shift_code: this.employeeShift.shift_code,
							cash_drawer_shift_id: cashDrawerShift.cash_drawer_shift_id,
							closing_employee_id: employee.id,
							closed_cash_money: cashDrawerShift.closed_cash_amount,
							description: cashDrawerShift.description,
							closed_at: cashDrawerShift.closed_at
						})
					}

					this.$bridge.insert(
						'Sync',
						this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
						true
					)
				}

				let shift = await this.$bridge.getEmployeeShifts(
					this.deviceId,
					this.objToJson({ id: this.employeeShift.id })
				)

				shift = typeof shift === 'string' ? JSON.parse(shift) : shift
				shift = shift ? shift[0] : null

				if (shift) {
					shift.clock_out = this.appVersionNumber >= 4110
						? date
						: this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
					shift.updated_at = date

					this.$bridge.insert(
						'EmployeeShift',
						this.bridgeName === 'ANDROID' ? this.objToJson(shift) : shift,
						true
					)

					if (!this.isMiniPlan) {
						syncData = {
							id: this.getUniqueId(+shift.id + 1),
							model_id: shift.id,
							model_name: 'employee-shift',
							payload: this.objToJson({
								model_id: shift.id,
								shift_code: shift.shift_code,
								clockout_time: this.appVersionNumber >= 4110
									? this.$moment.utc(shift.clock_out).format('YYYY-MM-DD HH:mm:ss')
									: shift.clock_out
							})
						}

						this.$bridge.insert(
							'Sync',
							this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
							true
						)
					}
				}

				if (+this.settings.general.print_shift_summary_on_clockout) {
					window.printShift({
						type: 'print',
						source: 'clockout',
						employeeShift: { id: this.employeeShift.id, shift_code: this.employeeShift.shift_code }
					})
				} else {
					this.$store.commit('setState', { key: 'employeeShift', value: null, save: false })
					this.$store.commit('setState', { key: 'cashDrawerShift', value: null, save: false })
					this.$store.commit('setState', { key: 'employee', value: null, save: false })
					this.$store.commit('setState', { key: 'selectedMerchant', value: null, save: false })
					this.$store.commit('resetPasscode')
				}

				if (typeof this.$bridge.archive === 'function' && !this.isMiniPlan) {
					await this.$bridge.archive()
				}

				setTimeout(() => this.$store.dispatch('checkForUpdates'), 1000)
			}
		}
	}
