
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	const initialState = () => ({
		newEmployee: {
			name: null,
			email: null,
			passcode: null,
			passkey: null,
			role: {},
			type: null
		},
		allAccess: false,
		roles: {
			cart: {
				cart: false,
				'apply discount': false,
				'apply custom discount': false,
				'apply charges': false,
				'apply custom charge': false
			},
			sell: {
				sell: false,
				view: false,
				'add item': false,
				'edit item': false,
				'custom sale': false,
				'custom refund': false
			},
			sales: {
				sales: false,
				refund: false,
				'print receipt': false,
				'manage other orders': false,
				edit: false
			},
			tables: {
				tables: false,
				view: false,
				'complete order': false,
				'cancel order': false,
				'reduce quantity': false,
				'print receipt': false,
				'resend kot': false,
				edit: false
			},
			reports: {
				reports: false,
				view: false,
				print: false
			},
			settings: {
				settings: false,
				view: false,
				edit: false,
				custom_refund: false
			},
			customers: {
				customers: false,
				view: false,
				'view containers': false,
				'pay credit': false
			},
			cash_management: {
				cash_management: false,
				view: false,
				'cash in': false,
				'cash out': false,
				view_cash_in: false,
				view_cash_out: false,
				view_sales: false,
				view_refunds: false,
				view_opening_cash_amount: false,
				view_closing_cash_amount: false,
				view_cash_drawer_shifts: false
			}
		}
	})

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				employees: {
					data: [],
					offset: 0,
					total: 0
				},
				selectedEmployee: null,
				fields: [
					{
						key: 'name',
						label: 'name',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					},
					{
						key: 'email',
						label: 'email',
						thClass: 'text-capitalize',
						tdClass: 'align-middle'
					},
					{
						key: 'type',
						label: 'type',
						thClass: 'text-capitalize',
						tdClass: 'align-middle text-capitalize'
					}
				],
				updatedEmployee: null,
				employeeTypes: [
					{
						text: 'select type',
						value: null
					},
					{
						text: 'staff',
						value: 'staff'
					},
					{
						text: 'delivery',
						value: 'delivery'
					},
					{
						text: 'waiter',
						value: 'waiter'
					}
				],
				...initialState(),
				translationOverrides: {
					'apply charges': {
						key: 'apply charge',
						position: 2
					},
					sales: {
						key: 'sale',
						position: 2
					},
					tables: {
						key: 'table',
						position: 2
					},
					reports: {
						key: 'report',
						position: 2
					},
					settings: {
						key: 'setting',
						position: 2
					},
					customers: {
						key: 'customer',
						position: 2
					}
				}
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			employee () {
				return this.$store.state.employee
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			}
		},
		async beforeMount () {
			if (this.employeePolicy.isAdmin) {
				this.fields.push({
					key: 'actions',
					label: 'actions',
					thClass: 'text-capitalize'
				})
				this.employeeTypes.push({
					text: 'admin',
					value: 'admin',
					disabled: true
				})
			}

			this.$root.$on('edit-employee-verified', this.editEmployeeCallback)
			this.$root.$on('delete-employee-verified', this.deleteEmployeeCallback)
			await this.getEmployees()
		},
		destroyed () {
			this.$root.$off('edit-employee-verified', this.editEmployeeCallback)
			this.$root.$off('delete-employee-verified', this.deleteEmployeeCallback)
		},
		methods: {
			async getEmployees (isLoadMore) {
				const data = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
					offset: isLoadMore ? this.employees.offset + 20 : 0
				}))

				this.employees = (typeof data === 'string' ? JSON.parse(data) : data)
				this.employees.data = data.offset ? this.employees.data.concat(data.data) : data.data
				this.employees.offset = data.offset
				this.employees.total = data.total
			},
			loadMoreResults (isVisible) {
				if (isVisible) {
					this.getEmployees(true)
				}
			},
			showEmployeeModal () {
				this.selectedEmployee = null
				window.requestAnimationFrame(() => this.$bvModal.show('employee-modal'))
			},
			editEmployee (employee) {
				this.selectedEmployee = employee
				window.requestAnimationFrame(() => this.$bvModal.show('employee-modal'))
			},
			async editEmployeeCallback () {
				if (this.updatedEmployee) {
					this.$bridge.insert(
						'Employee',
						this.bridgeName === 'ANDROID' ? this.objToJson(this.updatedEmployee) : this.updatedEmployee,
						true
					)

					await this.getEmployees()
				}
			},
			deleteEmployee (employee) {
				this.selectedEmployee = employee
				this.otpModalType = 'delete-employee'
			},
			async deleteEmployeeCallback () {
				if (this.selectedEmployee) {
					const employee = {
						id: this.selectedEmployee.id,
						is_active: false,
						updated_at: new Date()
					}

					this.$bridge.insert(
						'Employee',
						this.bridgeName === 'ANDROID' ? this.objToJson(employee) : employee,
						true
					)

					await this.getEmployees()
				}
			},
			initModal () {
				if (this.selectedEmployee) {
					this.newEmployee = {
						name: this.selectedEmployee.name,
						email: this.selectedEmployee.email,
						passcode: this.selectedEmployee.passcode,
						passkey: this.selectedEmployee.passkey,
						role: {},
						type: this.selectedEmployee.type
					}

					const policy = this.selectedEmployee.roles[0].policy?.appPolicy || []

					this.allAccess = this.selectedEmployee.type === 'admin' || this.selectedEmployee.roles[0].isAdmin || false

					Object.keys(policy).forEach((role) => {
						if (Object.keys(this.roles).includes(role)) {
							if (Object.keys(this.roles[role])?.length - policy[role]?.length === 1) {
								this.roles[role][role] = true
							}

							for (const key in this.roles[role]) {
								if (policy[role].includes(key)) {
									this.roles[role][key] = true
								}
							}
						}
					})
				}
			},
			selectPermission ($event, category) {
				const selectAllPermission = (role) => {
					for (const key in this.roles[role]) {
						this.roles[role][key] = $event
					}
				}

				if (category) {
					selectAllPermission(category)
				} else {
					Object.keys(this.roles).forEach((c) => {
						selectAllPermission(c)
					})
				}
			},
			async createEmployee () {
				if (await this.$refs?.validator?.validate()) {
					const appPolicy = {}
					const date = new Date()

					if (this.appVersionNumber >= 4110) {
						let data = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
							passkey: +this.newEmployee.passkey
						}))

						data = (typeof data === 'string' ? JSON.parse(data) : data)
						data = data.data || data

						if (data.length && (!this.selectedEmployee || this.selectedEmployee.id !== data[0].id)) {
							return this.$refs.validator?.setErrors({
								[this.$t('passkey')]: [this.$t('passcodeExists')]
							})
						}
					}

					this.allAccess = true

					Object.keys(this.roles).forEach((role) => {
						appPolicy[role] = []

						for (const key in this.roles[role]) {
							if (this.roles[role][key] === true && role !== key) {
								appPolicy[role].push(key)
							}

							if (this.roles[role][key] === false && role !== key) {
								this.allAccess = false
							}
						}
					})

					const employee = {
						id: this.selectedEmployee?.id || date.valueOf(),
						merchant_id: this.merchant.id,
						device_id: this.deviceId,
						name: this.newEmployee.name,
						email: this.newEmployee.email,
						passcode: +this.newEmployee.passcode,
						passkey: +this.newEmployee.passkey,
						type: this.newEmployee.type,
						locations: this.objToJson([this.locationId]),
						is_active: true,
						updated_at: date,
						roles: this.objToJson([{
							id: this.selectedEmployee?.roles[0]?.id || date.valueOf(),
							name: 'master',
							isActive: true,
							isAdmin: this.newEmployee.type === 'admin' || this.allAccess,
							policy: {
								appPolicy
							},
							createdAt: date
						}])
					}

					if (this.selectedEmployee) {
						this.updatedEmployee = employee
						this.otpModalType = 'edit-employee'
					} else {
						this.$bridge.insert(
							'Employee',
							this.bridgeName === 'ANDROID' ? this.objToJson(employee) : employee,
							true
						)
					}

					this.$bvModal.hide('employee-modal')
					this.getEmployees()
				}
			},
			resetModel () {
				Object.assign(this.$data, initialState())
			}
		}
	}
