
	export default {
		computed: {
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			pineLabs: {
				get () {
					return this.$store.state.pineLabs
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'pineLabs',
						value,
						save: true
					})
				}
			}
		}
	}
