
	export default {
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			receiptPreview: {
				get () {
					return this.$store.state.receiptPreview
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'receiptPreview',
						value
					})
				}
			},
			shareReceipt: {
				get () {
					return this.$store.state.shareReceipt
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'shareReceipt',
						value
					})
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		methods: {
			printReceipt () {
				this.$bridge.pushPrinterQueue(
					this.bridgeName === 'ANDROID'
						? this.objToJson({
							...this.receiptPreview,
							dataURL: this.receiptPreview.dataURLWoQr || this.receiptPreview.dataURL
						})
						: this.receiptPreview
				)
			},
			captureEmailPhone (type) {
				this.shareReceipt = {
					sendReceiptType: type,
					receiptCode: this.receiptPreview.order.receipt_code,
					customerPhone: this.receiptPreview.order.customers[0]?.phone,
					customerEmail: this.receiptPreview.order.customers[0]?.email
				}
			},
			downloadReceipt () {
				if (typeof this.$bridge.downloadFile === 'function') {
					const options = {
						fileName: this.receiptPreview.fileName,
						dataURL: this.receiptPreview.dataURL.replace(/^data:image\/png;base64,/, '')
					}

					this.$bridge.downloadFile(
						this.bridgeName === 'ANDROID' ? this.objToJson(options) : options
					)
				} else { // TODO: Remove in next release
					this.$bridge.downloadReceipt(
						this.bridgeName === 'ANDROID' ? this.objToJson(this.receiptPreview) : this.receiptPreview
					)
				}

				this.receiptPreview = null
			},
			printInvoice (type, invoiceType) {
				window.printOrderInvoice({
					...this.receiptPreview.order
				}, type, invoiceType)
			}
		}
	}
