
	export default {
		data () {
			return {
				loading: false,
				serialPorts: [],
				baudRates: [115200, 57600, 38400, 19200, 9600, 4800, 2400, 1800, 1200, 600, 300, 200, 150, 134, 110, 75, 50],
				weight: '-'
			}
		},
		computed: {
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			weighingScale: {
				get () {
					return this.$store.state.weighingScale
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'weighingScale',
						value,
						save: true
					})
				}
			}
		},
		watch: {
			weighingScale: {
				handler (value) {
					this.weighingScale = value
				},
				deep: true
			}
		},
		methods: {
			async getSerialPorts () {
				this.loading = true

				const serialPorts = await this.$bridge.getSerialPorts()

				this.serialPorts = typeof serialPorts === 'string' ? JSON.parse(serialPorts) : serialPorts
				this.loading = false
			},
			testWeighingScale () {
				this.getWeight((weight) => {
					if (weight) {
						this.weight = weight
					} else {
						this.$swal({
							title: this.$t('weighingScaleError.title'),
							text: this.$t('weighingScaleError.text'),
							icon: 'error',
							button: this.$t('ok')
						})
					}
				})
			}
		}
	}
