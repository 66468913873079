
	export default {
		computed: {
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			clover: {
				get () {
					return this.$store.state.clover
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'clover',
						value,
						save: true
					})
				}
			}
		},
		mounted () {
			this.getCloverPlatformDetails()
		},
		methods: {
			async getCloverPlatformDetails () {
				const response = await this.$store.dispatch('getResource', {
					resource: 'merchant_platforms',
					params: {
						platform_slug: 'clover'
					}
				})

				this.clover = {
					...this.clover,
					secret: response.data.merchant_platforms[0].secret
				}
			}
		}
	}
