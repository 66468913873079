
	import { ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationProvider
		},
		props: {
			errors: {
				type: Object,
				required: true
			},
			value: {
				type: String,
				default: ''
			}
		},
		$_veeValidate: {
			value () {
				return this.value
			}
		},
		data () {
			return {
				giftCardCode1: '',
				giftCardCode2: '',
				giftCardCode3: ''
			}
		},
		computed: {
			giftCardValidation () {
				return this.giftCardCode1 + this.giftCardCode2 + this.giftCardCode3
			}
		},
		watch: {
			value () {
				if (!this.value) {
					this.giftCardCode1 = ''
					this.giftCardCode2 = ''
					this.giftCardCode3 = ''
				}
			}
		},
		methods: {
			autoFocusGiftCard (value, inputKey, id) {
				if (inputKey === 'Backspace' && id !== 1 && value.length < 1) {
					document.getElementById(`gift_card_${id - 1}`)?.focus()
				} else if (id !== 3 && value.length > 4) {
					document.getElementById(`gift_card_${id + 1}`)?.focus()
				}

				this.$emit('input', this.giftCardValidation)
			}

		}
	}
