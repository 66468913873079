
	import GoogleMapsApiLoader from 'google-maps-api-loader'

	export default {
		props: {
			mapConfig: {
				type: Object,
				default: () => {
					return {}
				}
			},
			markers: {
				type: Array,
				default: () => {
					return []
				}
			},
			polylines: {
				type: Array,
				default: () => {
					return []
				}
			},
			event: {
				type: String,
				default: null
			}
		},
		data () {
			return {
				google: null,
				map: null,
				bounds: null,
				mapMarkers: [],
				polyline: null
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			}
		},
		watch: {
			markers () {
				this.addMarkers()
			},
			polylines () {
				this.addPolyline()
			}
		},
		async mounted () {
			const googleMapApi = await GoogleMapsApiLoader({
				apiKey: process.env.GOOGLE_MAPS_API_KEY
			})

			this.google = googleMapApi
			this.initializeMap()
		},
		methods: {
			initializeMap () {
				this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig)
				this.google.maps.event.addListener(this.map, 'zoom_changed', () => {
					const zoomChangeBoundsListener = this.google.maps.event.addListener(
						this.map,
						'bounds_changed',
						() => {
							if (this.map.getZoom() > 18) {
								this.map.setZoom(18)
							}

							this.google.maps.event.removeListener(zoomChangeBoundsListener)
						}
					)
				})

				this.addMarkers()
				this.addPolyline()

				if (this.mapConfig.centerMarker) {
					this.google.maps.event.addListener(this.map, 'drag', () => {
						if (this.mapMarkers.length) {
							this.mapMarkers[0].setPosition(this.map.getCenter())
						}
					})

					this.google.maps.event.addListener(this.map, 'dragend', async () => {
						if (this.mapMarkers.length) {
							this.$emit('loading', true)

							const lat = this.mapMarkers[0].position.lat()
							const lng = this.mapMarkers[0].position.lng()

							try {
								const response = await this.$store.dispatch('getPlaceByGeocode', {
									latlng: `${lat},${lng}`
								})

								if (response.status === 'OK') {
									const deliveryAddress = response.location
									const distance = await this.$store.dispatch('getDistance', {
										origins: `${this.location.latitude},${this.location.longitude}`,
										destinations: `${lat},${lng}`
									})

									deliveryAddress.distance = distance.rows[0].elements[0].distance
									deliveryAddress.duration = distance.rows[0].elements[0].duration

									this.$emit('location', deliveryAddress)
									this.$emit('loading', false)
								}
							} catch (err) {
								console.error('err', err)
							}
						}
					})

					if (this.event) {
						this.google.maps.event.trigger(this.map, this.event)
					}
				}
			},
			addMarkers () {
				if (this.markers.length) {
					this.clearMarkers()
					this.bounds = new this.google.maps.LatLngBounds()
					this.markers.forEach((marker) => {
						const { Marker } = this.google.maps

						if (marker.iconConfig) {
							marker.icon = {
								url: marker.iconConfig.url,
								size: new this.google.maps.Size(marker.iconConfig.size.x, marker.iconConfig.size.y),
								anchor: marker.iconConfig.anchor
									? new this.google.maps.Point(marker.iconConfig.anchor.x, marker.iconConfig.anchor.y)
									: null,
								scaledSize: new this.google.maps.Size(marker.iconConfig.size.x, marker.iconConfig.size.y)
							}

							delete marker.iconConfig
						}

						this.mapMarkers.push(new Marker({
							...marker,
							map: this.map
						}))

						this.bounds.extend(marker.position)
					})

					this.map.fitBounds(this.bounds)
				}
			},
			clearMarkers () {
				for (let i = 0; i < this.mapMarkers.length; i++) {
					this.mapMarkers[i].setMap(null)
				}

				this.mapMarkers = []
			},
			addPolyline () {
				if (this.polylines.length) {
					this.clearPolyline()
					this.polyline = new this.google.maps.Polyline({
						path: this.polylines,
						strokeOpacity: 0,
						icons: [{
							icon: {
								path: 'M 0,-1 0,1',
								strokeOpacity: 1,
								scale: 4
							},
							offset: '0',
							repeat: '20px'
						}],
						map: this.map
					})
				}
			},
			clearPolyline () {
				if (this.polyline) {
					this.polyline.setMap(null)
				}
			}
		}
	}
