
	export default {
		data () {
			return {
				loading: true
			}
		},
		computed: {
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			settings () {
				return this.$store.state.settings
			},
			syncDetails: {
				get () {
					return this.$store.state.syncDetails
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'syncDetails',
						value,
						save: true
					})
				}
			},
			models () {
				return Object.keys(this.syncDetails).reduce((acc, key) => {
					if (['devices', 'floors', 'floor-tables'].includes(key)) {
						if (['restaurant', 'qsr'].includes(this.merchant.businessType)) {
							acc.push(key)
						}
					} else {
						acc.push(key)
					}

					return acc
				}, [])
			}
		},
		async beforeMount () {
			let syncDetails = {}

			if (this.appVersionNumber >= 4015) {
				syncDetails = await this.$bridge.getSyncDetails(this.merchant.id, this.locationId, this.deviceId)
				syncDetails = typeof syncDetails === 'string' ? JSON.parse(syncDetails) : syncDetails
			} else if (this.appVersionNumber >= 4010) {
				syncDetails = {
					'devices-total': await this.$bridge.getData(
						'Device', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'floors-total': await this.$bridge.getData(
						'Floor', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'floor-tables-total': await this.$bridge.getData(
						'Table', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'employees-total': await this.$bridge.getData(
						'Employee', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'merchant-customer-groups-total': await this.$bridge.getData(
						'CustomerGroup', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'merchant-customers-total': await this.$bridge.getData(
						'Customer', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'merchant-customers-synced': await this.$bridge.getData(
						'Customer', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND customer_id != null`, -1, true
					),
					'categories-total': await this.$bridge.getData(
						'Category', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'merchant-price-categories-total': await this.$bridge.getData(
						'PriceCategory', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND type = 'base'`, -1, true
					),
					'taxes-total': await this.$bridge.getData(
						'Tax', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'discounts-total': await this.$bridge.getData(
						'Discount', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'merchant-charges-total': await this.$bridge.getData(
						'Charge', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'items-total': await this.$bridge.getData(
						'Item', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'item-variation-groups-total': await this.$bridge.getData(
						'ItemVariationGroup', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'merchant-payment-methods-total': await this.$bridge.getData(
						'PaymentMethod', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'employee-shifts-total': await this.$bridge.getData(
						'EmployeeShift', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'employee-shifts-synced': await this.$bridge.getData(
						'EmployeeShift', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId} AND is_synced = true`, -1, true
					),
					'cash-drawer-shifts-total': await this.$bridge.getData(
						'CashDrawerShift', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'cash-drawer-shifts-synced': await this.$bridge.getData(
						'CashDrawerShift', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND is_synced = true`, -1, true
					),
					'order-total': await this.$bridge.getData(
						'Order', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId}`, -1, true
					),
					'order-synced': await this.$bridge.getData(
						'Order', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId} AND is_synced = true`, -1, true
					),
					'cash-drawer-shift-event-total': await this.$bridge.getData(
						'CashDrawerShiftEvent', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'cash-drawer-shift-event-synced': await this.$bridge.getData(
						'CashDrawerShiftEvent', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND is_synced = true`, -1, true
					),
					'order-refund-total': await this.$bridge.getData(
						'OrderRefund', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'order-refund-synced': await this.$bridge.getData(
						'OrderRefund', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND is_synced = true`, -1, true
					),
					'customer-credit-total': await this.$bridge.getData(
						'Credit', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId}`, -1, true
					),
					'customer-credit-synced': await this.$bridge.getData(
						'Credit', `merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND is_synced = true`, -1, true
					)
				}
			} else {
				syncDetails = {
					devices: await this.$bridge.getSyncDetails(
						'Device', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					floors: await this.$bridge.getSyncDetails(
						'Floor', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'floor-tables': await this.$bridge.getSyncDetails(
						'Table', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					employees: await this.$bridge.getSyncDetails(
						'Employee', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'merchant-customer-groups': await this.$bridge.getSyncDetails(
						'CustomerGroup', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'merchant-customers': await this.$bridge.getSyncDetails(
						'Customer', this.merchant.id, this.deviceId, 'customer_id', '!=', 'null'
					),
					categories: await this.$bridge.getSyncDetails(
						'Category', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'merchant-price-categories': await this.$bridge.getSyncDetails(
						'PriceCategory', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					taxes: await this.$bridge.getSyncDetails(
						'Tax', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					discounts: await this.$bridge.getSyncDetails(
						'Discount', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'merchant-charges': await this.$bridge.getSyncDetails(
						'Charge', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					items: await this.$bridge.getSyncDetails(
						'Item', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'item-variation-groups': await this.$bridge.getSyncDetails(
						'ItemVariationGroup', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'merchant-payment-methods': await this.$bridge.getSyncDetails(
						'PaymentMethod', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'employee-shifts': await this.$bridge.getSyncDetails(
						'EmployeeShift', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'cash-drawer-shifts': await this.$bridge.getSyncDetails(
						'CashDrawerShift', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					order: await this.$bridge.getSyncDetails(
						'Order', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'cash-drawer-shift-event': await this.$bridge.getSyncDetails(
						'CashDrawerShiftEvent', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'order-refund': await this.$bridge.getSyncDetails(
						'OrderRefund', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					),
					'customer-credit': await this.$bridge.getSyncDetails(
						'Credit', this.merchant.id, this.deviceId, 'is_synced', '=', 'true'
					)
				}
			}

			Object.keys(syncDetails).forEach((sd) => {
				syncDetails[sd] = typeof syncDetails[sd] === 'string'
					? JSON.parse(syncDetails[sd])
					: syncDetails[sd]
			})

			this.syncDetails = this.models.reduce((acc, m) => {
				acc[m] = {
					...this.syncDetails[m],
					total: syncDetails[`${m}-total`]?.total || syncDetails[m]?.total,
					synced: syncDetails[`${m}-synced`]?.total || syncDetails[m]?.synced
				}

				return acc
			}, {})

			this.loading = false
		},
		mounted () {
			window.syncQueueRepaired = () => this.$emit('update:syncDetails')
		},
		methods: {
			repairSyncQueue ($event) {
				const el = $event.currentTarget

				this.$swal({
					title: this.$options.filters.capitalize(this.$t('repairSyncQueue.title')) + '?',
					text: this.$t('repairSyncQueue.text'),
					icon: 'info',
					buttons: [this.$t('no'), this.$t('yes')]
				}).then((response) => {
					if (response) {
						el.classList.add('running')
						this.$bridge.repairSyncQueue()
					}
				})
			},
			async uploadDb () {
				try {
					let response = await this.$swal({
						title: this.$options.filters.capitalize(this.$t('uploadDb.title')) + '?',
						text: this.$t('uploadDb.text'),
						icon: 'info',
						buttons: [this.$t('no'), this.$t('yes')]
					})

					if (response) {
						const filename = `Merchant:${this.merchant.id}-deviceId:${this.deviceId}-locationId:${this.locationId}-date:${new Date().valueOf()}.realm`

						response = await this.$store.dispatch('getS3Url', {
							filename,
							type: 'realm'
						})
						await this.$bridge.uploadDB(filename, response.data.url)
					}
				} catch (err) {
					console.error(err)
				}
			},
			async forceSync (model, $event) {
				const date = new Date()
				const el = $event.currentTarget
				const options = await this.getSyncOptions(model)

				const cb = () => {
					this.$store.commit('setProgress', { value: 100 })
					setTimeout(() => {
						el.classList.remove('running')
						this.$store.commit('resetProgress')
						this.$emit('update:syncDetails', $event)
					}, 1000)
				}

				if (options) {
					el.classList.add('running')
					this.sync(options).then(cb).catch(cb)
				} else {
					this.$swal({
						title: this.$t('areYouSure'),
						text: this.$t('forceSyncWarning'),
						icon: 'warning',
						buttons: [this.$t('no'), this.$t('yes')],
						dangerMode: true
					}).then((response) => {
						if (response) {
							el.classList.add('running')
							this.syncAll({ force: true })
								.then(() => {
									cb()
									this.$bridge.setLocalStorage('lastForceSyncDate', date)
								}).catch(cb)
						}
					})
				}
			}
		}
	}
